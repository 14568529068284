<script setup>
import DefaultButton from '@/components/Buttons/DefaultButton.vue';
import TextField from '@/components/Form/TextField.vue';
import { timestampToTime } from '@/helpers';
import { useChatStore } from '@/stores/chat';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';

const chat = useChatStore()
const { getMessages } = storeToRefs(chat)

const form = ref({
    content: '',
    files: []
})

const messages = computed(() => getMessages.value.sort((a, b) => a.createdAt - b.createdAt))

const fileInput = ref(null);
const files = ref([]);
const zoomedImage = ref(null);

const handleFileUpload = () => {
    const uploadedFiles = fileInput.value?.files;
    for (let i = 0; i < uploadedFiles.length; i++) {
        const file = uploadedFiles[i];
        const reader = new FileReader();

        reader.onload = (event) => {
            const fileData = {
            file: file,
            url: event?.target?.result 
        };
        files.value.push(fileData);
        };

        reader.readAsDataURL(file);
    }
};
const removeFile = (index)=>{
    files.value.splice(index, 1)
}

const openImage = (url) => {
    zoomedImage.value = url;
};

const closeImage = () => {
    zoomedImage.value = null;
};

const submit = async () => {
    if(files.value.length == 0 && form.value.content == ''){ return }
    else{
        if(files.value.length){
            form.value.files = files.value.map(fileData => ({
                file: fileData.file,
                name:fileData.file.name,
                size:fileData.file.size,
                url: fileData.url,
                type:fileData.file.type
            }));
        }
        chat.setMessage(form.value)
    }
    form.value.content = ''
    form.value.files = []
    files.value = []
}

const height = computed(() => {
    const headerHeight = document.getElementById("header")?.offsetHeight
    return Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) - headerHeight
})

onMounted(() => {
    chat.bindSocketMessages()
    chat.fetchMessages()
})
</script>

<template>
    <div>
        <div class="flex flex-col bg-[var(--color-grey6)]" :style="{maxHeight: height + 'px'}">
            <div class="px-4 py-2 flex-1 flex flex-col-reverse overflow-y-auto">
                <div 
                    v-for="message in messages"
                    :class="[message.opid ? 'left' : 'right']"
                    class="chat-MessageBubble"
                >       
                    <div v-if="zoomedImage" class="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50" @click="closeImage">
                        <img :src="zoomedImage" class="max-w-full max-h-full rounded-md shadow-lg" />
                    </div>

                    <div v-if="message.files?.length" class="flex flex-col gap-4 justify-end"   :class="[message.opid ? 'items-start' : 'items-end']"> 
                        <img v-for="file in message.files" :src="file?.url" class="w-32 h-20 cursor-pointer rounded-md"  @click="openImage(file.url)" /> 
                    </div>
                    <p class="chat-MessageContent">{{ message.content }}</p>
                    <p class="chat-MessageTime">{{ timestampToTime(message.createdAt) }}</p>
                </div>
            </div>
            <form @submit.prevent="submit" class="px-4 py-2 flex space-x-2 items-end">
                <div class="flex-1">
                    <!-- Uploaded Images Preview -->
                    <div class="pb-1 flex items-center gap-2">
                        <div v-for="(file, index) in files" :key="index" class="relative">
                            <img :src="file.url" class="w-12 h-12 rounded" />  
                            <button 
                                @click="removeFile(index)" 
                                class="absolute -top-2 -right-2 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                                ×
                            </button>
                        </div>
                    </div>
                    <!-- Input Field -->
                    <div class="input-wrapper">
                        <TextField class="w-full h-9 font-normal text-sm pr-10" v-model="form.content" placeholder="Message" />
                        <label for="fileInput" class="clip-icon">
                            <img src="/clip.svg" class="w-5 h-5"/>
                            <input id="fileInput" type="file" accept="image/png, image/jpeg" style="display: none" ref="fileInput" @change="handleFileUpload">
                        </label>
                    </div>
                </div>
                <DefaultButton>{{$t('app.1')}}</DefaultButton>
            </form>
        </div>
    </div>
</template>

<style scoped>
.chat-MessageBubble {
    @apply px-4 py-1 rounded-md shadow-md w-max max-w-80 mb-2  break-words;
}

.chat-MessageBubble.left {
    @apply bg-white text-left;
}

.chat-MessageBubble.right {
    @apply bg-emerald-300 text-right ml-auto;
}

.chat-MessageContent {
    @apply text-sm;
}

.chat-MessageTime {
    @apply text-xs text-neutral-600;
}
.input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.clip-icon {
    position: absolute;
    right: 10px;
    bottom: 50%;
    transform: translateY(50%);
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.2s;
}

.clip-icon:hover {
    opacity: 1;
}


</style>