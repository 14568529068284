<script setup>
import { RouterView } from 'vue-router'
import { useResourcesStore } from './stores/resources';
import { onMounted } from 'vue';
import ResponseMessageBox from './components/ResponseMessageBox.vue';
import { useGamesStore } from './stores/games';

const resources = useResourcesStore()
const games = useGamesStore()

onMounted(async () => {
    // Promise.all([
        resources.fetchAllConfigResources(),
        games.fetchAllCasinoGames()
    // ])

    !function(){var d="STATSCOREWidgetsEmbederScript";if(!window.document.getElementById(d)){window.STATSCOREWidgets={},window.STATSCOREWidgets.onLoadCallbacks=[],window.STATSCOREWidgets.onLoad=function(d){window.STATSCOREWidgets.onLoadCallbacks.push(d)};var n=window.document.createElement("script");n.src="https://wgt-s3-cdn.statscore.com/bundle/EmbederESM.js",n.type="module",n.async=!0,n.id=d,n.addEventListener("error",function(d){for(var n=0;n<window.STATSCOREWidgets.onLoadCallbacks.length;n++)window.STATSCOREWidgets.onLoadCallbacks[n](d)}),window.document.body.appendChild(n)}}();
})
</script>

<template>
    <RouterView />

    <ResponseMessageBox />
</template>
