<script setup>
import { useLiveStore } from '@/stores/live';
import { computed, onMounted, ref , watch} from 'vue';
import LiveSports from './Partials/LiveSports.vue';
import { storeToRefs } from 'pinia';
import { debounce } from '@/helpers';
import { useRoute, useRouter } from 'vue-router';
import MatchListItem from '@/components/Match/MatchListItem.vue';
import { useResourcesStore } from '@/stores/resources';
import MatchGroupHeader from '@/components/Match/MatchGroupHeader.vue';
import MatchStats from '@/components/Match/MatchStats.vue';

const resources = useResourcesStore()
const live = useLiveStore()
const { getSport, getLeague, getLocation } = storeToRefs(resources)
const { sports, getEvents } = storeToRefs(live)
const route = useRoute()
const router = useRouter()
const eventToShowStats = ref(undefined)
const activeSport = computed(() => route.query.s ?? sports.value[0])
const search =ref('')
const result= ref()
const hasSearched = ref(false)

const submit = debounce(() => {
  if (!search.value.trim()) {
    hasSearched.value = false;
    result.value = undefined; 
    return;
  }
  result.value = live.search(search.value, activeSport.value);
  hasSearched.value = true;
}, 500);

watch(search, (value) => {
  submit();
});

const changeActiveSport = (sport) => {
    router.push(`/in-play?s=${sport}`)
}
const showEventStats = (eid) => {
    eventToShowStats.value = eid
}

</script>

<template>
    <LiveSports
        :sports="sports"
        :active="activeSport"
        @selected="changeActiveSport"
    />
    <div class="flex flex-1">
        <section :class="`lv-SportEvents lv-SportEvents-${activeSport}`">
            <div class="py-3 px-2 xl:px-6 flex items-center justify-between">
                <h3 class="text-xl text-white font-bold italic">{{ getSport(activeSport)?.nm }}</h3>
                <div class="SearchInput" v-if="getEvents(activeSport).length">
                    <div class="SearchInput_Icon" />
                    <input
                        class="SearchInput_Field"
                        placeholder="Search..."
                        type="search"
                        v-model="search"
                    />
                </div>
            </div>
            <template v-if="!hasSearched">
                <template v-for="league in getEvents(activeSport)">
                    <!-- <div v-for="event in league.events"> -->
                        <MatchGroupHeader 
                            :title="`${getLocation(league.locid)?.nm} - ${getLeague(league.lid)?.nm}`"
                            :sport="activeSport"
                        >
                            <template #title>
                                <div class="flex items-center">
                                    <img :src="`/loc/${league.locid}.svg`" class="w-4 h-4 rounded-full mr-2" alt="">
                                    {{ getLocation(league.locid)?.nm }} - {{ league.name}}
                                </div>
                            </template>
                        </MatchGroupHeader>
                        <ul class="list-none divide-y divide-neutral-500/25">
                            <li v-for="event in league.events">
                                <MatchListItem 
                                    :key="event._id"
                                    :event="event"
                                    :live="true"
                                    @stats="showEventStats"
                                />
                            </li>
                        </ul>
                    <!-- </div> -->
                </template>
            </template>
            <template v-if="hasSearched">
                <ul>
                    <li v-for="event in result">
                        <MatchListItem :event="event" />
                    </li>
                </ul> 
            </template>
        </section>

        <template v-if="eventToShowStats">
            <MatchStats
                :event="eventToShowStats"
            />
        </template>
    </div>
</template>

<style scoped>
.lv-SportEvents {
    @apply relative flex-1;
}

.lv-SportEvents-6046 {background-image: linear-gradient(160deg,#364D3C 0%, var(--color-grey3) 400px)}
.lv-SportEvents-54094 {background-image: linear-gradient(160deg,#3F4D32 0%, var(--color-grey3) 400px)}
.lv-SportEvents-48242 {background-image: linear-gradient(160deg,#4D4432 0%, var(--color-grey3) 400px)}
.lv-SportEvents-452674 {background-image: linear-gradient(160deg,#474D36 0%, var(--color-grey3) 400px)}
.lv-SportEvents-154923 {background-image: linear-gradient(160deg,#4D2725 0%, var(--color-grey3) 400px)}
.lv-SportEvents-35232 {background-image: linear-gradient(160deg,#36444D 0%, var(--color-grey3) 400px)}
.lv-SportEvents-265917 {background-image: linear-gradient(160deg,#36414D 0%, var(--color-grey3) 400px)}
.lv-SportEvents-154830 {background-image: linear-gradient(160deg,#4D4032 0%, var(--color-grey3) 400px)}

.SearchInput {
    background-color: hsla(0,0%,100%,.1);
    border-radius: 50px;
    display: flex;
    @apply px-3;
}

.SearchInput_Icon {
    mask-image: url("/search.svg");
    mask-size: 18px 18px;
    mask-repeat: no-repeat;
    mask-position: center;
    min-width: 18px;
    background-color: #fff;
    z-index: 20;
    display: flex;
    flex-direction: column
}

.SearchInput_Field {
    @apply flex-1 text-sm !bg-transparent placeholder:text-white font-bold text-white py-2.5 border-0;
    @apply focus:ring-0;
}
</style>