<script setup>
import { ref } from 'vue';

defineProps({
    component: {
        type: String,
        default: 'button'
    },
    size: {
        type: String,
        default: 'lg'
    },
    loading: {
        type: Boolean,
        default: false
    }
})

const button = ref(null)

const focus = () => button.value.focus()

defineExpose({ focus })
</script>

<template>
    <component
        :is="component"
        v-bind="{ ...$props, ...$attrs }"
        ref="button"
        :disabled="loading"
        class="inline-flex items-center justify-center rounded-sm text-sm font-semibold focus:outline-none focus:ring ring-primary-200"
        :class="{
            'h-9 px-3': size == 'lg',
            'h-8 px-3': size == 'sm',
            'h-7 px-1 md:px-3': size == 'xs',
        }"
    >
        <div v-if="loading" class="spinner mr-2" />
        <slot />
    </component>
</template>

<style scoped>
.spinner,
.spinner:after {
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
}

.spinner {
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 0.2em solid var(--color-grey4);
    border-right: 0.2em solid var(--color-grey4);
    border-bottom: 0.2em solid var(--color-grey4);
    border-left: 0.2em solid transparent;
    transform: translateZ(0);
    animation: spinning 1s infinite linear;
}

@keyframes spinning {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
