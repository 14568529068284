<script setup>
import { computed, ref ,watch} from 'vue';
import Odd from '../Odd.vue';
import { timestampToTime } from '@/helpers';
import { useRouter } from 'vue-router';
import { useResourcesStore } from '@/stores/resources';
import { storeToRefs } from 'pinia';
const props = defineProps({
    event: Object,
    market: {
        type: [String, Number],
        default: 1
    }
})

defineEmits(['stats'])

const resources = useResourcesStore()
const { getTeam } = storeToRefs(resources)
const router = useRouter()

const hasMarketToShow = (marketId) => props.event.m && marketId in props.event.m
const isLive = computed(() => props.event.st == 2)
const homeTeam = computed(() => getTeam.value(props.event.htid))
const awayTeam = computed(() => getTeam.value(props.event.atid))
const homeScore = computed(() => props.event.inf?.res?.split('-')[0])
const awayScore = computed(() => props.event.inf?.res?.split('-')[1])
const homeTeamRC = computed(() => {
    if (props.event?.inf?.rc ){ 
        if(props.event?.inf?.rc?.split('-')[0] !== '0'){ return true }
    }else{ return false}
})
const awayTeamRC = computed(() => {
    if (props.event?.inf?.rc ){ 
        if(props.event?.inf?.rc?.split('-')[1] !== '0'){ return true }
    }else{  return false }
})
const url = computed(() => isLive.value ? `/in-play/${props.event.fid}` : `/pre/${props.event.fid}`)
const goToDetails = () => router.push(url.value)

const newHomeRes = ref(false)
const newAwayRes = ref(false)

watch(() => props.event.inf?.res?.split('-')[0], (newVal, oldVal) => {
  if (!props.event.inf) return;
  if (oldVal !== undefined && newVal !== undefined && newVal !== oldVal) {
    newHomeRes.value = true;
    setTimeout(() => {
      newHomeRes.value = false;
    }, 5000);
  }
});

watch(() => props.event.inf?.res?.split('-')[1], (newVal, oldVal) => {
  if (!props.event.inf) return;
  if (oldVal !== undefined && newVal !== undefined && newVal !== oldVal) {
    newAwayRes.value = true;
    setTimeout(() => {
      newAwayRes.value = false;
    }, 5000);
  }
});

</script>

<template>
    <div class="ev-Box">
        <div class="px-2 xl:px-7 my-3.5 flex gap-1 border-r border-neutral-600/50" @click="goToDetails">
            <div class="overflow-hidden ">
                <div class="ev-Teams">
                    <div class="flex items-center gap-1">
                        <p class="ev-Participant">{{ homeTeam?.nm ?? event.ht }}</p>
                        <img v-if="homeTeamRC" src="/rc.svg" class="h-3"/> 
                        <p v-if="newHomeRes && event.sid == 6046 && isLive"class="text-[11px] font-bold px-0.5 text-gray-800 bg-[var(--color-yellow1)]">GOAL</p>
                    </div>
                    <div class="flex items-center gap-1">
                        <p class="ev-Participant">{{ awayTeam?.nm ?? event.at }}</p>
                        <img v-if="awayTeamRC" src="/rc.svg" class="h-3"/>
                        <p v-if="newAwayRes && event.sid == 6046 && isLive" class="text-[10px] font-bold px-0.5 text-gray-800 bg-[var(--color-yellow1)]">GOAL</p>
                    </div>
                </div>
                <div class="ev-Time">
                    <p class="text-[11px] text-white opacity-75">
                        <template v-if="!isLive">{{ timestampToTime(event.tm) }}</template>
                        <template v-else>{{ Math.floor(event.inf?.t / 60) }}'</template>
                    </p>

                    <div
                        class="text-[11px] bg-[var(--color-primary5)] font-bold px-0.5 rounded-sm flex items-center h-3 ml-2"
                        v-if="!isLive"
                    >
                        {{ event.spnr }} &raquo;
                    </div>
                </div>
            </div>
            <div class="ml-auto" v-if="isLive">
                <div class="ev-ScoreBox">{{ homeScore }}</div>
                <div class="ev-ScoreBox mt-0.5">{{ awayScore }}</div>
            </div>
            <button
                v-if="isLive"
                class="ev-StatIconTrigger hidden xl:block"
                @click.stop="$emit('stats', event)"
            >
                <div class="ev-StatIcon" />
            </button>
        </div>
        <div>
            <ul class="list-none flex h-full" v-if="event.m">
                <template v-if="event.sid == 54094 || event.sid == 154830 || event.sid == 265917">
                    <li class="flex-1">
                        <Odd :odd="event.m[52]?.find(s => s.nm == '1')" />
                    </li>
                    <li class="flex-1">
                        <Odd :odd="event.m[52]?.find(s => s.nm == '2')" />
                    </li>
                </template>
                <template v-else>
                    <li class="flex-1">
                        <Odd :odd="event.m[1]?.find(s => s.nm == '1')" />
                    </li>
                    <li class="flex-1">
                        <Odd :odd="event.m[1]?.find(s => s.nm == 'X')" />
                    </li>
                    <li class="flex-1">
                        <Odd :odd="event.m[1]?.find(s => s.nm == '2')" />
                    </li>
                </template>
            </ul>
        </div>
    </div>
</template>

<style scoped>
.ev-Box {
    @apply grid grid-cols-2
}

.ev-Box:not(:last-of-type) {
    @apply border-b;
}

.ev-Time {
    @apply flex items-center;
}

.ev-Teams:hover .ev-Participant {
    color: var(--color-primary5);
}

/* .ev-Participant {
    @apply text-[13px] font-bold text-white text-ellipsis truncate flex items-center gap-1;
} */
.ev-Participant {
    @apply text-[13px] font-bold text-white flex items-center gap-1;
    /* width: 100%; */
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.ev-Teams {
    @apply flex-1 overflow-hidden whitespace-nowrap cursor-pointer;
}
.ev-ScoreBox {
    text-align: center;
    line-height: 15px;
    height: 19px;
    border-radius: 1px;
    background-color: var(--color-white10);
    padding: 2px 4px;
    width: 100%;
    font-size: 13px;
    font-weight: 700;
    white-space: nowrap;
    @apply text-white;
}

.ev-StatIcon {
    mask-image: url('/stat-icon.svg');
    background-color: var(--color-grey6);
    mask-size: 13px 13px;
    width: 13px;
    height: 13px;
    @apply ml-4;
}
</style>
