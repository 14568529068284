<script setup>
import { computed } from 'vue';

const props = defineProps({
    options: [Array, Object],
    textAttribute: String,
    valueAttribute: String,
    modelValue: [String, Number],
})

const emit = defineEmits(['update:modelValue'])

const proxyValue = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value)
    }
})
</script>

<template>
    <select 
        class="py-2.5 bg-white rounded-sm font-bold text-base text-black border-0 focus:ring-0 shadow-sm"
        v-model="proxyValue"
    >
        <option
            v-for="option in options"
            :key="valueAttribute ? option[valueAttribute] : option"
            :value="valueAttribute ? option[valueAttribute] : option"
        >
            {{ textAttribute ? option[textAttribute] : option }}
        </option>
    </select>
</template>