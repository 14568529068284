<script setup>
import { useResourcesStore } from '@/stores/resources';
import { storeToRefs } from 'pinia';
import MainEvents from './MainEvents.vue';
import { usePrematchStore } from '@/stores/prematch';

const prematch = usePrematchStore()
const resources = useResourcesStore()
const { mainLeagues, getLeague } = storeToRefs(resources)
const { eventsByLeague } = storeToRefs(prematch)
</script>

<template>
    <section class="flex-1" v-for="league in mainLeagues">
        <template v-if="eventsByLeague(league.sid, league.fid).length">
            <MainEvents
                :events="eventsByLeague(league.sid, league.fid)"
                :icon="league.sid"
                :title="getLeague(league.fid).nm"
            />
        </template>
    </section>
</template>
