<script setup>
import { useAuthStore } from '@/stores/auth';
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
import UserDetailsForm from './Partials/UserDetailsForm.vue';

const auth = useAuthStore()
const { getUser } = storeToRefs(auth)

const message = ref('')

const update = async (form) => {
    await auth.updateDetails(form).then(res => {
        if (res.status == 204) {
            message.value = 'Profile updated!'
        }
    })
}
</script>

<template>
    <div>
        <template v-if="message">
            <div class="py-2 px-4 bg-emerald-200/25 text-emerald-500 text-xs font-semibold">
                {{ message }}
            </div>
        </template>
        <UserDetailsForm 
            :title="$t('app.30')"
            :data="getUser"
            :auth="true"
            @submitted="update"
        />
    </div>
</template>

