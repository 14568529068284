<script setup>
import { usePrematchStore } from '@/stores/prematch';
import { useResourcesStore } from '@/stores/resources';
import { storeToRefs } from 'pinia';

const resources = useResourcesStore()
const prematch = usePrematchStore()
const { getSport } = storeToRefs(resources)
const { sports } = storeToRefs(prematch)
</script>

<template>
    <div class="w-full">
        <ul class="px-3 list-none flex space-x-4 w-full overflow-x-auto">
            <li>
                <RouterLink to="/ebd" class="flex flex-col items-center justify-center mt-1">
                    <div class="Calendar" />
                    <p class="text-[11px] text-white font-bold mt-1 whitespace-nowrap">Events</p>
                </RouterLink>
            </li>
            <li v-for="sport in sports">
                <RouterLink
                    :to="`/ps/${sport.sid}`"
                    class="flex flex-col items-center justify-center py-2.5"
                >
                    <img :src="`/sport/${sport.sid}.svg`" class="w-6 h-6" />
                    <p class="text-[11px] text-white font-bold mt-1 whitespace-nowrap">{{ sport.name }}</p>
                </RouterLink>
            </li>
        </ul>
    </div>
</template>

<style scoped>
.Calendar {
    mask-image: url('/calendar.svg');
    /* mask-size: 24px 24px; */
    height: 30px;
    width: 30px;
    @apply bg-neutral-300;
}
</style>