<script setup>
import GuestLayout from '@/layouts/GuestLayout.vue'
import { useAuthStore } from '@/stores/auth';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const { t } = useI18n()
const router = useRouter()
const authStore = useAuthStore()
const { getLoginError } = storeToRefs(authStore)
const username = ref('')
const password = ref('')
const credentials = computed(() => ({username: username.value, password: password.value}))

const submit = async () => {
    try {
        await authStore.login(credentials.value)
        router.push({to: '/'})
    } catch (error) {
        console.error('Not OK')
    }
}
</script>

<template>
    <GuestLayout>
        <div class="w-full max-w-96 mx-auto pt-12">
            <div class="w-full bg-[var(--color-grey9)] p-5">
                <form @submit.prevent="submit">
                    <div class="space-y-2.5 mb-5">
                        <input 
                            type="text" 
                            class="w-full h-12 px-2.5 text-[15px] border-gray-400 focus:border-gray-500 focus:ring-0"
                            :placeholder="$t('app.24')"
                            v-model="username"
                        />
                        <input 
                            type="password" 
                            class="w-full h-12 px-2.5 text-[15px] border-gray-400 focus:border-gray-500 focus:ring-0"
                            :placeholder="$t('app.27')"
                            v-model="password"
                        />
                    </div>

                    <div class="bg-red-200/50 text-red-600 border border-red-700 py-2 px-3 mb-5" v-if="getLoginError">
                        <p class="text-[15px]">{{ t(getLoginError) }}</p>
                    </div>
                    <button 
                        class="bg-[var(--color-primary1)] h-12 w-full flex items-center justify-center text-white text-[15px] font-bold" 
                        type="submit"
                    >{{$t('app.22')}}</button>
                </form>
            </div>
        </div>
    </GuestLayout>
</template>