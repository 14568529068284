<script setup>
import { toFixedTwo } from '@/helpers';
import { useAuthStore } from '@/stores/auth';
import { useBetslipStore } from '@/stores/betslip';
import { useLiveStore } from '@/stores/live';
import { usePrematchStore } from '@/stores/prematch';
import { useResourcesStore } from '@/stores/resources';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import DefaultButton from './Buttons/DefaultButton.vue';
import Spinner from './Spinner.vue';
import display from '@/markets-display'
const { t, te } = useI18n()
const auth = useAuthStore()
const prematch = usePrematchStore()
const live = useLiveStore()
const resources = useResourcesStore()
const betslip = useBetslipStore()
const show = ref(false)
const { getCredits } = storeToRefs(auth)
const { getSlips, selections, totalOdd, minAndMax, cols, hasChanges, hasUpdates, getSendingTicket, bonusValue } = storeToRefs(betslip)
const { getMarket } = storeToRefs(resources)
const { getEvent: getLiveEvent } = storeToRefs(live)
const { getEvent: getPrematchEvent } = storeToRefs(prematch)

const amountInput = ref()
const amount = ref()
const currency = ref(getCredits.value[0].cuid._id)

const validateAmount = (e) => {
    let value = e.target.value.replace(',', '.');
    amount.value = value.replace(/[^0-9.]/g, '') // Allow only numbers and dot
        .replace(/(\..*?)\..*/g, '$1') // Allow only one dot
        .replace(/(\.\d{2})\d*/g, '$1'); // Limit to 2 decimal places
}

const blockInvalidCharsOnAmount = (event) => {
    let value = event.target.value.replace(',', '.')
    const validChars = /^[0-9.,]$/; // Allow digits and dot
    if (!validChars.test(event.key)) {
        event.preventDefault(); // Block invalid characters
    }

    const [integerPart, decimalPart] = value.split('.');
    if (decimalPart && decimalPart.length >= 2) {
        event.preventDefault()
    }
}

// const event = (isl, eid) => {
//     return isl
//         ? getLiveEvent.value(eid)
//         : getPrematchEvent.value(eid)
// }
const event = (isl, eid) => {
    let event =  isl ? getLiveEvent.value(eid) : getPrematchEvent.value(eid)
    if(!event){
        betslip.pullSlip(eid)
    }
    return event
}
const minAmount = computed(() => {
    let [min] = minAndMax.value
    return min * (amount.value/cols.value)
})

const maxAmount = computed(() => {
    let [min, max] = minAndMax.value
    return max * (amount.value/cols.value)
})

const toReturn = computed(() => {
    return totalOdd.value * amount.value
})

const bonusToReturn = computed(() => {
    let bon = 0
    if(!isSystem.value && maxAmount.value){
        bon =  (bonusValue.value / 100) * maxAmount.value
    }else if(isSystem.value && minAmount.value){
        bon =  (bonusValue.value / 100) * minAmount.value
    }
    return bon
})
const isSystem = computed(() => {
    let obj = {}
    for (let eid in getSlips.value) {
        if (!(eid in obj)) obj[eid] = {}
        for (let uid in getSlips.value[eid].betslips) {
            if (!(uid in obj[eid])) {
                obj[eid][uid] = getMarket.value(getSlips.value[eid].betslips[uid].fid).sysnr
            }

            if ((new Set(Object.values(obj[eid]))).size !== Object.values(obj[eid]).length) {
                return true
            }
        }
    }

    return false
})

const marketName = (m, ht, at) => {
    const regexHome = new RegExp('\\{1\\}', 'g');
    const regexAway = new RegExp('\\{2\\}', 'g');
    const regexScore = new RegExp('\\{score\\}', 'g');

    let market = getMarket.value(m.fid)?.nm
    if(market){
        market = market.replace(regexHome, ht)
        market = market.replace(regexAway, at)
        if (m.fid == 59) {
            const ln = m.ln?.split('.')[0];
            if (ln) {
                market = market.replace(regexScore, ln)
            }
        }
    }
    return market
}
const displayOptions = (id) => id in display ? display[id] : undefined

const withLine = computed(() => (selection) => {
    let dis = displayOptions(selection.fid);

    if (!dis) return false;

    return dis.id === 205 || dis.id === 206;
});

const selectionTranslation = (selection) => {
    if(!withLine.value(selection)) {
        return te(selection.nm) ? t(selection.nm) : selection.nm;
    }else{
        return t(selection.nm + ' ' + selection.ln) 
    }
    
}

const placeBet = () => {
    if (hasUpdates.value) {
        betslip.acceptUpdates()
    } else {
        betslip.send(currency.value, amount.value)
    }
}

watch(selections, (value) => {
    if (!value) {
        amount.value = ''
        if (amountInput.value) {
            amountInput.value.value = ''
        }
    }
})

onMounted(() => {
    betslip.bindSocketEvents()
    betslip.initializeFromLocalStorage()
})
</script>

<template>
    <Spinner :show="getSendingTicket" />
    <div 
        v-if="getSendingTicket"
        class="fixed inset-0 bg-black bg-opacity-50 z-50"
    ></div>
    <div class="fixed bottom-0 left-0 right-0 pb-6" v-if="selections">
        <div class="flex justify-center">
            <div
                class="max-w-sm w-full rounded-xl overflow-hidden"
                :class="[show ? 'bg-[var(--color-whiteF0)]' : 'bg-white']"
            >
                <div
                    class="py-2 flex items-center justify-between"
                    :class="{'border-b': show}"
                    @click="show = !show"
                >
                    <div class="flex-1 flex justify-between items-center px-2">
                        <div class="flex items-center">
                            <div class="w-5 h-5 bg-[var(--color-primary1)] flex items-center justify-center rounded-full text-sm text-white font-bold">
                                {{ selections }}
                            </div>
                            <p class="text-[15px] font-extrabold text-[var(--color-grey4)] ml-2">{{$t('general.9')}}</p>
                        </div>

                        <div v-if="getCredits.length > 1">
                            <select @click.stop v-model="currency" class="border-x-0 border-t-0 border-neutral-300 appearance-none text-xs bg-transparent focus:ring-0">
                                <option v-for="c in getCredits" :value="c.cuid._id">
                                    {{ c.cuid.cod }}
                                </option>
                            </select>
                        </div>

                        <div class="ml-auto text-sm font-medium">
                            <template v-if="!isSystem">
                                {{ toFixedTwo(totalOdd) }}
                            </template>
                            <template v-else>
                                {{ toFixedTwo(minAndMax[0]) }} - {{ toFixedTwo(minAndMax[1]) }}
                            </template>
                        </div>
                    </div>

                    <div class="px-2 py-1.5 border-l">
                        <div class="bet-ShowHide" :class="{'opened': show}" />
                    </div>
                </div>

                <!-- START:Content -->
                 <div v-show="show">
                    <div class="p-1.5">
                        <div class="bg-white rounded py-3 px-0.5">
                            <ul class="space-y-4">
                                <li v-for="slip,eid in getSlips">
                                    <div class="flex-1 flex">
                                        <div class="px-2">
                                            <button class="bs-XMark" @click="betslip.pullSlip(eid)" />
                                        </div>
                                        <div class="flex-1">
                                            <h3 class="text-[13px] font-extrabold text-[var(--color-primary1)]">
                                                {{ event(slip.isl, eid)?.ht }} v {{ event(slip.isl, eid)?.at }}
                                            </h3>

                                            <ul class="flex-1">
                                                <li v-for="selection, uid in slip.betslips">
                                                    <div class="flex justify-between flex-1" :class="{'line-through': selection.st != 1}">
                                                        <p
                                                            class="text-xs font-bold text-[var(--color-grey44)]"
                                                        >
                                                            <span>{{ marketName(selection, event(slip.isl, eid)?.ht, event(slip.isl, eid)?.at) }}</span> 
                                                            ({{ selectionTranslation(selection) }}<span v-if="selection.ln && !withLine(selection)"> &emsp14;{{ selection.ln }}</span>)
                                                        </p>
                                                        <p
                                                            class="text-xs pr-2 font-bold"
                                                            :class="{
                                                                'text-red-600': selection.n == 1,
                                                                'text-emerald-500': selection.n == 2,
                                                                'text-[var(--color-grey44)]': !selection.n
                                                            }"
                                                        >{{ toFixedTwo(selection.p) }}</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div class="mt-1.5 flex justify-between items-center">
                            <button class="py-1.5 px-3 text-center text-red-600 font-bold text-[13px]" @click="betslip.clear">
                                Delete All
                            </button>
                            <span class="text-xs px-3" v-if="bonusValue">Bonus({{bonusValue}}%)= {{toFixedTwo(bonusToReturn)}}</span>
                        </div>
                    </div>
                 </div>

                 <div class="flex">
                    <input
                        ref="amountInput"
                        type="text"
                        inputmode="decimal"
                        lang="en"
                        pattern="[0-9.]*"
                        class="w-1/2 bg-[#222] text-[17px] text-[var(--color-primary3)] placeholder:text-[var(--color-primary3)] border-0 font-extrabold focus:ring-0"
                        :placeholder="$t('general.15')"
                        @input="validateAmount"
                        @keypress="blockInvalidCharsOnAmount"
                        v-if="!hasChanges && !hasUpdates"
                    >
                    <button
                        class="h-12 w-1/2 text-center bg-gradient-to-br from-red-600 to-red-400 text-white text-base font-extrabold"
                        @click="betslip.rejectChanges"
                        v-if="hasChanges && !hasUpdates"
                    >
                        Reject
                    </button>
                    <DefaultButton
                        class="bet-btn text-center bg-placebet text-base"
                        :class="{'w-1/2': !hasUpdates, 'w-full': !hasChanges && hasUpdates}"
                        @click="placeBet"
                        :disabled="getSendingTicket"   
                    >
                        <template v-if="hasChanges || hasUpdates">
                            {{$t('general.16')}}
                        </template>
                        <template v-else >
                            <div class="flex flex-col">
                                {{$t('general.10')}}
                                <p v-if="amount" class="text-[11px] font-bold text-[var(--color-grey4)] leading-none">
                                    <template v-if="!isSystem">
                                        {{$t('general.11')}} {{ toFixedTwo(toReturn) }}
                                    </template>
                                    <template v-else>
                                        {{$t('general.12')}} {{ toFixedTwo(minAmount) }} ---> {{$t('general.13')}} {{ toFixedTwo(maxAmount) }}
                                    </template>
                                </p>
                            </div>
                        </template>
                    </DefaultButton>
                 </div>
                 <!-- END:Content -->
            </div>
        </div>
    </div>
</template>

<style scoped>
.bet-ShowHide {
    @apply block w-6 h-6 cursor-pointer rotate-180;
    mask-image: url('/chevron-down.svg');
    background-color: #000;
}
.bet-ShowHide.opened {
    @apply rotate-0;
}

.bs-XMark {
    background-image: url('/x-mark.svg');
    width: 14px;
    height: 14px
}
.bet-btn{
    height: 48px; 
    font-weight: 800; 
    font-size: 1rem;
}
</style>
