import http from "@/composables/axios";
import { socket } from "@/feed.socket";
import { defineStore } from "pinia";
import { useBetslipStore } from "./betslip";
import { usePrematchStore } from "./prematch";
import { useResourcesStore } from "./resources";

export const useLiveStore = defineStore('live', {
    state: () => ({
        events: {},
        loaded: false
    }),

    getters: {
        getEvents: state => sid => {
            let groups = {}
            for (let eid in state.events[sid]) {
                let match = state.events[sid][eid]

                const { lid } = match;

                if (!groups[lid]) {
                    groups[lid] = [];
                }

                groups[lid].push(match)
            }


            const resources = useResourcesStore();
            const leagues = resources.leagues;
            return Object.keys(groups)
                .map((lid) => ({
                    lid,
                    locid: leagues[lid]?.locid,
                    order: leagues[lid]?.ord ?? Infinity,
                    name: leagues[lid]?.nm || "Unknown",
                    events: groups[lid]
                }))
                .sort((a, b) => a.order - b.order);
        },
        getEvent: state => eid => {
            for (let sid in state.events) {
                if (eid in state.events[sid]) {
                    return state.events[sid][eid]
                }
            }
            return null
        },
        
        search: state => (text, sport) => {
            if (!text) return
            return Object.values(state.events[sport])
                .filter(e => e.ht.toLowerCase().includes(text.toLowerCase()) || e.at.toLowerCase().includes(text.toLowerCase()))
        },

        //tenEvents: state => sid => Object.values(state.events[sid] ?? []).slice(0, 10),
       
        tenEvents: state => (sid) => {
            const store = useLiveStore();
            const groups = store.getEvents(sid);
            let sortedEvents = [];
            for (const group of groups) {
              for (const event of group.events) {
                sortedEvents.push(event);
                if (sortedEvents.length === 10) {
                  return sortedEvents;
                }
              }
            }
            return sortedEvents;
        },
  
        sports: state => Object.keys(state.events),
        eventsLoaded: state => state.loaded
    },

    actions: {
        async fetchEvents(query = {}) {
            query = new URLSearchParams(query).toString()
            await http.get('02/live/events?' + query).then(res => {
                if (res.data.ok) {
                    this.events = res.data.data
                    this.loaded = true
                }
            })
        },

        async fetchEvent(eid) {
            return await http.get(`02/live/events/${eid}`).then(res => {
                if (res.data.ok) {
                    return res.data.data
                }
            })
        },

        async fetchOdds(eid, query = {}) {
            query = new URLSearchParams(query).toString()
            return await http.get(`02/live/events/${eid}/details?` + query).then(res => {
                if (res.data.ok) {
                    return res.data.data
                }
            })
        },
        findSport(fid) {
            const resources = useResourcesStore();
            const sports = resources.sports;
            return sports[fid]
        },
        bindSocketEvents(user) {
            const prematch = usePrematchStore()

            socket.on('odds:live', (odds) => {
                this.updateOdds(odds, user)
            })

            socket.on('stats:live', (data) => {
                for (let sport in this.events) {
                    if (data.eid in this.events[sport]) {
                        this.events[sport][data.eid].inf = data.data
                    }
                }
            })

            socket.on('events:live', (data) => {
                for(let eid in data) {
                    prematch.removeEvent(eid)
                }

                this.fetchEvents({p: user.p ?? 0, m: user.mrg ?? 0})
            })
        },

        updateOdds(odds, user) {
            const betslip = useBetslipStore()
            for (let odd of odds) {
                const eventId = odd.eid
                if (user.mrg) {
                    let m = odd.p / (user.mrg / 100 + 1)
                    odd.p = m > 1 ? m : 1
                }
                if (user.p) {
                    let p = odd.p - (user.p / 100)
                    odd.p = p > 1 ? p : 1
                }
                    
                for (let sport in this.events) {
                    if (eventId in this.events[sport]) {
                        if (!('m' in this.events[sport][eventId])) {
                            this.events[sport][eventId].m = {}
                        }

                        if (!(odd.fid in this.events[sport][eventId].m)) {
                            this.events[sport][eventId].m[odd.fid] = []
                        }

                        let indexOfOdd = this.events[sport][eventId].m[odd.fid].findIndex(o => o.uid == odd.uid)

                        if(this.findSport(sport)?.m){
                            let newOdd = odd.p / (this.findSport(sport)?.m / 100 + 1) 
                            odd.p =  newOdd > 1 ? newOdd : 1
                        }

                        if (indexOfOdd > -1) {
                            this.events[sport][eventId].m[odd.fid][indexOfOdd] = odd
                        } else {
                            this.events[sport][eventId].m[odd.fid].push(odd)
                        }

                        betslip.updateOdd(eventId, odd)
                    }
                }
            }
        }
        
    }
})