<script setup>
import { orderByGivenArray } from '@/helpers';
import Odd from '../Odd.vue';
import { computed } from 'vue';

const props = defineProps({
    odds: Array
})
const width = computed(() => {
    return props.odds.length % 2 == 0 
        ? 'w-1/2'
        : 'w-1/3'
})
</script>

<template>
    <div>
        <div v-for="odd in odds" :class="[width, 'float-left OddsListItem']">
            <Odd 
                :odd="odd"
                :with-name="true"
                class="py-2.5"
            />
        </div>
    </div>
</template>