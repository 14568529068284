import { defineStore } from 'pinia'

export const useResponseMessageStore = defineStore('response_message', {
    state: () => ({
        message: '',
        closeable: true
    }),

    getters: {
        getMessage: state => state.message,
        hasMessage: state => state.message != '' && typeof state.message != 'undefined',
        getCloseable: state => state.closeable
    },

    actions: {
        setMessage(msg, closeable = true) {
            this.message = msg
            this.closeable = closeable
        },

        clearMessage() {
            this.message = ''
        }
    }
})