import http from "@/composables/axios";
import { defineStore } from "pinia";

export const useUsersStore = defineStore('users', {
    state: () => ({
        users: []
    }),

    getters: {
        getUsers: state => state.users
    },

    actions: {
        async fetchUsers(id = undefined) {
            let query = id ? new URLSearchParams({uid: id}).toString() : ''
            await http.get(`01/users?${query}`).then(res => {
                if (res.data.ok) {
                    this.users = res.data.data
                }
            })
        },

        async fetchHierarchy(id) {
            await http.get(`01/users/${id}/hierarchy`).then(res => {
                if (res.data.ok) {
                    this.users = res.data.data
                }
            })
        },

        async createUser(payload) {
            return await http.post('01/users', payload)
        },

        async fetchUser(id) {
            return await http.get(`01/users/${id}`)
        },

        async fetchUserCredits(id) {
            return await http.get(`01/users/${id}/credits`)
        },

        async updateUserDetails(id, payload) {
            return await http.put(`01/users/${id}`, payload)
        },

        async updatePassword(id, payload) {
            return await http.put(`01/users/${id}/password`, payload)
        },

        async addCredit(id, payload) {
            return await http.put(`01/credits/${id}/add`, payload)
        },

        async withdrawCredit(id, payload) {
            return await http.put(`01/credits/${id}/withdraw`, payload)
        },

        async fetchFinance(query) {
            query = new URLSearchParams(query).toString()
            return await http.get(`01/finances?${query}`)
        },

        async fetchGamesFinance(query) {
            query = new URLSearchParams(query).toString()
            return await http.get(`01/game-finances?${query}`)
        },
        async blockAllUsers() {
            return await http.put(`01//users/block`)
        },
        async unblockAllUsers() {
            return await http.put(`01//users/unblock`)
        },
        async fetchColors(id) {
            return await http.get(`01/colors`)
        },

    }
})
