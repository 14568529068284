<script setup>
import { useAuthStore } from '@/stores/auth';
import { useGamesStore } from '@/stores/games';
import { storeToRefs } from 'pinia';
import { onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const resources = ref([])
const meta = ref({})
const auth = useAuthStore()
const games = useGamesStore()
const route = useRoute()
const router = useRouter()
const { getCredits, getCurrency } = storeToRefs(auth)
const { getGame, isUrl } = storeToRefs(games)

const openGame = async (id) => {
    await games.openGame(id, {
        device: 'M',
        currency_id: getCredits.value[0].cuid._id,
        currency_name: getCurrency.value(getCredits.value[0].cuid._id).cod,
        homeURL: import.meta.env.VITE_APP_URL
    })
}

const moreGames = async () => {
    meta.value.page++
    await games.fetchGamesByProvider(route.params.id, meta.value.page).then(res => {
        if (res.data.ok) {
            resources.value = [...resources.value, ...res.data.data.games]
            meta.value = res.data.data.meta
        }
    })
}

watch(getGame, (value) => {
    if (isUrl.value) {
        window.open(value, '_blank')
    } else {
        router.push('/casino/game')
    }
})

onMounted(async () => {
    await games.fetchGamesByProvider(route.params.id).then(res => {
        if (res.data.ok) {
            resources.value = res.data.data.games
            meta.value = res.data.data.meta
        }
    })
})
</script>

<template>
    <div>
        <h3 class="text-gray-300 font-bold text-xl py-2 px-4">Games</h3>
        <div class="px-3 grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 gap-4 mt-6 space-x-1">
            <template v-for="game in resources">
                <div class="cursor-pointer" @click="openGame(game._id)">
                    <img :src="game.img ?? game.extimg" class="w-full h-full rounded-xl" alt="">
                    <!-- <p class="text-xs text-neutral-300 opacity-90 text-center">{{ game.nm }}</p> -->
                </div>
            </template>
        </div>

        <div class="mt-4 flex justify-center" v-if="meta.hasNextPage">
            <button class="text-sm text-white font-medium shadow-md bg-[var(--color-primary1)] rounded-md py-2 px-4" @click="moreGames">More</button>
        </div>
    </div>
</template>