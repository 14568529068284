import { reactive } from "vue";
import { io } from "socket.io-client";

export const state = reactive({
    connected: false,
    fooEvents: [],
    barEvents: []
});

export const socket = io(import.meta.env.VITE_SOCKET_URL, {
    transports: ['websocket'],
    secure: true,
    auth: {
        token: localStorage.getItem('token')
    },
    autoConnect: false,
});

socket.on("connect", () => {
    state.connected = true;
});

socket.on("disconnect", () => {
    state.connected = false;
});