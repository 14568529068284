import http from "@/composables/axios";
import { defineStore } from "pinia";
import { socket } from "@/user.socket";

export const useChatStore = defineStore('chat', {
    state: () => ({
        messages: [],
    }),

    getters: {
        getMessages: state => state.messages,
        unreadMessages: state => state.messages.filter(m => !m.isrc),
    },

    actions: {
        fetchMessages() {
            socket.emit('clt:get-messages')
        },

        setMessage(payload) {
            socket.emit('clt:send-message', payload)
        },

        bindSocketMessages() {
            socket.on('clt:send-message', (data) => {
                if (data.ok) {
                    this.pushMessageToRoom(data.data)
                }
            })

            socket.on('clt:get-message', (data) => {
                if (data.ok) {
                    this.pushMessageToRoom(data.data)
                }
            })

            socket.on('clt:get-messages', (data) => {
                if (data.ok) {
                    this.messages = data.data
                }
            })
        },

        pushMessageToRoom(data) {
            this.messages.splice(0, 0, data)
        },
    }
})