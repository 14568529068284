<script setup>
import { useAuthStore } from '@/stores/auth';
import { useBetsStore } from '@/stores/bets';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, watch } from 'vue';
import MyBet from './Partials/MyBet.vue';
import { useRoute } from 'vue-router';
import { modelDateValue, formatToLocalDate } from '@/helpers';
import TextField from '@/components/Form/TextField.vue';
import DefaultButton from '@/components/Buttons/DefaultButton.vue';
import SelectField from '@/components/Form/SelectField.vue';
import { toFixedTwo } from '@/helpers';

const bets = useBetsStore()
const auth = useAuthStore()

const route = useRoute()
const { getUser, getCurrencies, getCurrency } = storeToRefs(auth)
const { getTickets, getUsers: ticketUsers } = storeToRefs(bets)

const activeTab = ref(0)
const tabs = ref({
    0: 'All',
    1: 'Unconfirmed',
    2: 'Lost',
    3: 'Win',
})

const filters = ref({
    userId: getUser.value._id,
    startDate: modelDateValue(),
    endDate: modelDateValue(),
    currency: '',
})

const setActiveTab = (tab) => {
    activeTab.value = tab
    if (tab == 1) {
        filters.value.st = 6
        // return getTickets.value.filter(group => group.tickets.filter(t => t.st == 6).length)
    } else if (tab == 2) {
        filters.value.st = 1
        // return getTickets.value.filter(group => group.tickets.filter(t => t.st == 1).length)
    } else if (tab == 3) {
        filters.value.st = 2
        // return getTickets.value.filter(group => group.tickets.filter(t => t.st == 2).length)
    } else {
        delete filters.value.st
    }

    // fetch()
}

const fetch = () => {
    bets.fetchTickets(filters.value)
}

watch(() => route.query, (value) => {
    if (value.uid) {
        filters.value.userId = value.uid
    } else {
        filters.value.userId = getUser.value._id
    }
})

watch(filters, () => {
    fetch()
}, {deep: true})

onMounted(() => {
    filters.value.currency = getCurrencies.value[0]._id
})
</script>

<template>
    <div>
        <form>
            <div class="px-4 py-2">
                <TextField type="date" v-model="filters.startDate" placeholder="Start Date" class="w-full rounded-b-none" />
                <TextField type="date" v-model="filters.endDate" placeholder="End Date" class="w-full border-t border-neutral-200 rounded-none" />
                <SelectField
                    v-model="filters.currency"
                    :options="getCurrencies"
                    text-attribute="cod"
                    value-attribute="_id"
                    class="w-full rounded-t-none border-t border-neutral-200"
                />
            </div>
        </form>

        <template v-if="!ticketUsers.length">
            <div class="h-12 px-4 flex">
                <ul class="list-none flex items-center space-x-2">
                    <li
                        v-for="tab, i in tabs"
                        :class="['tab-item', {'active': activeTab == i}]"
                        @click="setActiveTab(i)"
                    >
                        <span>{{ tab }}</span>
                    </li>
                </ul>
            </div>

            <div class="px-4 py-2 space-y-4">
                <template v-if="getTickets.length">
                    <div v-for="group in getTickets">
                        <h3 class="text-sm font-bold text-neutral-400 mb-2">{{ formatToLocalDate(group.tickets[0].date) }}</h3>
                        <ul class="space-y-2">
                            <li v-for="ticket in group.tickets">
                                <MyBet
                                    :key="ticket._id"
                                    :ticket="ticket"
                                    @cashout="fetch"
                                />
                            </li>
                        </ul>
                    </div>
                </template>

                <div class="no-Tickets" v-else>
                    <p class="text-sm font-bold text-neutral-300 text-center">
                        {{$t('app.11')}}
                    </p>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="px-4 py-2">
                <ul class="list-none space-y-2">
                    <li v-for="user in ticketUsers" class="text-neutral-300">
                        <RouterLink :to="`/my-bets?uid=${user._id}`" class="flex justify-between no-Tickets px-4 cursor-pointer">
                            <p class="text-sm font-bold">
                                <span>{{ user.un }}</span>
                                <span class="ml-1 text-xs font-normal">({{ user.tnr }})</span>
                            </p>

                            <p class="text-xs">{{ toFixedTwo(user.am) }} {{ getCurrency(filters.currency)?.cod }}</p>
                        </RouterLink>
                    </li>
                </ul>
            </div>
        </template>
    </div>
</template>

<style>
.tab-item {
    @apply inline-flex items-center px-2 py-1 rounded-2xl cursor-pointer;
}

.tab-item span {
    @apply text-white text-sm;
}

.tab-item.active {
    background-color: var(--color-primary3);
}

.tab-item.active span {
    color: #000;
    font-weight: 700;
}

.no-Tickets {
    background-image: var(--mgc-bg);
    @apply rounded-md overflow-hidden py-4;
}
</style>
