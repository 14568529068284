<script setup>
import DefaultButton from '@/components/Buttons/DefaultButton.vue';
import TextField from '@/components/Form/TextField.vue';
import { modelDateValue } from '@/helpers';
import { onMounted, ref } from 'vue';
import SportBettingFinance from './Partials/SportBettingFinance.vue';
import CasinoFinance from './Partials/CasinoFinance.vue';
import LiveCasinoFinance from './Partials/LiveCasinoFinance.vue';

let months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
const tabs = ref([
    {0: 'Today'},
    {1: 'Yesterday'},
])
const activeTab = ref(0)

const filters = ref({
    sd: modelDateValue(),
    ed: modelDateValue()
})

const formatDate = (date) => {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
}

const setTab = async (index) => {
    activeTab.value = index
    switch(index) {
        case 0:
            filters.value.sd = modelDateValue()
            filters.value.ed = modelDateValue()
            break;
        case 1:
            var date = new Date();
            date.setDate(date.getDate() - 1);
            filters.value.sd = date.toISOString().split('T')[0]
            filters.value.ed = date.toISOString().split('T')[0]
            break;
        case 2:
            const currentDate = new Date();
            const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
            filters.value.sd = formatDate(firstDayOfMonth)
            filters.value.ed = formatDate(lastDayOfMonth)
            break
        case 3:
            const cdate = new Date();
            const fdOfMonth = new Date(cdate.getFullYear(), cdate.getMonth()-1, 1);
            const ldOfMonth = new Date(cdate.getFullYear(), cdate.getMonth(), 0);
            filters.value.sd = formatDate(fdOfMonth)
            filters.value.ed = formatDate(ldOfMonth)
            break
    }
}

const setCurrentMonth = () => {
    const d = new Date();
    tabs.value.push({2: months[d.getMonth()]})
}

const setPreviousMonth = () => {
    const currentDate = new Date();
    let previousMonthIndex = currentDate.getMonth() - 1;
    tabs.value.push({3: months[previousMonthIndex]})
}

onMounted(() => {
    setCurrentMonth()
    setPreviousMonth()
})
</script>

<template>
    <div>
        <form>
            <div class="px-4 py-2">
                <TextField type="date" v-model="filters.sd" placeholder="Start Date" class="w-full rounded-b-none" />
                <TextField type="date" v-model="filters.ed" placeholder="End Date" class="w-full border-t border-neutral-200 rounded-t-none" />
            </div>
        </form>

        <div class="h-12 px-4 flex">
            <ul class="list-none flex items-center space-x-2">
                <li
                    v-for="tab, i in tabs"
                    :class="['tab-item', {'active': activeTab == i}]"
                    @click="setTab(i)"
                >
                    <span>{{ tab[i] }}</span>
                </li>
            </ul>
        </div>

        <div class="mt-4 px-4 space-y-4">

            <div>
                <h3 class="text-sm font-bold text-white/60 mb-2">{{$t('app.5')}}</h3>
                <div class="fin-Container">
                    <table class="fin-Table">
                        <SportBettingFinance
                            :start-date="filters.sd"
                            :end-date="filters.ed"
                        />
                    </table>
                </div>
            </div>

            <div>
                <h3 class="text-sm font-bold text-white/60 mb-2">{{$t('app.6')}}</h3>
                <div class="fin-Container">
                    <table class="fin-Table">
                        <CasinoFinance
                            :start-date="filters.sd"
                            :end-date="filters.ed"
                        />
                    </table>
                </div>
            </div>

            <div>
                <h3 class="text-sm font-bold text-white/60 mb-2">{{$t('app.7')}}</h3>
                <div class="fin-Container">
                    <table class="fin-Table">
                        <LiveCasinoFinance
                            :start-date="filters.sd"
                            :end-date="filters.ed"
                        />
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.fin-Container {
    background-image: var(--mgc-bg);
    @apply rounded-md overflow-hidden;
}

.fin-Table {
    @apply w-full text-neutral-200 divide-y divide-neutral-500/30;
    font-size: 2.5vw;
    line-height: 7vw;
    text-align: right;
}

.fin-Table th,
.fin-Table td {
    @apply px-2
}

.fin-Table th {
    @apply font-normal text-neutral-400;
}

.fin-Table .left {
    @apply text-left;
}
</style>
