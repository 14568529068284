<script setup>
</script>

<template>
    <div>
        <h1>{{$t('terms.term3.title')}}</h1>
        <h2>{{$t('terms.term3.p1')}}</h2>
        
        <h3>{{$t('terms.term3.p2')}}</h3>
        <p>{{$t('terms.term3.p3')}}</p>
        
        <h3>{{$t('terms.term3.p4')}}</h3>
        <p>{{$t('terms.term3.p5')}}</p>

        <h3>{{$t('terms.term3.p6')}}</h3>
        <p>{{$t('terms.term3.p7')}}</p>
        
        <h3>{{$t('terms.term3.p8')}}</h3>
        <p>{{$t('terms.term3.p9')}}</p>

        <h3>{{$t('terms.term3.p10')}}</h3>
        <p>{{$t('terms.term3.p11')}}</p>
        
        <h3>{{$t('terms.term3.p12')}}</h3>
        <p>{{$t('terms.term3.p13')}}:</p>
        <ul>
            <li><strong>1:</strong> {{$t('terms.term3.p14')}}</li>
            <li><strong>X:</strong> {{$t('terms.term3.p15')}}</li>
            <li><strong>2:</strong> {{$t('terms.term3.p16')}}</li>
        </ul>

        <h3>{{$t('terms.term3.p17')}}</h3>
        <p>{{$t('terms.term3.p18')}}</p>
        <ul>
            <li><strong>1:</strong> {{$t('terms.term3.p19')}}</li>
            <li><strong>X:</strong>{{$t('terms.term3.p20')}}</li>
            <li><strong>2:</strong> {{$t('terms.term3.p21')}}</li>
        </ul>

        <h3>{{$t('terms.term3.p22')}}</h3>
        <p>{{$t('terms.term3.p23')}}</p>
        
        <h3>{{$t('terms.term3.p24')}}</h3>
        <p>{{$t('terms.term3.p25')}}</p>

        <h3>{{$t('terms.term3.p26')}}</h3>
        <p>{{$t('terms.term3.p27')}}</p>

        <h3>{{$t('terms.term3.p28')}}</h3>
        <p>{{$t('terms.term3.p29')}}</p>
        <ul>
            <li>{{$t('terms.term3.p30')}}</li>
            <li>{{$t('terms.term3.p31')}}</li>
        </ul>

        <h3>{{$t('terms.term3.p32')}}</h3>
        <p>{{$t('terms.term3.p33')}}</p>
        <ul>
            <li><strong>{{$t('terms.term3.p34')}}:</strong> {{$t('terms.term3.p35')}}</li>
            <li><strong>{{$t('terms.term3.p36')}}:</strong> {{$t('terms.term3.p37')}}</li>
        </ul>

        <h3>{{$t('terms.term3.p38')}}</h3>
        <p>{{$t('terms.term3.p39')}}</p>

        <h3>{{$t('terms.term3.p40')}}</h3>
        <p>{{$t('terms.term3.p41')}}</p>
    </div>
</template>
<style scoped>
   ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        margin-bottom: 10px;
        font-size: 12px;
    }
    p{
        padding: 1px 0px;
        font-size: 12px;
    }
    strong {
        color: white;
    }
    ul{
        padding: 2px 0px
    }
    h2 , h3{
        color: white;
        padding: 2px 0px;
        font-size: 12px;
    }
    h1{
        color: white;
        padding: 4px 0px;
        font-size: 14px;
    }
</style>