<script setup>
import { computed } from 'vue';

const props = defineProps({
    options: [Array, Object],
    name: String,
    modelValue: [String, Number, Boolean]
})

const emit = defineEmits(['update:modelValue'])

const proxyValue = computed({
    get() {
        return props.modelValue
    },

    set(value) {
        emit('update:modelValue', value)
    }
})
</script>

<template>
    <div class="flex-1 flex bg-white rounded-sm shadow-sm">
        <div v-for="option, val in options" class="flex-1 flex">
            <input 
                type="radio" 
                :name="name" 
                :id="`${name}-${val}`" 
                :value="val" 
                v-model="proxyValue"
                class="hidden"
            >
            <label 
                :for="`${name}-${val}`"
                :class="{
                    'bg-[var(--color-primary4)]': val == modelValue
                }"
                class="py-2.5 flex-1 flex justify-center h-full text-xs font-bold"
            >{{ option }}</label>
        </div>
    </div>
</template>