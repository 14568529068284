<script setup>
import { useResourcesStore } from '@/stores/resources';
import { storeToRefs } from 'pinia';

const resources = useResourcesStore()
const { getLeague, trendingLeagues } = storeToRefs(resources)
</script>

<template>
    <div class="block xl:hidden px-3 mt-4">
        <div class="mb-2">
            <p class="font-futura text-base text-[var(--color-primary4)] uppercase font-bold">{{$t('partials.6')}}</p>
        </div>
        <div class="bg-[var(--color-white7)] rounded">
            <ul class="list-none divide-y divide-neutral-600/50">
                <li v-for="l in trendingLeagues">
                    <RouterLink :to="`ps/${l.sid}/l/${l.locid}/${l.fid}`" class="text-sm text-white px-2 py-3 flex justify-between">
                        <div class="flex items-center">
                            <img :src="`/sport/${l.sid}.svg`" class="w-4 h-4 mr-3">
                            {{ getLeague(l.fid).nm }}
                        </div>

                        <span class="text-neutral-500">&rarr;</span>
                    </RouterLink>
                </li>
            </ul>
        </div>
    </div>
</template>
