<script setup>
import MatchGroupHeader from '@/components/Match/MatchGroupHeader.vue';
import MatchListItem from '@/components/Match/MatchListItem.vue';
import PageTitleSection from '@/components/PageTitleSection.vue';
import PrematchSportsSidebar from '@/components/PrematchSportsSidebar.vue';
import { usePrematchStore } from '@/stores/prematch';
import { useResourcesStore } from '@/stores/resources';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

const prematch = usePrematchStore()
const resources = useResourcesStore()
const { getLeague, getMarket, mainMarkets } = storeToRefs(resources)
const { eventsByLeague } = storeToRefs(prematch)
const route = useRoute()
const selectedMarket = ref(1)

const lid = computed(() => route.params.lid)
const sid = computed(() => route.params.sid)

const eventsByDate = computed(() => {
    const groups = {};

    for (const key in eventsByLeague.value(sid.value, lid.value)) {
        const event = eventsByLeague.value(sid.value, lid.value)[key];
        const { tm } = event;

        const date = new Date(tm);
        const dateString = date.toISOString().split('T')[0];

        if (!groups[dateString]) {
            groups[dateString] = [];
        }

        groups[dateString].push(event);
    }

    return groups;
})

const setMarket = (id) => {
    selectedMarket.value = id
}

watch(() => eventsByLeague.value(sid.value, lid.value), async (value) => {
    let ids = value.map(({fid}) => fid)

    await prematch.fetchSelections(ids, sid.value)
})
</script>

<template>
    <div class="flex flex-1">
        <!-- <PrematchSportsSidebar /> -->

        <div class="flex-1">
            <PageTitleSection
                :title="getLeague(lid)?.nm"
            />

            <div class="bg-[#383838] rounded">
                <div>
                    <ul class="list-none flex space-x-4 px-6">
                        <li
                            v-for="market, fid in mainMarkets"
                            @click="setMarket(fid)"
                            class="py-3 text-xs text-white opacity-80 hover:opacity-100 cursor-pointer border-b-2 -mb-px border-transparent"
                            :class="{'border-[var(--color-primary5)] text-[var(--color-primary5)] font-bold': selectedMarket == fid}"
                        >
                            {{ getMarket(fid) }}
                        </li>
                    </ul>
                </div>
                <div v-for="events, date in eventsByDate">
                    <MatchGroupHeader :title="date" :sport="sid" />

                    <ul class="list-none divide-y divide-neutral-600/50">
                        <li v-for="event in events">
                            <MatchListItem
                                :event="event"
                                :market="selectedMarket"
                            />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
