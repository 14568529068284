<script setup>
import { orderByScore } from '@/helpers';
import { computed } from 'vue';
import Odd from '../Odd.vue';

const props = defineProps({
    odds: Array,
    ht: String,
    at: String,
})

const result = computed(() => orderByScore(props.odds))

const home = computed(() => result.value.homeWin)
const draw = computed(() => result.value.draw)
const away = computed(() => result.value.awayWin)
</script>

<template>
    <div>
        <div class="grid grid-cols-3 bg-[var(--color-white7)] text-white text-xs font-bold">
            <div class="py-1.5 text-center">{{ ht }}</div>
            <div class="py-1.5 text-center">{{$t('general.8')}}</div>
            <div class="py-1.5 text-center">{{ at }}</div>
        </div>
        <div class="grid grid-cols-3">
            <div>
                <div class="flex flex-col" v-if="home">
                    <div class="OddsListItem" v-for="odd in home">
                        <Odd :odd="odd" class="py-2.5" :with-name="true" />
                    </div>
                </div>
            </div>
            <div>
                <div class="flex flex-col" v-if="draw">
                    <div class="OddsListItem" v-for="odd in draw">
                        <Odd :odd="odd" class="py-2.5" :with-name="true" />
                    </div>
                </div>
            </div>
            <div>
                <div class="flex flex-col" v-if="away">
                    <div class="OddsListItem" v-for="odd in away">
                        <Odd :odd="odd" class="py-2.5" :with-name="true" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.mg-Head {
    @apply bg-[var(--color-white7)] text-white text-xs font-bold;
}
</style>