<script setup>
import { useAuthStore } from '@/stores/auth';
import { storeToRefs } from 'pinia';
import { onMounted, ref, computed } from 'vue';
import InputLabel from '@/components/InputLabel.vue';
import DefaultButton from '@/components/Buttons/DefaultButton.vue';
import SelectField from '@/components/Form/SelectField.vue';
import RadioField from '@/components/Form/RadioField.vue';
import { useI18n } from 'vue-i18n';
import { useResourcesStore } from '@/stores/resources';
import { useUsersStore }from '@/stores/users';

const auth = useAuthStore()
const { getUser } = storeToRefs(auth)
const { t } = useI18n()
const message = ref('')

const form = ref({})
const resources = useResourcesStore();
const users = useUsersStore()
const { getLanguages } = storeToRefs(resources)
const colors = ref([])
const translatedLanguages = computed(() => {
  return getLanguages.value.map(lang => ({
    cod: lang.cod,
    nm: t(`language.${lang.cod}`)
  }));
});

const update = async () => {
    await auth.updateSettings({
        theme: form.value.theme,
        def_lang: form.value.def_lang, 
        betop:form.value.betop == 1 ? true : false
    }).then(res => {
        if (res.status == 204) {
            message.value = 'Settings updated!'
        }
    })
}
const fetchColors = async () => {
    await users.fetchColors().then(res => {
        if(res.data && res.data.ok){
            colors.value = res.data.data 
        }
    })
}
onMounted(() => {
    fetchColors()
    if(getUser.value) {
        form.value.def_lang = getUser.value.def_lang;
        form.value.betop = getUser.value.betop ? '1' : '0';
        form.value.theme = getUser.value?.theme;
    }
});
</script>

<template>
    <div>
        <template v-if="message">
            <div class="py-2 px-4 bg-emerald-200/25 text-emerald-500 text-xs font-semibold">
                {{ message }}
            </div>
        </template>
        <div class="upw-Container">
        <div>
            <h1 class="text-base font-bold">{{$t('settings.1')}}</h1>
        </div>
        <form @submit.prevent="update" class="mt-6 space-y-4">
            <div v-if="auth">
                <InputLabel :value="$t('settings.2')" />
                <SelectField
                    v-model="form.def_lang"
                    :placeholder="$t('settings.2')"
                    text-attribute="nm"
                    value-attribute="cod"
                    class="w-full mt-1"
                    :options="translatedLanguages"
                />
            </div>
            <div>
                <InputLabel :value="$t('settings.3')" />
                <RadioField 
                    v-model="form.betop"
                    :options="{1: 'Yes', 0: 'No'}"
                    name="betop"
                    class="mt-1"
                />
            </div>
            <div v-if="getUser.acc_u_th">
                <InputLabel :value="'Theme'" />
                <SelectField
                    v-model="form.theme"
                    :placeholder="$t('partials.23')"
                    class="w-full mt-1"
                    :options="colors"
                    text-attribute="nm"
                    value-attribute="cod"
                />
            </div>

            <div>
                <DefaultButton>{{$t('partials.14')}}</DefaultButton>
            </div>
        </form>
    </div>
    </div>
</template>

