export default {
    mounted(el, binding) {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    if (binding.value) {
                        binding.value();
                    } // Execute the bound function
                    observer.unobserve(el); // Stop observing once the element is visible
                }
            },
            { threshold: 1 } // Adjust threshold as needed
        );
    
        observer.observe(el);
    },
};