<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
const props = defineProps({
    event: Object
})
const isLive = computed(() => props.event.st == 2)
const homeScore = computed(() => props.event.inf?.res?.split('-')[0])
const awayScore = computed(() => props.event.inf?.res?.split('-')[1])
const router = useRouter()

const showLiveResults = ()=>{
    router.push(`/in-play/events/${props.event.sid}`)
}
</script>

<template>
    <div v-if="isLive" class="evd-LiveStats">
        <div class="border-b border-neutral-600/50 flex items-center justify-center cursor-pointer" @click="showLiveResults">
            <h3 class="evd-Participant">{{ event.ht }}</h3>
            <div class="px-2 flex">
                <h2 class="evd-Score">{{ homeScore }}</h2>
                <h2 class="evd-Score">{{ awayScore }}</h2>
            </div>
            <h3 class="evd-Participant">{{ event.at }}</h3>
        </div>
    </div>
</template>

<style scoped>
.evd-LiveStats {
    @apply xl:max-w-[485px];
    width: 100%;
}
.evd-Participant {
    @apply text-[13px] font-bold text-white flex-1 text-center text-ellipsis truncate overflow-hidden;
}
.evd-Score {
    color: var(--color-yellow1);
    font-size: 22px;
    font-weight: 900;
    height: 50px;
    @apply w-1/2 px-2 border-b-2 -mb-px flex items-center;
}
.evd-Score:first-of-type {
    border-color: rgb(240, 240, 240);
}
.evd-Score:last-of-type {
    border-color: rgb(0, 0, 0);
}
</style>