<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import SwitchLanguage from './SwitchLanguage.vue';
import SwitchOddType from './SwitchOddType.vue';

const { t } = useI18n()
</script>

<template>
    <footer class="px-4 py-3 max-w-xl mx-auto h-auto">
        <div class="text-center">
            <div class="h-12 flex justify-center">
                <div class="hm-Logo h-12" />
            </div>
            <div class="mb-4 space-y-2">
                <SwitchLanguage class="w-full" />
                <SwitchOddType class="w-full" />
            </div>
            <div>
                <small class="text-neutral-500 space-y-3 text-[11px]">
                    <p>{{t('footer.page')}}</p>
                    <p>{{t('footer.games')}}</p>
                    <p>{{t('footer.rights')}}</p>
                </small>
            </div>
        </div>
    </footer>
</template>