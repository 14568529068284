<script setup>
import { orderByGivenArray } from '@/helpers';
import Odd from '../Odd.vue';
import { computed } from 'vue';

const props = defineProps({
    order: Array,
    odds: Array
})

const width = computed(() => {
    return props.order.length % 2 == 0 
        ? 'w-1/2'
        : 'w-1/3'
})
</script>

<template>
    <ul class="list-none">
        <li v-for="odd in orderByGivenArray(odds, order, 'nm')" :class="[width, 'float-left OddsListItem']">
            <Odd 
                :odd="odd" 
                :with-name="true"
                class="py-2.5"
            />
        </li>
    </ul>
</template>