<script setup>
</script>

<template>
 <div>
    <h1>{{$t('terms.term6.p1')}}</h1>
    <h2>{{$t('terms.term6.p2')}}</h2>
    
    <ul>
        <li><strong>1-{{$t('terms.term6.p3')}}:</strong> {{$t('terms.term6.p4')}}</li>
        <li><strong>2-{{$t('terms.term6.p5')}}:</strong> {{$t('terms.term6.p6')}}</li>
        <li><strong>3-{{$t('terms.term6.p7')}}:</strong> {{$t('terms.term6.p8')}}</li>
        <li><strong>4-{{$t('terms.term6.p9')}}:</strong> {{$t('terms.term6.p10')}}</li>
    </ul>
    <p>{{$t('terms.term6.p11')}}</p>
    
    <ul>
        <li><strong>5-{{$t('terms.term6.p12')}}:</strong> {{$t('terms.term6.p13')}}</li>
    </ul>
    <p>{{$t('terms.term6.p14')}}</p>
    
    <ul>
        <li><strong>6-{{$t('terms.term6.p5')}}:</strong> {{$t('terms.term6.p16')}}</li>
        <li><strong>7-{{$t('terms.term6.p17')}}:</strong> {{$t('terms.term6.p18')}}</li>
        <li><strong>8-{{$t('terms.term6.p19')}}:</strong> {{$t('terms.term6.p20')}}</li>
    </ul>
    <p>{{$t('terms.term6.p21')}}</p>
    
    <h2>{{$t('terms.term6.p22')}}</h2>
    <p>{{$t('terms.term6.p23')}}</p>
    
    <ul>
        <li><strong>{{$t('terms.term6.p24')}}:</strong> {{$t('terms.term6.p25')}}</li>
    </ul>
    <p>{{$t('terms.term6.p26')}}</p>
 </div>
</template>
<style scoped>
   ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        margin-bottom: 10px;
        font-size: 12px;
    }
    p{
        padding: 1px 0px;
        font-size: 12px;
    }
    strong {
        color: white;
    }
    ul{
        padding: 2px 0px
    }
    h2 , h3{
        color: white;
        padding: 2px 0px;
        font-size: 12px;
    }
    h1{
        color: white;
        padding: 4px 0px;
        font-size: 14px;
    }
</style>