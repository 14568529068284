<script setup>
import MarketGroupCard from '@/components/Market/MarketGroupCard.vue';
import PrematchSportsSidebar from '@/components/PrematchSportsSidebar.vue';
import WhiteShirt from '@/components/WhiteShirt.vue';
import { timestampToDayMonth, timestampToDayMonthString, timestampToTime } from '@/helpers';
import { useAuthStore } from '@/stores/auth';
import { usePrematchStore } from '@/stores/prematch';
import { useResourcesStore } from '@/stores/resources';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import _ from 'lodash';

const router = useRouter()
const auth = useAuthStore()
const resources = useResourcesStore()
const prematch = usePrematchStore()
const { getUser } = storeToRefs(auth)
const { getSport, getLeague, getGroup, getGroupsMetadata, getMarket, getTeam } = storeToRefs(resources)
const route = useRoute()
const eid = computed(() => route.params.id)
const event = ref({})
const odds = ref([])
const activeGroup = ref(0)

const oddSet = computed(() => new Set(odds.value.map((odd) => odd.fid)));
const homeTeam = computed(() => getTeam.value(event.value.htid))
const awayTeam = computed(() => getTeam.value(event.value.atid))
const hasHomeTeamKit = computed(() => homeTeam.value && homeTeam.value.hkit)
const hasAwayTeamKit = computed(() => awayTeam.value && awayTeam.value.akit)
const dayOfMatch = computed(() => timestampToDayMonth(event.value.tm))

const groups = computed(() => {
    let result = {};
    for (const [id, group] of Object.entries(getGroupsMetadata.value)) {
        if (group.m.some((fid) => oddSet.value.has(fid))) {
            result[id] = group;
        }
    }

    result = Object.fromEntries(
        Object.entries(result).sort(([, a], [, b]) => a.ord - b.ord)
    );

    return result;
})

const oddsByGroup = computed(() => {
    let od = _.groupBy(
        odds.value.filter(odd => groups.value[activeGroup.value]?.m.includes(odd.fid)),
        'fid'
    );

    return Object.keys(od)
        .map((fid) => ({
            fid,
            order: getMarket.value(fid)?.pord ?? Infinity,
            name: getMarket.value(fid)?.nm || "Unknown",
            o: od[fid]
        }))
        .sort((a, b) => a.order - b.order);
});

const loadMarket = (market) => {
    console.log(market)
}

onMounted(async () => {
    await prematch.fetchEvent(eid.value)
        .then(async data => {
            if (data.st == 3) {
                router.push('/')
                return
            } else {
                event.value = data
                await prematch.fetchOdds(eid.value, {p: getUser.value.p ?? 0, m: getUser.value.mrg ?? 0 , sid: data.sid}).then(data => {
                    odds.value = data
                    activeGroup.value = Object.keys(groups.value)[0]
                })
            }
        })
        .catch(error => {
            router.push('/')
        })
    
})
</script>

<template>
    <div class="flex" v-if="event">
        <!-- <PrematchSportsSidebar /> -->
        
        <div class="flex-1 overflow-hidden">
            <div class="flex w-full relative items-center">
                <div class="evp-EventHeader">
                    <div class="px-8 relative">
                        <div class="pt-4">
                            <RouterLink 
                                :to="`/ps/${event?.sid}`" 
                                class="text-white text-xs"
                            >
                                &lt; {{ getSport(event.sid)?.nm }} - {{ getLeague(event.lid)?.nm }}
                            </RouterLink>
                        </div>

                        <div class="py-6 flex justify-between space-x-4">
                            <div class="flex-1 flex flex-col justify-center">
                                <div class="flex flex-col items-center">
                                    <div v-if="hasHomeTeamKit" v-html="getTeam(event.htid)?.hkit" class="w-[40px] h-[40px]" />
                                    <div v-else class="w-[40px] h-[40px]">
                                        <WhiteShirt />
                                    </div>
                                    <p class="evp-Participant">{{ homeTeam?.nm ?? event.ht }}</p>
                                </div>
                            </div>
                            <div class="text-white text-xs text-center">
                                <p>{{ timestampToTime(event.tm) }}</p>
                                <p v-if="event.tm">{{ dayOfMatch[0] }} {{ dayOfMatch[1] }} {{ dayOfMatch[2] }}</p>
                            </div>
                            <div class="flex-1 flex flex-col justify-center">
                                <div class="flex flex-col items-center">
                                    <div v-if="hasAwayTeamKit" v-html="getTeam(event.atid).akit" class="w-[40px] h-[40px]" />
                                    <div v-else class="w-[40px] h-[40px]">
                                        <WhiteShirt />
                                    </div>
                                    <p class="evp-Participant">{{ awayTeam?.nm ?? event.at }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="pb-4">
                            <ul class="list-none flex space-x-4 overflow-x-auto">
                                <li v-for="group, fid in groups">
                                    <div 
                                        class="text-[13px] text-white cursor-pointer px-2 py-1"
                                        :class="{'bg-[var(--color-primary3)] rounded-2xl !text-black font-bold': fid == activeGroup}"
                                        @click="activeGroup = fid"
                                    >
                                        {{ getGroup(fid)?.nm }}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="py-1.5 xl:py-4 px-2 xl:px-6 bg-[var(--color-grey3)] relative" v-if="activeGroup">
                <template v-for="market in oddsByGroup">
                    <MarketGroupCard
                        :fid="market.fid"
                        :odds="market.o"
                        :ht="event.ht"
                        :at="event.at"
                        v-lazy
                    />
                </template>
            </div>
        </div>
    </div>
</template>

<style scoped>
.evp-EventHeader {
    @apply flex flex-col w-full border-b;
}

.evp-EventHeader::before {
    background-image: url('/soccer-bg.webp');
    height: 100%;
    content: "";
    position: absolute;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    border-bottom: 2px solid var(--color-primary2);
}

.evp-EventShirt {
    mask-image: url("/white-shirt.svg");
    color: #fff;
    background-color: #fff;
}

.evp-EventShirtSize {
    width: 35px;
    height: 35px;
}

.evp-Participant {
    @apply text-white font-bold text-center;
    font-size: 15px;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal; 
    word-break: break-word; 
}
</style>