import CryptoJS from "crypto-js";

export const createHash = (str) => {
    return CryptoJS.MD5(str).toString(CryptoJS.enc.Hex);
}

export const timestampToDayMonth = (timestamp) => {
    const date = new Date(timestamp);

    const dayName = new Intl.DateTimeFormat('en', { weekday: 'short' }).format(date);
    const monthName = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
    const dayOfMonth = date.getDate();

    return [dayName, dayOfMonth, monthName]
}

export const getDayShortName = (timestamp) => {
    const date = new Date(timestamp);
    const dayName = new Intl.DateTimeFormat('en', { weekday: 'short' }).format(date);

    return dayName
}

export const getDayNumber = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate()
    return String(day).padStart(2, '0');
}

export const timestampToDayMonthString = (timestamp) => {
    const date = new Date(timestamp);

    const dayName = new Intl.DateTimeFormat('en', { weekday: 'short' }).format(date);
    const monthName = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
    const dayOfMonth = date.getDate();

    return `${dayName} ${dayOfMonth} ${monthName}`
}

export const timestampToTime = (timestamp) => {
    const date = new Date(timestamp);

    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${hours}:${minutes}`
}

export const orderByGivenArray = (objects, orderArray, key) => {
    const orderMap = new Map(orderArray.map((value, index) => [value, index]));
  
    return objects.sort((a, b) => {
        const indexA = orderMap.get(a[key]) ?? Infinity;
        const indexB = orderMap.get(b[key]) ?? Infinity;
        return indexA - indexB;
    });
}

export const orderByLines = (odds, lineOrder, directionOrder) => {
    const oddsMap = new Map(
        odds.filter(o => o.st == 1).map((odd) => [`${odd.ln}-${odd.nm}`, odd])
    );
  
    const grid = lineOrder.map((line) => {
        const row = directionOrder.map((direction) => {
            return oddsMap.get(`${line}-${direction}`) || null;
        });
        return { line, row };
    }).filter((value) => value.row.some(e => e != null));
  
    return grid;
}

export const orderByScore = (odds) => {
    const categories = {
        homeWin: [],
        draw: [],
        awayWin: [],
    };
    
    // Categorize odds
    odds.forEach((odd) => {
        const [home, away] = odd.nm.split("-").map(Number);
        if (home > away) {
            categories.homeWin.push(odd);
        } else if (home === away) {
            categories.draw.push(odd);
        } else {
            categories.awayWin.push(odd);
        }
    });
    
      // Sort each category by score in the format:
      // home first, then away
    Object.keys(categories).forEach((key) => {
        categories[key].sort((a, b) => {
            const [homeA, awayA] = a.nm.split("-").map(Number);
            const [homeB, awayB] = b.nm.split("-").map(Number);
            return homeA - homeB || awayA - awayB;
        });
    });
    
    return categories;
}

export const toFixedTwo = (num) => {
    const strNum = num.toString();
    const [integer, decimal = ""] = strNum.split(".");
    const trimmedDecimal = decimal.substring(0, 2)
    const paddedDecimal = trimmedDecimal.padEnd(2, "0")
    return `${integer}.${paddedDecimal}`;

}

// export const modelDateValue = () => {
//     return new Date().toISOString().split('T')[0]
// }
export const modelDateValue = () => {
    const now = new Date();
    const localDate = new Date(now.getTime() - (now.getTimezoneOffset() * 60000));
    return localDate.toISOString().split('T')[0]; 
};
export const formatToLocalDate=(utcDateStr)=> {
    const localDate = new Date(utcDateStr); 
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, "0");
    const day = String(localDate.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
}

export const timezones = () => ({
    0: "GMT-12",
    1: "GMT-11",
    2: "GMT-10",
    3: "GMT-9",
    4: "GMT-8",
    5: "GMT-7",
    6: "GMT-6",
    7: "GMT-5",
    8: "GMT-4",
    9: "GMT-3",
    10: "GMT-2",
    11: "GMT-1",
    12: "GMT",
    13: "GMT+1",
    14: "GMT+2",
    15: "GMT+3",
    16: "GMT+4",
    17: "GMT+5",
    18: "GMT+6",
    19: "GMT+7",
    20: "GMT+8",
    21: "GMT+9",
    22: "GMT+10",
    23: "GMT+11",
    24: "GMT+12",
    25: "GMT+13",
})

export function debounce(fn, delay) {
    var timeoutID = null
        return function () {

        clearTimeout(timeoutID)
        var args = arguments
        var that = this
        timeoutID = setTimeout(function () {
            fn.apply(that, args)
        }, delay)
    }
}
