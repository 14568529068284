import axios from 'axios';

const http = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Optionally, add request/response interceptors
http.interceptors.request.use(
    (config) => {
        // Get the client's timezone offset in minutes
        const timezoneOffsetMinutes = new Date().getTimezoneOffset();

        // Convert the offset to hours and format it as +,1 or -,3
        const timezoneHeader = `${timezoneOffsetMinutes <= 0 ? '+' : '-'},${Math.abs(timezoneOffsetMinutes / 60)}`;

        config.headers['X-Client-Timezone'] = timezoneHeader

        const token = localStorage.getItem('token') ?? ''
        if (token) config.headers.Authorization = `Bearer ${token}`

        return config;
    },
    (error) => Promise.reject(error)
);

http.interceptors.response.use(
    (response) => response,
    (error) => {
        // Handle errors globally (e.g., log or show a notification)
        console.error('Axios Error:', error.response || error.message);
        return Promise.reject(error);
    }
);

export default http