import { defineStore } from "pinia";
import { socket } from "@/user.socket";
import http from "@/composables/axios";
import { useResponseMessageStore } from "./response_message";

export const useAuthStore = defineStore('auth', {
    state: () => ({
        token: undefined,
        user: undefined,
        currencies: [],
        credits: [],
        userLoaded: false,
        loginError: ''
    }),

    getters: {
        isAuth: state => state.userLoaded && typeof state.user != 'undefined',
        isUserLoaded: state => state.userLoaded,
        getUser: state => state.user,
        getCredits: state => state.credits,
        getCurrencies: state => state.currencies,
        getCurrency: state => id => state.currencies.filter(c => c._id == id)[0],
        getLoginError: state => state.loginError,
        isMarketBlocked: state => id => state.user.blcm.includes(Number(id)),
        isPreSportBlocked: state => id => state.user.blcsp.includes(Number(id)),
        isLiveSportBlocked: state => id => state.user.blcsl.includes(Number(id)),
    },

    actions: {
        async fetchUser() {
            if (localStorage.token) {
                await http.get('01/user').then((res) => {
                    if (res.data.ok) {
                        this.user = res.data.data.user
                        this.currencies = res.data.data.currencies
                        this.credits = res.data.data.credits
                        if(!localStorage.getItem('lang')){
                            localStorage.setItem('lang',res.data.data.user?.def_lang || 'en')
                            window.location.reload()
                        }
                        if(res.data.data.user?.theme){
                            document.documentElement.setAttribute('th', res.data.data.user.theme)    
                        }
                        this.userLoaded = true
                    }
                }).catch(error => {
                    if (error.status == 404 && !error.response.data.ok) {
                        localStorage.removeItem('token')
                        window.location.reload()
                    }
                })
            }
        },

        async login(credentials) {
            await http.post('01/login', credentials).then(async res => {
                if (res.data.ok) {
                    this.token = res.data.data
                    localStorage.setItem('token', res.data.data)
                    // await this.fetchUser()
                    window.location.reload()
                }
            }).catch(e => {
                if (e.response.status == 401) {
                    this.loginError = 'login.unauthorized'
                } else if (e.response.status == 400) {
                    this.loginError = 'login.notAllowed'
                }
            })
        },

        logout () {
            localStorage.removeItem('token')
            localStorage.removeItem('lang')
            window.location.reload()
        },

        async updateDetails(payload) {
            return await http.put('01/user', payload)
        },

        async updatePassword(payload) {
            return await http.put('01/user/update-password', payload)
        },

        async updateSettings(payload) {
            return await http.put('01/users/settings', payload)
        },

        async fetchTransactions(query) {
            query = new URLSearchParams(query).toString()

            return await http.get(`01/user/transactions?${query}`)
        },

        bindSocketEvents() {
            const responseMessageStore = useResponseMessageStore()

            socket.on('credit:updated', (data) => {
                if (data.ok) {
                    let index = this.credits.findIndex(c => c._id == data.data.creditId)

                    if (index > -1) {
                        this.credits[index].am = data.data.amount
                    }
                }
            })

            socket.on('ticket:on-wait', (data) => {
                if (data.ok) {
                    responseMessageStore.setMessage('Ticket is being confirmed...', false)
                }
            })
        }
    }
})
