<script setup>
import { orderByGivenArray, orderByLines } from '@/helpers';
import Odd from '../Odd.vue';
import { computed, ref } from 'vue';

const props = defineProps({
    odds: Array,
    id: Number
})

const lines = computed(() => [...new Set(props.odds.map((odd) => odd.ln))].sort((a, b) => a - b))

const ids = {
    205: [
        ['1 And Over', '1 And Under'],
        ['X And Over', 'X And Under'],
        ['2 And Over', '2 And Under']
    ],
    206: [
        ['Over And Yes', 'Under And Yes'],
        ['Over And No', 'Under And No']
    ]
}

let generateOdds = (order) => {
    let data = []
    for (let line of lines.value) {
        order.forEach((order, index) => {
            let odd = props.odds.find(o => o.nm == order && o.ln == line)

            if (odd) {
                data.push(odd)
            } 
            // else {
            //     data = null
            // }
        })
    }

    return data
}

const oddsToDisplay = computed(() => {
    let obj = {}

    ids[props.id].forEach((o, i) => {
        obj[i] = generateOdds(o)
    })

    return obj
})

const columns = computed(() => ({
    2: 'w-1/2',
    3: 'w-1/3'
}[ids[props.id].length]))
</script>

<template>
    <div>
        <div class="flex">
            <ul :class="columns" v-for="(o, i) in oddsToDisplay">
                <li v-for="odd in o">
                    <Odd :odd="odd" :with-name="true" :with-line="true" class="py-2.5" />
                </li>
            </ul>
        </div>
    </div>
</template>