<script setup>
import { onMounted, ref } from 'vue';
import DefaultButton from '@/components/Buttons/DefaultButton.vue';
import TextField from '@/components/Form/TextField.vue';
import InputLabel from '@/components/InputLabel.vue';

const emit = defineEmits(['submitted'])

const props = defineProps({
    auth: {
        type: Boolean,
        default: false
    }
})

const form = ref({
    password: ''
})

onMounted(() => {
    if (props.auth) {
        form.value.current_password = ''
        form.value.password_confirmation = ''
    }
})
</script>

<template>
    <div class="upw-Container">
        <div>
            <h1 class="text-base font-bold">{{$t('partials.15')}}</h1>
        </div>

        <form @submit.prevent="$emit('submitted', form)" class="mt-6 space-y-4">
            <div v-if="auth">
                <InputLabel :value="$t('partials.25')" />
                <TextField
                    v-model="form.current_password"
                    type="password"
                    :placeholder="$t('partials.25')"
                    class="w-full mt-1"
                />
            </div>

            <div>
                <InputLabel :value="$t('partials.26')" />
                <TextField
                    v-model="form.password"
                    type="password"
                    :placeholder="$t('partials.26')"
                    class="w-full mt-1"
                />
            </div>

            <div v-if="auth">
                <InputLabel :value="$t('partials.27')" />
                <TextField
                    v-model="form.password_confirmation"
                    type="password"
                    :placeholder="$t('partials.27')"
                    class="w-full mt-1"
                />
            </div>

            <div>
                <DefaultButton>{{$t('partials.14')}}</DefaultButton>
            </div>
        </form>
    </div>
</template>
<style>
.upw-Container {
    background-color: #ebeded;
    @apply px-6 py-4
}
</style>