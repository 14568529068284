<script setup>
import { useBetsStore } from '@/stores/bets';
import { useResourcesStore } from '@/stores/resources';
import { storeToRefs } from 'pinia';
import { computed, ref, onMounted } from 'vue';
import MyBetFooterAmount from './MyBetFooterAmount.vue';
import { useAuthStore } from '@/stores/auth';
import { useI18n } from 'vue-i18n';
import { timestampToDayMonth, timestampToTime, toFixedTwo, timestampToDayMonthString } from '@/helpers';
import MyBetTimeline from './MyBetTimeline.vue';
import MyBetCashout from './MyBetCashout.vue';
import display from '@/markets-display'
import PrintTicket from './PrintTicket.vue'

const props = defineProps({
    ticket: Object,
})

const emit = defineEmits(['cashout'])
const { t, te } = useI18n()
const auth = useAuthStore()
const resources = useResourcesStore()
const bets = useBetsStore()
const { getSport ,getMarket } = storeToRefs(resources)
const { getCurrency, getUser } = storeToRefs(auth)


const isOpen = computed(() => props.ticket.st == 6 && getUser.value.betop)

const timeline = ref(0)
const timelineSlip = ref(0)
const collapsed = ref(!isOpen.value)
const betslips = ref([])

const date = computed(() => timestampToDayMonth(props.ticket.createdAt))

const openTimeline = (eid, oddid) => {
    if (timeline.value == eid && timelineSlip.value == oddid) {
        timeline.value = 0
        timelineSlip.value = 0
    } else {
        timeline.value = eid
        timelineSlip.value = oddid
    }
}

const fetchSlips = async () => {
    if (collapsed.value) {
        await bets.fetchBetslips(props.ticket._id).then(res => {
            if (res.data.ok) {
                betslips.value = res.data.data
                collapsed.value = false
            }
        })
    } else {
        collapsed.value = true
    }
}
const marketName = (m, ht, at) => {
    const regexHome = new RegExp('\\{1\\}', 'g');
    const regexAway = new RegExp('\\{2\\}', 'g');
    const regexScore = new RegExp('\\{score\\}', 'g');

    let market = getMarket.value(m.mfid)?.nm 
    
    if (market) {
        market = market.replace(regexHome, ht)
        market = market.replace(regexAway, at)
        if (m.mfid == 59) {
            const ln = m.ln?.split('.')[0];
            if (ln) {
                market = market.replace(regexScore, ln)
            }
        }
    }
    return market
}

const displayOptions = (id) => id in display ? display[id] : undefined

const withLine = computed(() => (selection) => {
    let dis = displayOptions(selection.mfid);

    if (!dis) return false;

    return dis.id == 205 || dis.id == 206;
});

const selectionTranslation = (selection) => {
    if(!withLine.value(selection)) {
        return te(selection.oddnm) ? t(selection.oddnm) : selection.oddnm;
    }else{
        return t(selection.oddnm + ' ' + selection.ln) 
    }
}

const areSelectionsWaiting = computed(() => betslips.value.every((b) => b.st != 1))

onMounted(() => {
  if (!collapsed.value ) {
    bets.fetchBetslips(props.ticket._id).then(res => {
      if (res.data.ok) {
        betslips.value = res.data.data;
      }
    });
  }
});
const printTicketRef = ref(null);
const openPrintModal = async (ticketNr)=>{
    if(collapsed.value ){
        await bets.fetchBetslips(props.ticket._id).then(res => {
            if (res.data.ok) {
                betslips.value = res.data.data
            }
        })
    }
    printTicketRef.value.openModal();
}

</script>

<template>
    <div class="myb-Ticket">
        <div class="myb-TicketHeader" @click="fetchSlips">
            <div class="myb-TicketHeaderNr">
                <p>
                    <template v-if="getUser.rl == 'clt'">{{ ticket.nr }}</template>
                    <template v-else>{{ ticket.unm }}</template>
                </p>
                <p class="text-xs font-normal text-neutral-400">
                    {{ date[0] }} {{ date[1] }} {{ date[2] }}
                    {{ timestampToTime(ticket.createdAt) }}
                </p>
            </div>
            <div class="myb-TicketHeaderAct">
                <div
                    v-if="ticket.st != 6"
                    :class="[
                        'myb-TicketStatus',
                        {'win': ticket.st == 2},
                        {'lost': ticket.st == 1}
                    ]"
                >
                    <span class="mr-1.5">{{ t('ticket.' + ticket.st) }}</span>
                    <span v-if="ticket.st == 2"><span v-if="ticket.is_c_out">Cashout&emsp14;</span>{{ ticket.finp }}</span>
                    <span v-if="ticket.st == 1">{{ ticket.am }}</span>
                    <span class="ml-0.5" v-if="ticket.st == 2 || ticket.st == 1">{{ getCurrency(ticket.cuid).cod }}</span>
                </div>

                <div
                    class="myb-TicketCancel"
                    v-if="ticket.st == 6"
                    @click.stop="bets.cancelTicket(ticket._id)"
                >{{$t('partials.7')}}</div>
                <button class="flex items-center justify-end" @click.stop="openPrintModal(ticket.nr)" >
                    <img src="/print.svg" class="w-5 h-5"/>
                </button>
            </div>
        </div>

        <div v-if="!collapsed" class="myb-TicketBetslips">
            <ul class="list-none space-y-5">
                <li v-for="slip in betslips">
                    <div class="myb-TicketBetslip">
                        <div class="flex-shrink-0 mt-1 mr-1.5">
                            <img src="/win.svg" class="w-3 h-3" v-if="slip.st == 2" />
                            <img src="/lost.svg" class="w-3 h-3" v-if="slip.st == 1" />
                            <div class="w-3 h-3 bg-neutral-400 rounded-full" v-if="slip.st != 2 && slip.st != 1" />
                        </div>
                        <div class="flex-1 cursor-pointer" @click="openTimeline(slip.eid, slip.oddid)">
                            <div class="text-neutral-400 text-xs">
                                <p>{{timestampToDayMonthString(slip.tm)}} {{ timestampToTime(slip.tm)}}</p>
                            </div>
                            <p class="text-neutral-200 font-bold text-sm relative myb-SlipTeams ">
                                <span v-if="slip.isl">Live - </span>
                                <span>{{ slip.ht }} v {{ slip.at }}</span>
                            </p>
                            <div class="mt-1 flex justify-between">
                                <p class="text-neutral-400 text-xs">
                                    <span>{{ marketName( slip, slip.ht , slip.at)  }}</span>
                                    ({{ selectionTranslation (slip) }}<span v-if="slip.ln && !withLine(slip)"> &emsp14;{{ slip.ln }}</span>)
                                </p>
                                <p class="text-neutral-300 text-xs">{{ toFixedTwo(slip.p) }}</p>
                            </div>

                            <template v-if="timeline == slip.eid && timelineSlip == slip.oddid">
                                <MyBetTimeline
                                    :eid="timeline"
                                    :sid="slip.sid"
                                />
                            </template>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <template v-if="!collapsed">
            <div class="border-t border-neutral-500/30">
                <div class="grid grid-cols-6 px-4 py-3">
                    <MyBetFooterAmount
                        class="col-span-2"
                        label="Stake"
                        :value="ticket.am"
                        :currency="getCurrency(ticket.cuid).cod"
                    />
                    <MyBetFooterAmount
                        class="col-span-2"
                        label="Total Return"
                        :value="ticket.mx"
                        :currency="getCurrency(ticket.cuid).cod"
                        v-if="ticket.tp != 2"
                    />
                    <MyBetFooterAmount
                        class="col-span-2"
                        :label="'Bonus('+ ticket.b_value + '%)'"
                        :value="toFixedTwo(ticket?.bn)"
                        :currency="getCurrency(ticket.cuid).cod"
                        v-if="ticket.b_value"
                    />
                </div>
                <div class="grid grid-cols-3 px-4 py-3 border-t border-neutral-500/30" v-if="ticket.tp == 2">
                    <MyBetFooterAmount
                        label="Min. To Return"
                        :value="ticket.mn"
                        :currency="getCurrency(ticket.cuid).cod"
                    />
                    <MyBetFooterAmount
                        label="Max. To Return"
                        :value="ticket.mx"
                        :currency="getCurrency(ticket.cuid).cod"
                    />
                </div>
            </div>

            <MyBetCashout
                :ticket-id="ticket._id"
                v-if="ticket.tp != 2 && ticket.st == 6 && !ticket.is_c_out && areSelectionsWaiting && getUser.accashout"
                @accepted="$emit('cashout')"
            />
        </template>
        <PrintTicket ref="printTicketRef" :ticket="ticket" :betslips="betslips" :key="ticket._id"/>
    </div>
</template>


<style scoped>
.myb-Ticket {
    background-image: var(--mgc-bg);
    @apply rounded-md overflow-hidden;
}

.myb-TicketHeader {
    @apply h-14 px-4 flex items-center justify-between cursor-pointer;
}

.myb-TicketStatus {
    @apply text-xs;
}
.myb-TicketStatus.win {
    @apply text-emerald-400
}
.myb-TicketStatus.lost {
    color: red;
}

.myb-TicketHeaderNr {
    @apply font-bold;
    color: var(--color-primary4)
}
.myb-TicketHeaderAct{
    @apply flex items-center gap-2
}
.myb-TicketCancel {
    @apply inline-flex text-xs text-neutral-400 border border-neutral-400 px-1.5 py-1 cursor-pointer;
}

.myb-TicketBetslips {
    @apply border-t border-neutral-500/30 px-4 py-2;
}

.myb-TicketBetslip {
    @apply flex;
}
.myb-SlipTeams{
    width: 100%;
    padding-right: 4px;
    display: -webkit-box;
    -webkit-line-clamp: 1; 
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal; 
    word-break: break-word; 
}
.myb-SlipTeams::after {
    content: '>';
    font: 28px "Consolas", monospace;
    color: #bababa;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    right: 0;
    padding: 0 0 0;
    position: absolute;
    pointer-events: none;
    top: -1px;
    
}
</style>
