import { defineStore, storeToRefs } from "pinia";
import { socket } from '@/user.socket';
import { socket as socketLive } from '@/feed.socket';
import { usePrematchStore } from "./prematch";
import { useLiveStore } from "./live";
import { useResponseMessageStore } from "./response_message";
import { useI18n } from "vue-i18n";
import { useAuthStore } from "./auth";

export const useBetslipStore = defineStore('betslip', {
    state: () => ({
        slips: {},
        oddType: 'p',
        changes: false,
        updates: false,
        confirming: false,
        ticketId: null,
        sendingTicket: false,
    }),

    getters: {
        getSendingTicket: state => state.sendingTicket,
        hasChanges: state => state.changes,
        hasUpdates: state => state.updates,
        getOddType: state => state.oddType,
        getSlips: state => state.slips,
        isHighlited: state => uid => {
            for (let eid in state.slips) {
                if (uid in state.slips[eid].betslips) {
                    return true
                }
            }
            return false
        },
        isEventSelected: state => eid => eid in state.slips,
        cols(state) {
            let counter = 1
            for (let eid in state.slips) {
                counter *= Object.keys(state.slips[eid].betslips).length
            }
            return counter
        },
        selections(state) {
            let counter = 0
            for (let eid in state.slips) {
                counter += Object.keys(state.slips[eid].betslips).length
            }

            return counter
        },
        totalOdd(state) {
            let odd = 1;
            for (let eid in state.slips) {
                for (let uid in state.slips[eid].betslips) {
                    odd *= state.slips[eid].betslips[uid].p
                }
            }
            return odd
        },

        minAndMax: state => {
            let mins = []
            let maxs = []

            for (let eid in state.slips) {
                let min = 9999999
                let max = 1
                for (let uid in state.slips[eid].betslips) {
                    if (state.slips[eid].betslips[uid].p < min) min = state.slips[eid].betslips[uid].p
                    if (state.slips[eid].betslips[uid].p > max) max = state.slips[eid].betslips[uid].p
                }

                maxs.push(max)
                mins.push(min)
            }

            return [
                mins.reduce((acc, number) => acc * number, 1),
                maxs.reduce((acc, number) => acc * number, 1)
            ]
        },

        bonusValue(state){
           const auth = useAuthStore()
            const { getUser } = storeToRefs(auth)
            let bonusPerc
            if(this.selections && getUser.value?.tb ){
                if(getUser.value?.tb[this.selections] ){
                    bonusPerc = getUser.value?.tb[this.selections] 
                }
            }
            return bonusPerc
        }
    },

    actions: {
        setOddType (type) {
            this.oddType = type
            localStorage.setItem('oddType', type)
        },

        initializeFromLocalStorage() {
            let t = localStorage.getItem('ticket')
            if (t) {
                this.slips = JSON.parse(t)
            }
        },

        setOdd(odd) {
            if (odd.st != 1) return;

            if (!(odd.eid in this.slips)) {
                this.slips[odd.eid] = {isl: odd.isl, betslips: {}}
            }

            if (!(odd.uid in this.slips[odd.eid].betslips)) {
                this.slips[odd.eid].betslips[odd.uid] = JSON.parse(JSON.stringify(odd))
            } else {
                delete this.slips[odd.eid].betslips[odd.uid]

                if (!Object.keys(this.slips[odd.eid].betslips).length) {
                    delete this.slips[odd.eid]
                }
            }

            localStorage.setItem('ticket', JSON.stringify(this.slips))
        },
        updateOdd(eid, odd) {
            if (this.confirming) return;

            if (this.updateKoef(eid, odd)) {
                this.updates = true
            }

            localStorage.setItem('ticket', JSON.stringify(this.slips))
        },

        updateKoef(eid, odd) {
            if (!this.changes) {
                if (eid in this.slips) {
                    if (odd.uid in this.slips[eid].betslips) {
                        const oldPrice = this.slips[eid].betslips[odd.uid].p
                        this.slips[eid].betslips[odd.uid] = odd

                        if (oldPrice > odd.p) {
                            this.slips[eid].betslips[odd.uid].n = 1
                        } else {
                            this.slips[eid].betslips[odd.uid].n = 2
                        }

                        return true
                    }
                }
            }
            return false
        },

        acceptUpdates() {
            this.updates = false
            for (let eid in this.slips) {
                for (let uid in this.slips[eid].betslips) {
                    delete this.slips[eid].betslips[uid].n
                }
            }
        },

        pullSlip(eid) {
            if (eid in this.slips) {
                delete this.slips[eid]
            }

            localStorage.setItem('ticket', JSON.stringify(this.slips))
        },

        send(cur_id, amount) {
            if (this.changes) {
                this.acceptChanges()
                return;
            }

            try {
                this.sendingTicket = true
                const prematch = usePrematchStore()
                const live = useLiveStore()
                const { getEvent: getLiveEvent } = storeToRefs(live)
                const { getEvent: getPrematchEvent } = storeToRefs(prematch)

                for (let event in this.slips) {
                    let e;

                    if (this.slips[event].isl) {
                        e = JSON.parse(JSON.stringify(getLiveEvent.value(event)))
                    } else {
                        e = JSON.parse(JSON.stringify(getPrematchEvent.value(event)))
                    }

                    e.result = e?.inf?.res || '0-0' 
                    e.cor = e?.inf?.cor || '0-0'

                    delete e['m']
                    delete e['inf']
                    this.slips[e.fid] = {...this.slips[e.fid], ...e}
                }
                socket.emit('ticket:create', {
                    cur_id,
                    amount: Number(amount),
                    ticket: this.slips,
                })
            } catch (error) {
                this.sendingTicket = false
                console.error(error.message)
            }
        },

        clear() {
            this.sendingTicket = false
            this.slips = {}
            this.confirming = false
            localStorage.setItem('ticket', '')
        },

        acceptChanges() {
            if (!this.ticketId) return;

            socket.emit('ticket:accept-offer', {ticketId: this.ticketId})
            this.changes = false
            this.ticketId = null

            this.clear()
        },

        rejectChanges() {
            if (!this.ticketId) return;

            socket.emit('ticket:refuse-offer', {ticketId: this.ticketId})
            this.changes = false
            this.sendingTicket = false
            this.ticketId = null
        },

        bindSocketEvents() {
            const responseMessageStore = useResponseMessageStore()
            const { t } = useI18n()

            socket.on('ticket:create', (data) => {
                if (data.ok) {
                    responseMessageStore.setMessage(t('betslips.created'))
                    for (let event in this.slips) {
                        if (this.slips[event].isl) {
                            socketLive.emit('leave-live-event', {eid: event})
                        }
                    }
                    this.clear()
                } else {
                    console.log('no')
                    this.sendingTicket = false
                    this.confirming = false
                    responseMessageStore.setMessage(t(data.message, {extra: data.extra}))
                }
            })

            socket.on('ticket:confirming', (data) => {
                if (data.ok) {
                    this.confirming = true
                    responseMessageStore.setMessage(t(data.message, {extra: data.extra}))
                }
            })

            socket.on('ticket:offer', (data) => {
                if (data.ok) {
                    responseMessageStore.setMessage(t(data.message, {extra: data.extra}))

                    for (let eid in data.data.s) {
                        for (let uid in data.data.s[eid]) {
                            if (this.slips[eid].betslips[uid].p > data.data.s[eid][uid]) {
                                this.slips[eid].betslips[uid].n = 1
                            } else {
                                this.slips[eid].betslips[uid].n = 2
                            }

                            this.slips[eid].betslips[uid].p = data.data.s[eid][uid]

                            this.changes = true
                            this.sendingTicket = false
                        }
                    }
                    this.ticketId = data.data._id
                }
            })

            socket.on('ticket:accept-offer', (data) => {
                if (data.ok) {
                    responseMessageStore.setMessage(t('betslips.created'))
                }
            })
        }
    }
})
