<script setup>
import { ref, watch, computed } from 'vue';
import SelectField from '../Form/SelectField.vue';
import { useI18n } from 'vue-i18n';
import { useAuthStore } from '@/stores/auth';
import { storeToRefs } from 'pinia';
import { useResourcesStore } from '@/stores/resources';
const { locale, t } = useI18n()

const auth = useAuthStore()
const { getUser } = storeToRefs(auth)
const resources = useResourcesStore();
const { getLanguages } = storeToRefs(resources)

const lang = ref(localStorage.getItem('lang') || getUser.value.def_lang)

watch(lang, (val) => {
    localStorage.setItem('lang', val)
    window.location.reload()
})
const translatedLanguages = computed(() => {
  return getLanguages.value.map(lang => ({
    cod: lang.cod,
    nm: t(`language.${lang.cod}`)
  }));
});
</script>

<template>
    <div class="max-w-xl">
        <SelectField 
            :options="translatedLanguages"
            v-model="lang"
            text-attribute="nm"
            value-attribute="cod"
            class="text-sm w-full"
        />
    </div>
</template>