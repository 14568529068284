<script setup>
</script>

<template>
 <div>
    <h1>{{$t('terms.term4.title')}}</h1>
    
    <ul>
        <li><strong>1.</strong> {{$t('terms.term4.p1')}}</li>
        <li><strong>2.</strong> {{$t('terms.term4.p2')}}</li>
        <li><strong>3.</strong> {{$t('terms.term4.p3')}}</li>
        <li><strong>4.</strong> {{$t('terms.term4.p4')}}</li>
        <li><strong>5.</strong> {{$t('terms.term4.p5')}}</li>
        <li><strong>6.</strong> {{$t('terms.term4.p6')}}</li>
        <li><strong>7.</strong> {{$t('terms.term4.p7')}}</li>
    </ul>

    <h2>{{$t('terms.term4.p8')}}</h2>
    
    <ul>
        <li><strong>1-{{$t('terms.term4.p9')}}:</strong> {{$t('terms.term4.p10')}}</li>
        <li><strong>2-{{$t('terms.term4.p11')}}:</strong> {{$t('terms.term4.p12')}}</li>
        <li><strong>3-{{$t('terms.term4.p13')}}:</strong> {{$t('terms.term4.p14')}}</li>
        <li><strong>4-{{$t('terms.term4.p15')}}:</strong> {{$t('terms.term4.p16')}}</li>
        <li><strong>5-{{$t('terms.term4.p17')}}:</strong> {{$t('terms.term4.p18')}}</li>
    </ul>
 </div>
</template>
<style scoped>
   ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        margin-bottom: 10px;
        font-size: 12px;
    }
    p{
        padding: 1px 0px;
        font-size: 12px;
    }
    strong {
        color: white;
    }
    ul{
        padding: 2px 0px
    }
    h2 , h3{
        color: white;
        padding: 2px 0px;
        font-size: 12px;
    }
    h1{
        color: white;
        padding: 4px 0px;
        font-size: 14px;
    }
</style>