<template>
  <Modal :show="showTicketModal" @close="showTicketModal = false" max-width="full" :closeable="true">
    <div class="ticket-container">
      <div class="ticket-header-info">
        <p>{{ ticket.unm }}.{{ ticket.nr }}</p>
        <p>{{ timestampToDayMonthString(ticket.createdAt) }} {{ timestampToTime(ticket.createdAt) }}</p>
      </div>
      <table>
        <thead>
          <tr>
            <th class="truncate">{{ t('print.2') }}</th>
            <th class="truncate">{{ t('print.3') }}</th>
            <th class="truncate">{{ t('print.4') }}</th>
            <th class="truncate">{{ t('print.5') }}</th>
            <th class="truncate">{{ t('print.6') }}</th>
            <th class="truncate">{{ t('print.7') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="slip in betslips" :key="slip._id">
            <td class="truncate">{{ slipDate(slip.tm) }}</td>
            <td class="truncate">{{ timestampToTime(slip.tm) }}</td>
            <td class="truncate">{{ slip.ht }}-{{ slip.at }}</td>
            <td class="truncate">{{ slip.oddnm }}</td>
            <td class="truncate">{{ slip.mnm }}</td>
            <td class="truncate">{{ slip.p }}</td>
          </tr>
        </tbody>
      </table>
      <div class="ticket-details">
        <p class="ticket-detail" v-if="ticket.tp !== 2">
          <span>{{ t('print.15') }}:</span> 
          <span class="truncate"> {{ ticket?.total_max_odd }}</span>
        </p>
        <p class="ticket-detail" v-if="ticket.tp == 2">
          <span>{{ t('print.14') }}:</span> 
          <span class="truncate"> {{ ticket?.total_max_odd }}</span>
        </p>
        <p class="ticket-detail" v-if="ticket.tp == 2">
          <span>{{ t('print.13') }}:</span> 
          <span class="truncate"> {{ ticket?.total_min_odd }}</span>
        </p>
        <p class="ticket-detail">
          <span>{{ t('general.9') }}:</span> 
          <span class="truncate">{{ betslips.length }}</span>
        </p>
        <p class="ticket-detail">
          <span>{{ t('app.18') }}:</span> 
          <span class="truncate">{{ ticket.am }} {{ getCurrency(ticket.cuid)?.cod }}</span>
        </p>
        <p class="ticket-detail" v-if="ticket?.b_value">
          <span>{{ t('print.9') }}({{ticket.b_value}}%) :</span> 
          <span class="truncate" v-if="ticket?.bn">{{toFixedTwo(ticket?.bn)}} {{ getCurrency(ticket.cuid)?.cod }}</span>
        </p>
        <p class="ticket-detail" v-if="ticket.tp !== 2">
          <span>{{ t('print.10') }}:</span> 
          <span class="truncate">{{ ticket.mx }} {{ getCurrency(ticket.cuid)?.cod }}</span>
        </p>
        <p class="ticket-detail" v-if="ticket.tp == 2">
          <span>{{ t('print.11') }}:</span> 
          <span class="truncate">{{ ticket.mn }} {{ getCurrency(ticket.cuid)?.cod }}</span>
        </p>
        <p class="ticket-detail" v-if="ticket.tp == 2">
          <span>{{ t('print.12') }}:</span> 
          <span class="truncate">{{ ticket.mx }} {{ getCurrency(ticket.cuid)?.cod }}</span>
        </p>
      </div>
      <div class="ticket-footer">{{ t('print.16') }}</div>
    </div>
  </Modal>
</template>

<script setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { timestampToDayMonthString, timestampToTime, timestampToDayMonth, toFixedTwo } from '@/helpers';
import { useAuthStore } from '@/stores/auth';
import Modal from '@/components/Modal.vue';

const props = defineProps({
  ticket: Object,
  betslips: Array,
});
const { t } = useI18n();
const auth = useAuthStore();
const { getCurrency } = storeToRefs(auth);
const showTicketModal = ref(false);

const slipDate = (slipDate) => {
  let d = timestampToDayMonth(slipDate)
  return d[1]+ ' '  + d[2]
}

defineExpose({
  openModal: () => {
    showTicketModal.value = true;
  }
});
</script>
<style scoped>
.ticket-container {
  padding:0px 2px;
  width: 100%;
  background: #fff;
}
.ticket-header-info {
  font-size: 12px;
  text-align: left;
  padding: 5px 0px;
}
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 2px;
  font-size: 10px;
  border: 1px solid #000;
}
thead th {
  background-color: #000 !important;
  color: #fff !important;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  padding: 2px;
  border-bottom: 1px solid #b4b4b4;
  text-align: center;
}
tbody td {
  text-align: center;
  padding: 2px;
  border-bottom: 1px solid #b4b4b4;
}
tbody tr:last-child td {
  border-bottom: 1px solid #000;
}
.ticket-details {
  width: 100%;
  margin-top: 10px;
  font-size: 10px;
}
.ticket-detail {
  width: 99%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ticket-footer {
  margin-top: 10px;
  font-size: 10px;
  text-align: center;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
}
</style>
