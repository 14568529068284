<script setup>
import PageTitleSection from '@/components/PageTitleSection.vue';
import { usePrematchStore } from '@/stores/prematch';
import { useResourcesStore } from '@/stores/resources';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const prematch = usePrematchStore()
const resources = useResourcesStore()
const { getSport, getLocation, getLeague } = storeToRefs(resources)
const { leaguesGroupedByLocation } = storeToRefs(prematch)
const route = useRoute()
const sid = computed(() => route.params.id)
</script>

<template>
    <div class="flex w-full">
        <div class="flex-1">
            <PageTitleSection
                :title="getSport(sid)?.nm"
            />

            <div>
                <ul class="list-none divide-y divide-neutral-600/50">
                    <li v-for="location in leaguesGroupedByLocation(sid)" class="pb-2">
                        <div class="py-3 px-4 flex items-center space-x-3">
                            <img :src="`/loc/${location.loid}.svg`" class="w-5 h-5 rounded-full" alt="">
                            <h3 class="text-[15px] text-white font-bold">{{ location.name }}</h3>
                        </div>

                        <ul class="list-none grid md:grid-cols-2 px-1 xl:px-0">
                            <li v-for="(data, lid) in location.leagues" class="rounded overflow-hidden">
                                <RouterLink
                                    :to="`/ps/${sid}/l/${location.loid}/${lid}`"
                                    class="py-3 px-4 flex justify-between hover:bg-neutral-600/30 cursor-pointer"
                                >
                                    <p class="text-[13px] text-white">{{ getLeague(lid)?.nm }}</p>

                                    <div
                                        class="text-[11px] bg-[var(--color-primary5)] font-bold px-0.5 rounded-sm flex items-center h-3"
                                    >
                                        {{ data.count }} &raquo;
                                    </div>
                                </RouterLink>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
