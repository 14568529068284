<script setup>
import { useAuthStore } from '@/stores/auth'
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import LiveInfo from './Partials/Info/LiveInfo.vue';
import FootballInfo from './Partials/Info/FootballInfo.vue';
import BasketballInfo from './Partials/Info/BasketballInfo.vue';
import TeninsInfo from './Partials/Info/TeninsInfo.vue';
import Formula1Info from './Partials/Info/Formula1Info.vue';
import GeneralInfo from './Partials/Info/GeneralInfo.vue';
const { t } = useI18n()
const authStore = useAuthStore()
const tabs = ref([
    {0: t('terms.term1.title')},
    {1: t('terms.term2.title')},
    {2: t('terms.term3.title')},
    {3: t('terms.term4.title')},
    {4: t('terms.term5.title')},
    {5: t('terms.term6.title')},
])
const activeTab = ref(0)
const setTab = async (index) => {
    activeTab.value = index
}
</script>

<template>
    <div>
        <p class="text-white text-center text-sm font-bold py-2">{{$t('terms.1')}}</p>
        <div class="h-12 px-1 flex w-full">
            <ul class="list-none flex items-center w-full overflow-x-auto space-x-2">
                <li
                    v-for="tab, i in tabs"
                    :class="['tab-item', {'active': activeTab == i}]"
                    @click="setTab(i)"
                >
                    <span>{{ tab[i] }}</span>
                </li>
            </ul>
        </div>
        <div class="px-4 py-2 text-sm font-semibold text-white/60 ">
            <GeneralInfo v-if="activeTab == 0"/>
            <LiveInfo v-if="activeTab == 1" />
            <FootballInfo v-if="activeTab == 2"/>
            <TeninsInfo v-if="activeTab == 3"/>
            <BasketballInfo v-if="activeTab == 4"/>
            <Formula1Info v-if="activeTab == 5"/>
        </div>
    </div>
</template>

<style scoped>
.hm-Padd {
    padding: 0 calc((100vw - 1450px)/2);
}

.hm-Link {
    @apply inline-flex items-center border-b-2 border-transparent text-white text-[10px] font-bold;
}
.hm-Link.router-link-exact-active {
    color: var(--hm-selected);
    border-color: var(--hm-selected);
}
</style>
