import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'


import en from './locales/en.json'
import sq from './locales/sq.json'
import it from './locales/it.json'
import de from './locales/de.json'
import es from './locales/es.json'
import tr from './locales/tr.json'
import gr from './locales/gr.json'

import App from './App.vue'
import router from './router'
import lazy from './directives/lazy'

const app = createApp(App)
const i18n = createI18n({
    legacy: false,
    locale: localStorage.getItem('lang') || 'en',
    fallbackLocale: 'en',
    messages: {
        en,
        sq,
        it,
        de,
        es,
        tr,
        gr
    }
})

app.use(createPinia())
app.use(router)
app.use(i18n)
app.directive('lazy', lazy)

app.mount('#app')
