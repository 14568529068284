<script setup>
import { useAuthStore } from '@/stores/auth';
import { storeToRefs } from 'pinia';
import Dropdown from '../Dropdown.vue';
import DropdownLink from '../DropdownLink.vue';
import { useChatStore } from '@/stores/chat';

const auth = useAuthStore()
const chat = useChatStore()
const { getCredits, getCurrency, getUser } = storeToRefs(auth)
const { unreadMessages } = storeToRefs(chat)
</script>

<template>
    <Dropdown width="80">
        <template #trigger>
            <button class="inline-flex flex-col-reverse xl:flex-row items-center">
                <p class="text-[11px] text-white">{{ getCredits[0].am }} {{getCurrency(getCredits[0].cuid._id).cod}} </p>
                <div class="hm-MemberMenuIcon xl:ml-3" />

                <div class="absolute top-0 right-0" v-if="unreadMessages.length">
                    <div class="w-4 h-4 bg-[var(--color-primary4)] rounded-full flex items-center justify-center">
                        <p class="text-xs font-extrabold">{{ unreadMessages.length }}</p>
                    </div>
                </div>
            </button>
        </template>

        <template #content>
            <div class="divide-y divide-neutral-200">
                <DropdownLink 
                    :href="`/transactions/${credit.cuid._id}`"
                    class="!py-1"
                    v-for="credit in getCredits"
                >
                    <div>
                        <p class="text-xs">{{ getUser.un }}</p>
                        <p class="text-lg font-medium">{{ credit.am }} <span class="text-xs text-neutral-600 font-normal">{{ getCurrency(credit.cuid._id).cod }}</span></p>
                    </div>
                </DropdownLink>
                <DropdownLink href="/chat" v-if="getUser.acc">
                    {{$t('general.1')}}
                    <span 
                        class="w-4 h-4 bg-[var(--color-primary4)] rounded-full flex items-center justify-center font-extrabold text-xs ml-1" 
                        v-if="unreadMessages.length"
                    >{{ unreadMessages.length }}</span>
                </DropdownLink>
                <DropdownLink href="/finance">{{$t('general.2')}}</DropdownLink>
                <template v-if="getUser.rl != 'clt'">
                    <DropdownLink href="/users">{{$t('general.3')}}</DropdownLink>
                    <DropdownLink href="/create-user">{{$t('general.4')}}</DropdownLink>
                </template>
                <DropdownLink href="/my-account">{{$t('general.5')}}</DropdownLink>
                <DropdownLink href="/settings">{{$t('settings.1')}}</DropdownLink>
                <DropdownLink href="/terms">{{$t('terms.1')}}</DropdownLink>
                <DropdownLink href="/change-password">{{$t('general.6')}}</DropdownLink>
                <DropdownLink as="button" @click="auth.logout">{{$t('general.7')}}</DropdownLink>
            </div>
        </template>
    </Dropdown>
</template>

<style scoped>
.hm-MemberMenuIcon {
    display: flex;
    align-items: center;
    background-image: url('/user.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    width: 35px;
    height: 19px;
    cursor: pointer;
    position: relative;
}

.hm-UserPassworIcon {
    background-image: url('/lock-control.svg');
    background-repeat: no-repeat;
    width: 38px;
    height: 36px;
}

.hm-UserSettingsIcon {
    background-image: url('/user-settings.svg');
    width: 38px;
    height: 36px;
}
</style>
