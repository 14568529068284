<script setup>
import { onMounted, ref, watch } from 'vue';
import SelectField from '../Form/SelectField.vue';
import { useBetslipStore } from '@/stores/betslip';

const betslips = useBetslipStore()
const type = ref(localStorage.getItem('oddType') ?? 'p')

watch(type, (val) => {
    betslips.setOddType(val)
})

onMounted(() => {
    betslips.setOddType(type.value)
})
</script>

<template>
    <div class="max-w-xl">
        <SelectField
            :options="[
                {name: 'Decimal', code: 'p'},
                {name: 'Fractional', code: 'puk'},
                {name: 'American', code: 'pus'},
            ]"
            value-attribute="code"
            text-attribute="name"
            v-model="type"
            class="text-sm w-full"
        />
    </div>
</template>