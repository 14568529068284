<script setup>
</script>

<template>
    <div>
    <h1>{{$t('terms.term2.p1')}}</h1>

    <h2>{{$t('terms.term2.p2')}}</h2>
    <p>{{$t('terms.term2.p3')}}</p>
    <ul>
        <li><strong>1:</strong> {{$t('terms.term2.p4')}}</li>
        <li><strong>X:</strong> {{$t('terms.term2.p5')}}</li>
        <li><strong>2:</strong> {{$t('terms.term2.p6')}}</li>
    </ul>

    <h2>{{$t('terms.term2.p7')}}</h2>
    <p>{{$t('terms.term2.p8')}}</p>

    <h2>{{$t('terms.term2.p9')}}</h2>
    <p>{{$t('terms.term2.p10')}}</p>
    <ul>
        <li><strong>1:</strong> {{$t('terms.term2.p11')}}</li>
        <li><strong>X:</strong> {{$t('terms.term2.p12')}}</li>
        <li><strong>2:</strong> {{$t('terms.term2.p13')}}</li>
    </ul>
    <p><strong>{{$t('terms.term2.p14')}}:</strong> {{$t('terms.term2.p15')}}</p>

    <h2>{{$t('terms.term2.p16')}}</h2>
    <p>{{$t('terms.term2.p17')}}</p>
    <ul>
        <li><strong>1:</strong> {{$t('terms.term2.p18')}}</li>
        <li><strong>X:</strong> {{$t('terms.term2.p19')}}</li>
        <li><strong>2:</strong> {{$t('terms.term2.p20')}}</li>
    </ul>
    <p><strong>{{$t('terms.term2.p14')}}:</strong> {{$t('terms.term2.p21')}}</p>

    <h2>{{$t('terms.term2.p22')}}</h2>
    <p>{{$t('terms.term2.p23')}}</p>
    <ul>
        <li><strong>1:</strong> {{$t('terms.term2.p24')}}</li>
        <li><strong>X:</strong> {{$t('terms.term2.p25')}}</li>
        <li><strong>2:</strong> {{$t('terms.term2.p26')}}</li>
    </ul>
    <p><strong>{{$t('terms.term2.p14')}}:</strong> {{$t('terms.term2.p27')}}</p>

    <h2>{{$t('terms.term2.p28')}}</h2>
    <p>{{$t('terms.term2.p29')}}</p>

    <h2>{{$t('terms.term2.p30')}}</h2>
    <p>{{$t('terms.term2.p31')}}</p>

    <h2>{{$t('terms.term2.p32')}}</h2>
    <p>{{$t('terms.term2.p33')}}</p>

    <h2>{{$t('terms.term2.p34')}}</h2>
    <p>{{$t('terms.term2.p35')}}</p>

    <h2>{{$t('terms.term2.p36')}}</h2>
    <p>{{$t('terms.term2.p37')}}</p>

    <h2>{{$t('terms.term2.p38')}}</h2>
    <p>{{$t('terms.term2.p39')}}</p>

    <h2>{{$t('terms.term2.p40')}}</h2>
    <p>{{$t('terms.term2.p41')}}</p>

    <h2>{{$t('terms.term2.p42')}}</h2>
    <p>{{$t('terms.term2.p43')}}</p>

    <h2>{{$t('terms.term2.p44')}}</h2>
    <p>{{$t('terms.term2.p45')}}</p>

    </div>
</template>
<style scoped>
   ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        margin-bottom: 10px;
        font-size: 12px;
    }
    p{
        padding: 1px 0px;
        font-size: 12px;
    }
    strong {
        color: white;
    }
    h2 , h3{
        color: white;
        padding: 2px 0px;
        font-size: 12px;
    }
    h1{
        color: white;
        padding: 4px 0px;
        font-size: 14px;
    }
</style>