import { defineStore } from "pinia";
import http from "@/composables/axios";
import { socket } from "@/feed.socket";
import { useResourcesStore } from "./resources";
import { storeToRefs } from 'pinia';
import * as Sentry from "@sentry/browser";
import _ from "lodash";

const preRefreshTime = 200000;

export const usePrematchStore = defineStore('prematch', {
    state: () => ({
        events: {},
        loaded: false,
        preRefreshTime: 200000,
        groupedEvents: new Map(), // Map to store grouped events by date
        availableDates: [], // Array of available dates (precomputed)
    }),

    getters: {
        sports: state => {
            const resources = useResourcesStore();
            const sports = resources.sports;

            return Object.keys(state.events)
                .map((sid) => ({
                    sid,
                    order: sports[sid]?.ord ?? Infinity,
                    name: sports[sid]?.nm || "Unknown"
                }))
                .sort((a, b) => a.order - b.order)
        },
        eventsLoaded: state => state.loaded,
        leaguesGroupedByLocation: state => sport => {
            const groups = {};
            for (const key in state.events[sport]) {
                const match = state.events[sport][key];
                const { loid, lid } = match;

                if (!groups[loid]) {
                    groups[loid] = {};
                }

                if (!groups[loid][lid]) {
                    groups[loid][lid] = { count: 0 };
                }

                groups[loid][lid].count += 1;
            }

            const resources = useResourcesStore();
            const locations = resources.locations;

            return Object.keys(groups)
                .map((loid) => ({
                    loid,
                    leagues: groups[loid],
                    order: locations[loid]?.ord ?? Infinity, // Use `Infinity` as fallback
                    name: locations[loid]?.nm || "Unknown" // Use "Unknown" as fallback
                }))
                .sort((a, b) => a.order - b.order); // Sort by `ord` field;
        },
        eventsByLeague: (state) => (sid, lid) => {
            return _.values(_.get(state.events, sid, {})).filter(event => event.lid == lid);
        },

        mainEvents: (state) => (sid) => {
            return _.values(_.get(state.events, sid, {})).filter(event => event.me);
        },

        upcomingEvents: state => sid => {
            return _.orderBy(_.values(_.get(state.events, sid, {})), ['tm'], ['asc']).slice(0, 10);
        },
        getEvent: state => eid => {
            for (let sid in state.events) {
                if (eid in state.events[sid]) {
                    return state.events[sid][eid]
                }
            }
            return null
        },
        search: state => (text) => {
            if (!text) return
            return Object.values(state.events[6046])
                .filter(e => e.ht.toLowerCase().includes(text.toLowerCase()) || e.at.toLowerCase().includes(text.toLowerCase()))
        },
        eventsByDate: state => date => {
            let result = {};
            for (const eventId in state.groupedEvents.get(date)) {
                const event = state.groupedEvents.get(date)[eventId];
                const { lid, loid } = event;

                if (!result[lid]) {
                    result[lid] = [];
                }

                result[lid].push(event);
            }

            const resources = useResourcesStore();
            const leagues = resources.leagues;

            return Object.keys(result)
                .map((lid) => ({
                    lid,
                    locid: leagues[lid]?.locid,
                    order: leagues[lid]?.ord ?? Infinity,
                    name: leagues[lid]?.nm || "Unknown",
                    events: result[lid]
                }))
                .sort((a, b) => a.order - b.order);
        },
        getAvailableDates: state => state.availableDates,
    },

    actions: {
        async fetchEvents (query = {}) {
            let localData = window.localStorage.getItem('prematches') && window.localStorage.getItem('prematches') != 'undefined'
                ? true
                : false;
            let setTime = window.localStorage.getItem('preSetTime') && window.localStorage.getItem('preSetTime') != 'undefined'
                ? window.localStorage.getItem('preSetTime')
                : new Date(new Date().getTime() - this.preRefreshTime).getTime();
            let calculateTime = new Date().getTime() - setTime;

            if (calculateTime > this.preRefreshTime || !localData) {
                query = new URLSearchParams(query).toString()
                await http.get('02/pre/events?' + query).then(res => {
                    if (res.data.ok) {
                        window.localStorage.setItem('preSetTime', new Date().getTime().toString());
                        window.localStorage.setItem('prematches', JSON.stringify(res.data.data));
                    }
                }).catch(error => {
                    Sentry.captureMessage(error.message, 'error')
                    window.localStorage.removeItem('prematches');
                    window.localStorage.removeItem('preSetTime');
                })
            }
            this.joinEvents(
                JSON.parse(window.localStorage.getItem('prematches'))
            )
            this.loaded = true
        },

        async fetchEvent(eid) {
            return await http.get(`02/pre/events/${eid}`).then(res => {
                if (res.data.ok) {
                    return res.data.data
                }
            })
        },

        async fetchOdds(eid, query = {}) {
            query = new URLSearchParams(query).toString()
            return await http.get(`02/pre/events/${eid}/details?` + query).then(res => {
                if (res.data.ok) {
                    return res.data.data
                }
            })
        },

        async fetchSelections(eids, sid) {
            await http.get(`02/pre/events/selections?e=${eids}`).then(res => {
                if (res.data.ok) {
                    var _temp = res.data.data
                    for (let odd of _temp) {
                        if (!('m' in this.events[sid][odd.eid])) {
                            this.events[sid][odd.eid]['m'] = {[odd.fid]: []}
                        }

                        let indexOfOdd = this.events[sid][odd.eid]['m'][odd.fid].findIndex(o => o.uid = odd.uid)
                        if (indexOfOdd > -1) {
                            this.events[sid][odd.eid]['m'][odd.fid][indexOfOdd] = odd
                        } else {
                            this.events[sid][odd.eid]['m'][odd.fid].push(odd)
                        }
                    }
                }
            })
        },

        async fetchMainLeagues(query) {

            const resources = useResourcesStore()
            const { mainLeagues } = storeToRefs(resources)
            const leagues = mainLeagues.value.map(({fid}) => fid).join(',')

            query.lid = leagues
            query = new URLSearchParams(query).toString()

            await http.get(`02/ml/events?${query}`).then(res => {
                if (res.data.ok) {
                    this.joinEvents(res.data.data)
                }
            })
        },

        removeEvent(eid) {
            for (let sport in this.events) {
                if (eid in this.events[sport]) {
                    delete this.events[sport][eid]
                    return;
                }
            }
        },

        joinEvents(data) {
            for (let sid in data) {
                if (!(sid in this.events)) {
                    this.events[sid] = {}
                }

                this.events[sid] = {...this.events[sid], ...data[sid]}
            }
        },
        findSport(fid) {
            const resources = useResourcesStore();
            const sports = resources.sports;
            return sports[fid]
        },
        bindSocketEvents(user) {
            socket.on('odds:pre', (odds) => {
                for (let odd of odds) {
                    if (user.mrg) {
                        let m = odd.p / (user.mrg / 100 + 1)
                        odd.p = m > 1 ? m : 1
                    }
                    if (user.p) {
                        let p = odd.p - (user.p / 100)
                        odd.p = p > 1 ? p : 1
                    }

                    const eventId = odd.eid
                    for (let sport in this.events) {
                        if (eventId in this.events[sport]) {
                            if (!('m' in this.events[sport][eventId])) {
                                this.events[sport][eventId].m = {}
                            }

                            if (!(odd.fid in this.events[sport][eventId].m)) {
                                this.events[sport][eventId].m[odd.fid] = []
                            }

                            let indexOfOdd = this.events[sport][eventId].m[odd.fid].findIndex(o => o.uid == odd.uid)


                            if(this.findSport(sport)?.m){
                                const newOdd = odd.p / (this.findSport(sport)?.m / 100 + 1)
                                odd.p = newOdd > 1 ? newOdd : 1
                            }

                            if (indexOfOdd > -1) {
                                this.events[sport][eventId].m[odd.fid][indexOfOdd] = odd
                            } else {
                                this.events[sport][eventId].m[odd.fid].push(odd)
                            }
                        }
                    }
                }
            })
        },

        groupEventsByDate(sport) {
            const grouped = new Map();
            for (const key in this.events[sport]) {
                const event = this.events[sport][key];
                const date = new Date(event.tm).toISOString().split('T')[0]; // Format as YYYY-MM-DD
                if (!grouped.has(date)) {
                    grouped.set(date, []);
                }
                grouped.get(date).push(event);
            }
            this.groupedEvents = grouped;
            this.availableDates = Array.from(grouped.keys()).sort();
        },
    }
})
