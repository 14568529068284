<script setup>
import DefaultButton from '@/components/Buttons/DefaultButton.vue';
import TextField from '@/components/Form/TextField.vue';
import InputLabel from '@/components/InputLabel.vue';
import { useUsersStore } from '@/stores/users';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const { t } = useI18n()
const users = useUsersStore()
const router = useRouter()

const form = ref({
    username: '',
    first_name: '',
    last_name: '',
    password: ''
})
const message = ref('')

const save = async () => {
    await users.createUser(form.value).then(res => {
        if (res.data.ok) {
            router.push('/users')
        }
    }).catch(error => {
        message.value = error.response.data.message
    })
}
</script>

<template>
    <div class="CreateUser">
        <div>
            <h1 class="text-base font-bold">{{$t('app.2')}}</h1>
            <p class="text-xs font-semibold text-neutral-500">{{$t('app.3')}}</p>
        </div>

        <div class="py-2 px-4 bg-red-200/25 text-red-600 text-xs font-semibold mt-2" v-if="message">
            {{ t('createUser.' + message) }}
        </div>

        <form @submit.prevent="save" class="mt-6 space-y-4">
            <div>
                <InputLabel :value="$t('app.24')" />
                <TextField v-model="form.username" class="w-full mt-1" :placeholder="$t('app.24')" />
            </div>
            <div>
                <InputLabel :value="$t('partials.21')" />
                <TextField v-model="form.first_name" class="w-full mt-1" :placeholder="$t('app.25')" />
            </div>
            <div>
                <InputLabel :value="$t('partials.22')" />
                <TextField v-model="form.last_name" class="w-full mt-1" :placeholder="$t('app.26')" />
            </div>
            <div>
                <InputLabel :value="$t('app.27')" />
                <TextField type="password" v-model="form.password" class="w-full mt-1" placeholder="******" />
            </div>

            <div>
                <DefaultButton>{{$t('app.4')}}</DefaultButton>
            </div>
        </form>
    </div>
</template>

<style scoped>
.CreateUser {
    background-color: #ebeded;
    @apply px-6 py-4
}
</style>