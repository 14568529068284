<script setup>
import { toFixedTwo } from '@/helpers';
import { useAuthStore } from '@/stores/auth';
import { useUsersStore } from '@/stores/users';
import { storeToRefs } from 'pinia';
import { onMounted, ref, watch } from 'vue';

const props = defineProps({
    startDate: '',
    endDate: ''
})

const sportFinances = ref({})
const sportFinancesTotal = ref({})

const users = useUsersStore()
const auth = useAuthStore()
const { getCurrency, getUser } = storeToRefs(auth)

const fetchFinance = async () => {
    await users.fetchFinance({sd: props.startDate, ed: props.endDate}).then(res => {
        sportFinances.value = res.data.data
        sportFinancesTotal.value = res.data.total
    })
}

watch(() => [props.startDate, props.endDate], async () => {
    await fetchFinance()
})

onMounted(async () => {
    await fetchFinance()
})
</script>

<template>
    <thead class="">
        <tr>
            <th class="left">{{$t('partials.1')}}</th>
            <th>{{$t('partials.2')}}</th>
            <th>{{$t('partials.3')}}</th>
            <th v-if="getUser.rl != 'clt'&& getUser.acc_w_comm ">{{$t('partials.8')}}</th>
            <th>{{$t('partials.4')}}</th>
        </tr>
    </thead>

    <tbody>
        <tr v-for="info in sportFinances">
            <td class="left">{{ info._id.username ?? info._id.date }}</td>
            <td>{{ toFixedTwo(info.turnover) }}</td>
            <td>{{ toFixedTwo(info.win) }}</td>
            <td v-if="getUser.rl != 'clt' && getUser.acc_w_comm">{{ toFixedTwo(info.percentage) }}</td>
            <td>{{ toFixedTwo(info.diff) }} {{ getCurrency(info._id.curr).cod }}</td>
        </tr>
    </tbody>

    <tfoot>
        <tr v-for="total in sportFinancesTotal">
            <th class="left">{{$t('partials.5')}}</th>
            <th>{{ toFixedTwo(total.totalTurnover) }}</th>
            <th>{{ toFixedTwo(total.totalWin) }}</th>
            <th v-if="getUser.rl != 'clt' && getUser.acc_w_comm">{{ toFixedTwo(total.totalPercentage) }}</th>
            <th>{{ toFixedTwo(total.totalDiff) }} {{ getCurrency(total._id).cod }}</th>
        </tr>
    </tfoot>
</template>