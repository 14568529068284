<script setup>
import MatchGroupHeader from '@/components/Match/MatchGroupHeader.vue';
import MatchListItem from '@/components/Match/MatchListItem.vue';
import { getDayNumber, getDayShortName } from '@/helpers';
import { usePrematchStore } from '@/stores/prematch';
import { useResourcesStore } from '@/stores/resources';
import { storeToRefs } from 'pinia';
import { onMounted, ref, watch } from 'vue';

const prematch = usePrematchStore()
const resources = useResourcesStore()
const { sports, eventsByDate, getAvailableDates } = storeToRefs(prematch)
const { getLeague, getLocation } = storeToRefs(resources)

const sport = ref()
const date = ref()

const setDate = (d) => {
    date.value = d
}

watch(sport, (value) => {
    prematch.groupEventsByDate(sport.value)
    date.value = getAvailableDates.value[0]
})

onMounted(() => {
    sport.value = sports.value[0].sid
})
</script>

<template>
    <div>
        <div>
            <select
                class="appearance-none bg-transparent text-[13px] text-neutral-300 border-0 focus:ring-0"
                v-model="sport"
            >
                <option
                    v-for="sport in sports"
                    :value="sport.sid"
                >{{ sport.name }}</option>
            </select>
        </div>
        <div class="px-2 py-1.5">
            <ul class="list-none flex space-x-1 overflow-x-auto">
                <li v-for="d in getAvailableDates">
                    <button
                        class="w-14 text-center py-1 rounded-sm"
                        :class="[d == date ? 'bg-[var(--color-primary3)]' : 'bg-neutral-500']"
                        @click="setDate(d)"
                    >
                        <p class="text-black text-sm font-bold">{{ getDayShortName(d) }}</p>
                        <p class="text-xs">{{ getDayNumber(d) }}</p>
                    </button>
                </li>
            </ul>
        </div>
        <div v-for="league in eventsByDate(date)">
            <div v-lazy>
                <MatchGroupHeader :sport="sport">
                    <template #title>
                        <div class="flex items-center">
                            <img :src="`/loc/${league.locid}.svg`" class="w-4 h-4 rounded-full mr-2" alt="">
                            {{ getLocation(league.locid)?.nm }} - {{ league.name}}
                        </div>
                    </template>
                </MatchGroupHeader>
                <ul class="list-none divide-y divide-neutral-700/75">
                    <li v-for="event in league.events">
                        <MatchListItem
                            :event="event"
                            v-lazy
                        />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
