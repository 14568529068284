<script setup>
import { onMounted, ref } from 'vue';
import DefaultButton from '@/components/Buttons/DefaultButton.vue';
import TextField from '@/components/Form/TextField.vue';
import InputLabel from '@/components/InputLabel.vue';
import SelectField from '@/components/Form/SelectField.vue';
import { timezones } from '@/helpers';
import RadioField from '@/components/Form/RadioField.vue';

const props = defineProps({
    title: String,
    data: Object,
    auth: {
        type: Boolean,
        default: false,
    }
})

const emit = defineEmits(['submitted'])

const form = ref({})
const setInitialFormData = () => {
    form.value.first_name = props.data.fn
    form.value.last_name = props.data.ln
    form.value.username = props.data.un
    form.value.timezone = props.data.tmz
    form.value.active = props.data.ac
}

onMounted(() => {
    setInitialFormData()
})
</script>

<template>
    <div class="udet-Container">
        <div>
            <RouterLink class="text-xs text-neutral-600 leading-none" to="/users" v-if="!auth">&larr; {{$t('partials.12')}}</RouterLink>
            <h1 class="text-base font-bold">{{ title }}</h1>
            <p class="text-xs font-semibold text-neutral-500">{{$t('partials.13')}}</p>
        </div>

        <form @submit.prevent="$emit('submitted', form)" class="mt-6 space-y-4">
            <div>
                <InputLabel :value="$t('partials.20')" />
                <TextField
                    v-model="form.username"
                    :placeholder="$t('partials.20')"
                    class="w-full mt-1"
                    readonly
                />
            </div>
            <div>
                <InputLabel :value="$t('partials.21')" />
                <TextField
                    v-model="form.first_name"
                    :placeholder="$t('partials.21')"
                    class="w-full mt-1"
                />
            </div>
            <div>
                <InputLabel :value="$t('partials.22')" />
                <TextField
                    v-model="form.last_name"
                    :placeholder="$t('partials.22')"
                    class="w-full mt-1"
                />
            </div>
            <div>
                <InputLabel :value="$t('partials.23')" />
                <SelectField
                    v-model="form.timezone"
                    :placeholder="$t('partials.23')"
                    class="w-full mt-1"
                    :options="timezones()"
                />
            </div>

            <div v-if="!auth">
                <InputLabel :value="$t('partials.24')" />
                <RadioField 
                    v-model="form.active"
                    :options="{1: 'Yes', 0: 'No'}"
                    name="user_ac"
                    class="mt-1"
                />
            </div>

            <div>
                <DefaultButton>{{$t('partials.14')}}</DefaultButton>
            </div>
        </form>
    </div>
</template>
<style>
.udet-Container {
    background-color: #ebeded;
    @apply px-6 py-4
}
</style>