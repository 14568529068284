<script setup>
import { useLiveStore } from '@/stores/live';
import { useResourcesStore } from '@/stores/resources';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { timestampToTime } from '@/helpers';
import { useRouter, useRoute } from 'vue-router';
const props = defineProps({
    event: Object,
})
const router = useRouter()
const route = useRoute()
const live = useLiveStore()
const { getEvents } = storeToRefs(live)
const resources = useResourcesStore()
const { getSport, getLeague } = storeToRefs(resources)

const sid = computed(() => route.params.sid)
const closeModal = () =>{
    router.back();
}
const selectEvent = (event) => {
   const url = `/in-play/${event.fid}`
   router.push(url)
}
</script>

<template>
    <div class="fixed w-full h-full top-0  gradient-bg">
        <div class="flex px-4 w-full justify-between text-white items-center h-[50px] border-b border-gray-600/50">
            <p></p>
            <p class="text-center font-bold">{{getSport(sid)?.nm}}</p>
            <div class="flex items-center cursor-pointer" @click="closeModal">
                <img src="/close.svg" class="w-4 h-4"/>
            </div>
        </div>

        <div class="flex-1  overflow-y-auto container">
            <div class="text-white">
                <div v-for="league in getEvents(sid)" class="border-b border-gray-600/50">
                    <p class="league-name text-center justify-center font-bold py-4"> {{getLeague(league.lid)?.nm}} </p>
                    <ul class="list-none divide-y divide-neutral-500/25">
                        <li v-for="event in league.events">
                            <div @click="selectEvent(event)" class="grid grid-cols-5 items-end gap-2 justify-center p-4 hover:bg-[var(--color-white15)] cursor-pointer">
                                <span class="font-bold w-full col-span-2 ht-name">{{ event?.ht }} </span>
                                <div class="flex flex-col items-center w-full col-span-1">
                                    <p class="text-center text-sm"> {{ timestampToTime(event.tm) }}</p>
                                    <p class="text-yellow-500 font-bold text-lg flex items-center gap-2"><span>{{event.inf?.res?.split('-')[0] }} </span> <span>{{ event.inf?.res?.split('-')[1]}}</span></p>   
                                </div>
                                <span class="font-bold w-full col-span-2 ht-name"> {{ event?.at }} </span>   
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.league-name{
    color: var(--hm-selected);
}
.gradient-bg {
  background-image: linear-gradient(160deg,#364D3C 0%, var(--color-grey3) 400px)
}
.ht-name{
    display: -webkit-box;
    -webkit-line-clamp: 1; 
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal; 
    word-break: break-word; 
    text-align: center;
}
.container {
  height: calc(100% - 50px);
}
</style>