import { createRouter, createWebHistory } from 'vue-router'
import AppLayout from '@/layouts/AppLayout.vue'
import HomeView from '../views/(app)/HomeView.vue'
import LoginView from '../views/(guest)/LoginView.vue'
import { useAuthStore } from '@/stores/auth'
import { storeToRefs } from 'pinia'
import InPlayView from '@/views/(app)/InPlayView.vue'
import CasinoView from '@/views/(app)/CasinoView.vue'
import PrematchSportView from '@/views/(app)/PrematchSportView.vue'
import LeagueView from '@/views/(app)/LeagueView.vue'
import MyBetsView from '@/views/(app)/MyBetsView.vue'
import LiveView from '@/views/(app)/LiveView.vue'
import PrematchView from '@/views/(app)/PrematchView.vue'
import MyAccountDetailsView from '@/views/(app)/MyAccountDetailsView.vue'
import UsersView from '@/views/(app)/UsersView.vue'
import ShowUserView from '@/views/(app)/ShowUserView.vue'
import CreateUserView from '@/views/(app)/CreateUserView.vue'
import MyAccountPasswordView from '@/views/(app)/MyAccountPasswordView.vue'
import FinanceView from '@/views/(app)/FinanceView.vue'
import GameOpenView from '@/views/(app)/GameOpenView.vue'
import ChatView from '@/views/(app)/ChatView.vue'
import SearchView from '@/views/(app)/SearchView.vue'
import TransactionsView from '@/views/(app)/TransactionsView.vue'
import CasinoProviderGamesView from '@/views/(app)/CasinoProviderGamesView.vue'
import EventsByDateView from '@/views/(app)/EventsByDateView.vue'
import MySettingsView from '@/views/(app)/MySettingsView.vue'
import LiveResultsView from '@/views/(app)/LiveResultsView.vue'
import TermsView from '@/views/(app)/TermsView.vue'
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: AppLayout,
      children: [
        {path: '/', name: 'home', component: HomeView},
        {path: '/in-play', name: 'inplay', component: InPlayView},
        {path: '/my-bets', name: 'mybets', component: MyBetsView},
        {path: '/casino', name: 'casino', component: CasinoView},
        {path: '/casino/provider/:id', name: 'casino.provider', component: CasinoProviderGamesView},
        {path: '/casino/game', name: 'casino.game', component: GameOpenView},
        {path: '/ps/:id', name: 'prematch.sport', component: PrematchSportView},
        {path: '/ps/:sid/l/:loid/:lid', name: 'prematch.league', component: LeagueView},
        {path: '/in-play/:id', name: 'inplay.event', component: LiveView},
        {path: '/in-play/events/:sid', name: 'inplay.events', component: LiveResultsView},
        {path: '/pre/:id', name: 'prematch.event', component: PrematchView},
        {path: '/my-account', name: 'my.account', component: MyAccountDetailsView},
        {path: '/change-password', name: 'my.account.password', component: MyAccountPasswordView},
        {path: '/users', name: 'users', component: UsersView, meta: {clt: false}},
        {path: '/users/:id', name: 'users.show', component: ShowUserView, meta: {clt: false}},
        {path: '/create-user', name: 'users.create', component: CreateUserView, meta: {clt: false}},
        {path: '/finance', name: 'finance', component: FinanceView},
        {path: '/chat', name: 'chat', component: ChatView, meta:{acc: true}},
        {path: '/search', name: 'search', component: SearchView},
        {path: '/transactions/:id', name: 'transactions', component: TransactionsView},
        {path: '/ebd', name: 'ebd', component: EventsByDateView},
        {path: '/settings', name: 'my.account.settings', component: MySettingsView},
        {path: '/terms', name: 'my.account.terms', component: TermsView},
      ],
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView,
      meta: {
        guest: true
      }
    }
  ],
  // scrollBehavior() {
  //   return { x: 0, y: 0 };
  // }
  scrollBehavior() {
    return new Promise((resolve) => {
      requestAnimationFrame(() => {
        const container = document.querySelector(".pageView");
        if (container) {
          container.scrollTop = 0; 
        }
        resolve();
      });
    });
  }  
})

router.beforeEach(async (to, from, next) => {
    const authStore = useAuthStore()
    const { isAuth, isUserLoaded, getUser } = storeToRefs(authStore)

    if (!isUserLoaded.value) {
        await authStore.fetchUser()
    }

    if (to.meta.requiresAuth && !isAuth.value) {
        next({ name: 'login' });
    } else if (to.meta.guest && isAuth.value) {
        next({ name: 'home' })
    } else {
        if (to.meta.acc && !getUser.value.acc) {
          next({ name: from.name });
        } 
        else if ('clt' in to.meta && !to.meta.clt && getUser.value.rl == 'clt') {
          next({name: from.name})
        } else {
          next();
        }
    }
})

export default router
