<script setup>
import Odd from '@/components/Odd.vue';
import { createHash, orderByGivenArray, timestampToDayMonth, timestampToTime } from '@/helpers';
import { useResourcesStore } from '@/stores/resources';
import { storeToRefs } from 'pinia';
import { computed, onMounted } from 'vue';
const props = defineProps({
    event: Object
})

const resources = useResourcesStore()
const { getLeague, getTeam } = storeToRefs(resources)

const [dayName, dayOfMonth, monthName] = timestampToDayMonth(props.event.tm)
const time = timestampToTime(props.event.tm)
const hasMarket = computed(() => 'm' in props.event && '1' in props.event.m)

const homeTeam = computed(() => getTeam.value(props.event.htid))
const awayTeam = computed(() => getTeam.value(props.event.atid))
const hasHomeTeamKit = computed(() => homeTeam.value && getTeam.value(props.event.htid).hkit)
const hasAwayTeamKit = computed(() => awayTeam.value && getTeam.value(props.event.atid).akit)
</script>

<template>
    <div class="w-64">
        <div class="bg-[var(--color-white7)] rounded overflow-hidden">
            <RouterLink :to="`/ps/${event.sid}/l/${event.loid}/${event.lid}`" class="block border-b border-neutral-600 px-3 py-2">
                <p class="text-xs text-yellow-500 font-bold">
                    {{ getLeague(event.lid)?.nm }}
                </p>
            </RouterLink>
            <RouterLink :to="`/pre/${event.fid}`" class="block px-3 py-2">
                <div class="mev-Participant">
                    <div v-if="hasHomeTeamKit" v-html="getTeam(event.htid).hkit" class="mev-ParticipantSize" />
                    <div v-else class="mev-ParticipantKit mev-ParticipantSize"></div>
                    <p>{{ homeTeam?.nm ?? event.ht }}</p>
                </div>
                <div class="mev-Participant">
                    <div v-if="hasAwayTeamKit" v-html="getTeam(event.atid).akit" class="mev-ParticipantSize" />
                    <div v-else class="mev-ParticipantKit mev-ParticipantSize"></div>
                    <p>{{ awayTeam?.nm ?? event.at }}</p>
                </div>
                <p class="mev-Date">{{ dayName }} {{ dayOfMonth }} {{ monthName}} - {{ time }}</p>
            </RouterLink>
            <div class="grid grid-cols-3 border-t border-neutral-600">
                <template v-if="hasMarket">
                    <Odd
                        :odd="event.m[1].find(s => s.nm == '1')"
                        class="py-2.5"
                        odd-classes="text-[var(--color-primary5)] text-lg font-bold"
                    />
                    <Odd
                        :odd="event.m[1].find(s => s.nm == 'X')"
                        class="py-2.5"
                        odd-classes="text-[var(--color-primary5)] text-lg font-bold"
                    />
                    <Odd
                        :odd="event.m[1].find(s => s.nm == '2')"
                        class="py-2.5"
                        odd-classes="text-[var(--color-primary5)] text-lg font-bold"
                    />
                </template>
            </div>
        </div>
    </div>
</template>

<style scoped>
.mev-Participant {
    @apply flex items-center text-sm text-white font-bold;
}

.mev-Date {
    @apply text-xs text-neutral-400 mt-1;
}

.mev-ParticipantKit {
    mask-image: url('/white-kit.svg');
    background-color: #fff;
    background-repeat: no-repeat;
}

.mev-ParticipantSize {
    width: 15px;
    height: 16px;
    margin-right: 11px;
}
</style>
