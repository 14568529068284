<script setup>
import TextField from '@/components/Form/TextField.vue';
import Spinner from '@/components/Spinner.vue';
import { debounce } from '@/helpers';
import { useAuthStore } from '@/stores/auth';
import { useGamesStore } from '@/stores/games';
import { useResponseMessageStore } from '@/stores/response_message';
import { storeToRefs } from 'pinia';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const { t } = useI18n()
const router = useRouter()
const responseMessageStore = useResponseMessageStore()
const auth = useAuthStore()
const games = useGamesStore()
const { getCategories, getSubcategories, getGames, getGame, isUrl, getMeta, getProvidersByCategory } = storeToRefs(games)
const { getCredits, getCurrency, getUser } = storeToRefs(auth)

const showSpinner = ref(false)
const opening = ref(false)
const search = ref('')
const searchResult = ref([])
const selectedCategory = ref({})
const selectedSubcategory = ref({})

const mainGames = ref([])

const openGame = async (id) => {
    opening.value = true
    await games.openGame(id, {
        device: 'M',
        currency_id: getCredits.value[0].cuid._id,
        currency_name: getCurrency.value(getCredits.value[0].cuid._id).cod,
        homeURL: import.meta.env.VITE_APP_URL
    }).then(res => {
        opening.value = false
    }).catch(error => {
        opening.value = false
        responseMessageStore.setMessage(t(error.response.data.message))
    })
}

const startSearching = debounce(async () => {
    showSpinner.value = true
    await games.searchGames(search.value).then(res => {
        if (res.data.ok) {
            searchResult.value = res.data.data
            showSpinner.value = false
        }
    })
}, 500)


const setFirstCategory = () => {
    if (!getCategories.value || !getCategories.value.length) {
        return;
    }
    selectedCategory.value = getCategories.value[0]
}

watch(selectedCategory, (value) => {
    selectedSubcategory.value = getSubcategories.value(value._id)[0]
})

watch(getGame, (value) => {
    if (isUrl.value) {
        window.open(value, '_blank')
    } else {
        router.push('/casino/game')
    }
})

watch(search, (value) => {
    startSearching()
})

watch(getCategories, (value) => {
    setFirstCategory()
})

onMounted(async () => {
    setFirstCategory()
})
</script>

<template>
    <div>
        <div class="SearchInput">
            <div class="SearchInput_Icon" />
            <input
                class="SearchInput_Field"
                placeholder="Search..."
                type="search"
                v-model="search"
            />
        </div>

        <div v-show="!search">
            <div class="py-2 px-3 flex justify-center space-x-4">
                <template v-for="cat in getCategories">
                    <div
                        :class="['cursor-pointer flex flex-col items-center', cat._id == selectedCategory._id ? 'ActiveFilter' : 'text-[#dddddd]']"
                        @click="selectedCategory = cat"
                    >
                        <div class="cat-icon icon-home w-full h-[28px]" v-if="cat.nm == 'Home'"></div>
                        <div class="cat-icon icon-lc w-full h-[28px]" v-if="cat.nm == 'Live Casino'"></div>
                        <div class="cat-icon icon-slots w-full h-[28px]" v-if="cat.nm == 'Slots'"></div>
                     
                        <p
                            class="text-[11px]"
                        >{{ cat.nm }}</p>
                    </div>
                </template>
            </div>

            <div v-if="selectedCategory._id">
                <div class="px-3 flex items-center overflow-x-auto space-x-4">
                    <div
                        v-for="sub in getSubcategories(selectedCategory._id)"
                        class="py-2 border-b-2 border-transparent cursor-pointer"
                        :class="{'!border-[var(--color-primary4)] ': selectedSubcategory._id == sub._id}"
                        @click="selectedSubcategory = sub"
                    >
                        <p
                            class="text-[11px] font-bold"
                            :class="[selectedSubcategory._id == sub._id ? 'text-[var(--color-primary4)]' : 'text-neutral-400']"
                        >{{ sub.nm }}</p>
                    </div>
                </div>

                <div class="px-3 grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 gap-4 mt-6 space-x-1">
                    <template v-for="game in getGames(selectedCategory._id, selectedSubcategory._id)">
                        <div class="cursor-pointer" @click="openGame(game._id)">
                            <img :src="game.img ?? game.extimg" class="w-full h-full rounded-xl" alt="">
                            <!-- <p class="text-xs text-neutral-300 opacity-90 text-center">{{ game.nm }}</p> -->
                        </div>
                    </template>
                </div>

                <div class="mt-4 flex justify-center" v-if="getMeta.hasNextPage">
                    <button class="text-sm text-white font-medium shadow-md bg-[var(--color-primary1)] rounded-md py-2 px-4" @click="moreGames">More</button>
                </div>
            </div>

            <div class="px-4" v-else>
                <ul class="list-none space-y-6">
                    <li v-for="cat in getCategories.filter(c => c._id)">
                        <div>
                            <h3 @click="selectedCategory = cat" class="text-lg font-bold text-white/75 cursor-pointer">{{ cat.nm }}</h3>

                            <ul v-for="sub in getSubcategories(cat._id).filter(s => s.main)" class="flex overflow-x-auto space-x-4 mt-3">
                                <li v-for="game in getGames(cat._id, sub._id)" class="flex-1">
                                    <div class="w-[140px]" @click="openGame(game._id)">
                                        <img :src="game.img ?? game.extimg" class="w-full h-full rounded-xl" alt="">
                                        <!-- <p class="text-xs text-neutral-300/90 text-center">{{ game.nm }}</p> -->
                                    </div>
                                </li>
                            </ul>

                            <ul class="flex overflow-x-auto space-x-2 mt-2.5">
                                <li v-for="provider in getProvidersByCategory(cat._id).sort((a, b) => a.ord - b.ord)" class="flex-1 px-2 bg-neutral-600/25 rounded-lg">
                                    <RouterLink :to="`/casino/provider/${provider._id}`" class="block w-[94px] ">
                                        <img :src="provider.img" class="h-[56px] w-full" :alt="provider.nm">
                                    </RouterLink>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div v-show="search" class="px-3 grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 gap-4 mt-6 space-x-1">
            <template v-for="game in searchResult">
                <div class="cursor-pointer" @click="openGame(game._id)">
                    <img :src="game.img ?? game.extimg" class="w-full h-full rounded-xl" alt="">
                </div>
            </template>
        </div>

        <Spinner :show="opening || showSpinner" />
    </div>
</template>

<style scoped>
.ActiveFilter {
    /* filter: invert(37%) sepia(60.5%) saturate(460%) hue-rotate(108.5deg) brightness(125%) contrast(115%); */
    color:var(--color-primary4);
    .cat-icon{
        background-color:var(--color-primary4);
    }
}
.cat-icon{
    mask-position: center;
    /* mask-size: contain; */
    mask-repeat: no-repeat;
    background-color: #BBBBBB; 
}
.icon-home {
  mask-image: url("/home.svg");

}
.icon-lc {
  mask-image: url("/livecasino.svg");

}
.icon-slots{
  mask-image: url("/slots.svg");
}
.SearchInput {
    background-color: hsla(0,0%,100%,.1);
    border-radius: 50px;
    display: flex;
    @apply mx-3 mt-2 px-3;
}

.SearchInput_Icon {
    mask-image: url("/search.svg");
    mask-size: 18px 18px;
    mask-repeat: no-repeat;
    mask-position: center;
    min-width: 18px;
    background-color: #fff;
    z-index: 20;
    display: flex;
    flex-direction: column
}

.SearchInput_Field {
    @apply flex-1 text-sm !bg-transparent placeholder:text-white font-bold text-white py-2.5 border-0;
    @apply focus:ring-0;
}
</style>
