import http from "@/composables/axios";
import { defineStore } from "pinia";
import { useResponseMessageStore } from "./response_message";

export const useBetsStore = defineStore('bets', {
    state: () => ({
        tickets: [],
        users: []
    }),

    getters: {
        getTickets: state => state.tickets,
        getUsers: state => state.users
    },

    actions: {
        async fetchTickets(query = {}) {
            query = new URLSearchParams(query).toString()
            await http.get(`/01/tickets?${query}`).then(res => {
                if (res.data.ok) {
                    this.tickets = res.data.data.groups
                    this.users = res.data.data.users
                }
            })
        },

        async fetchBetslips(id) {
            return await http.get(`01/tickets/${id}/slips`)
        },


        async fetchTimeline(eid) {
            return await http.get(`02/events/${eid}/timeline`)
        },

        async cancelTicket(id) {
            const responseMessageStore = useResponseMessageStore()
            await http.put(`01/tickets/${id}/cancel`).then(res => {
                if (res.status == 204) {
                    for (let group in this.tickets) {
                        let index = this.tickets[group].tickets.findIndex(t => t._id == id)
                        if (index > -1) {
                            this.tickets[group].tickets[index].st = 7
                        }
                    }
                }
            }).catch(error => {
                responseMessageStore.setMessage(error.response.data.message)
            })
            
        }
    }
})
