<script setup>
import DefaultButton from '@/components/Buttons/DefaultButton.vue';
import TextField from '@/components/Form/TextField.vue';
import { modelDateValue, timestampToDayMonth, timestampToDayMonthString, timestampToTime } from '@/helpers';
import { useAuthStore } from '@/stores/auth';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

const auth = useAuthStore()
const { getUser } = storeToRefs(auth)

const filters = ref({
    sd: modelDateValue(),
    ed: modelDateValue()
})
const transactions = ref([])
const route = useRoute()

let date = (time) => timestampToDayMonth(time)

const search = async () => {
    await auth.fetchTransactions({
        currency: route.params.id,
        ...filters.value
    }).then(res => {
        if (res.data.ok) {
            transactions.value = res.data.data
        }
    })
}

watch(() => route.params.id, async (value) => {
    await search()
})

onMounted(async () => {
    await search()
})
</script>

<template>
    <div>
        <form @submit.prevent="search">
            <div class="px-4 py-2">
                <TextField type="date" v-model="filters.sd" placeholder="Start Date" class="w-full rounded-b-none" />
                <TextField type="date" v-model="filters.ed" placeholder="End Date" class="w-full border-t border-neutral-200 rounded-t-none" />
                <div class="mt-2">
                    <DefaultButton class="w-full">{{$t('app.8')}}</DefaultButton>
                </div>
            </div>
        </form>

        <div class="mt-4 px-4 space-y-4">
            <div class="trx-Container">
                <table class="trx-Table w-full">
                    <thead>
                        <tr>
                            <th class="left">{{$t('app.15')}}</th>
                            <th>{{$t('app.16')}}</th>
                            <th>{{$t('app.17')}}</th>
                            <th>{{$t('app.18')}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr 
                            v-for="trx in transactions"
                            :class="trx.receiver._id == getUser._id ? 'receive' : 'send'"
                        >
                            <td class="left">{{ timestampToDayMonthString(trx.createdAt) }} {{ timestampToTime(trx.createdAt) }}</td>
                            <td>{{ trx.sender.un }}</td>
                            <td>{{ trx.receiver.un }}</td>
                            <td>{{ trx.am }} {{ trx.currency.cod }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<style scoped>
.trx-Container {
    background-image: var(--mgc-bg);
    @apply rounded-md overflow-hidden;
}

.trx-Table {
    @apply w-full divide-y divide-neutral-500/30;
    font-size: 2.5vw;
    line-height: 7vw;
    text-align: right;
}

.trx-Table th, 
.trx-Table td {
    @apply px-2
}

.trx-Table th {
    @apply font-normal text-neutral-400;
}

.trx-Table tr.receive {
    @apply text-emerald-400;
}
.trx-Table tr.send {
    @apply text-red-400;
}

.trx-Table .left {
    @apply text-left;
}
</style>