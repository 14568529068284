<script setup>
</script>

<template>
 <div>
    <h1>{{$t('terms.term5.title')}}</h1>
    <h2>{{$t('terms.term5.p1')}}</h2>
    
    <ul>
        <li><strong>1-{{$t('terms.term4.p9')}}:</strong> {{$t('terms.term4.p10')}}</li>
        <li><strong>2-{{$t('terms.term4.p11')}}:</strong> {{$t('terms.term4.p12')}}</li>
        <li><strong>3-{{$t('terms.term4.p13')}}:</strong> {{$t('terms.term4.p14')}}</li>
        <li><strong>4-{{$t('terms.term4.p15')}}:</strong> {{$t('terms.term4.p16')}}</li>
        <li><strong>5-{{$t('terms.term4.p17')}}:</strong> {{$t('terms.term4.p18')}}</li>
    </ul>
    
    <p><strong>{{$t('terms.term5.p2')}}:</strong> {{$t('terms.term5.p3')}}</p>
 </div>
</template>
<style scoped>
   ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        margin-bottom: 10px;
        font-size: 12px;
    }
    p{
        padding: 1px 0px;
        font-size: 12px;
    }
    strong {
        color: white;
    }
    ul{
        padding: 2px 0px
    }
    h2 , h3{
        color: white;
        padding: 2px 0px;
        font-size: 12px;
    }
    h1{
        color: white;
        padding: 4px 0px;
        font-size: 14px;
    }
</style>