<script setup>
import MarketGroupCard from '@/components/Market/MarketGroupCard.vue';
import MatchStats from '@/components/Match/MatchStats.vue';
import { socket } from '@/feed.socket';
import { useAuthStore } from '@/stores/auth';
import { useBetslipStore } from '@/stores/betslip';
import { useLiveStore } from '@/stores/live';
import { useResourcesStore } from '@/stores/resources';
import { storeToRefs } from 'pinia';
import { computed, onBeforeUnmount, onMounted, onUnmounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import _ from 'lodash';
import http from '@/composables/axios';

const auth = useAuthStore()
const resources = useResourcesStore()
const live = useLiveStore()
const betslips = useBetslipStore()
const { getUser } = storeToRefs(auth)
const { isEventSelected } = storeToRefs(betslips)
const { getSport, getLeague, getGroup, getGroupsMetadata, getMarket } = storeToRefs(resources)
const route = useRoute()
const eid = computed(() => route.params.id)
const event = ref({})
const odds = ref([])
const activeGroup = ref(0)
let widget = undefined;
const widgetId = ref(0)
const keys = {
  'bet90.plus': 'gE4vTC0MMPh299S06i7wCg==:ekt5UmN3dytLMFNiOHZHZlFkd2pIQ0IzSnF1UnMxc0hXNnZ3SHhFcjNLeE1EbkhwSTh6WlUyMm5NZmJJcXVvcUo1WC9Ma1lpZFM3VXBnejlsSFhORXVuZm1LOXNCdWNTSGhKRWVQUHlEYlE9',
  'betzone24.com': 'DPOAECM9BYjXSjyl/FJYww==:Rk9KVDhjWTRKSTVYLzZKU3F1c1VoKzRCNkhybEZ5d2pDTlkwcVkrMU9aTE0ydlFYVm1lQlRlWE5tUmtxd25waFdwVnVrUCtzQU9JUUIwOTZJN2YvVjZhVVdCSmZZcXJhNWNTRmNrbUNpaTQ9',
  'localhost': 'DPOAECM9BYjXSjyl/FJYww==:Rk9KVDhjWTRKSTVYLzZKU3F1c1VoKzRCNkhybEZ5d2pDTlkwcVkrMU9aTE0ydlFYVm1lQlRlWE5tUmtxd25waFdwVnVrUCtzQU9JUUIwOTZJN2YvVjZhVVdCSmZZcXJhNWNTRmNrbUNpaTQ9'
}

const oddSet = computed(() => new Set(odds.value.map((odd) => odd.fid)));

const groups = computed(() => {

    let result = {};
    for (const [id, group] of Object.entries(getGroupsMetadata.value)) {
        if (group.m.some((fid) => oddSet.value.has(fid))) {
            result[id] = group;
        }
    }

    return Object.fromEntries(
        Object.entries(result).sort(([, a], [, b]) => a.ord - b.ord)
    );
})


const oddsByGroup = computed(() => {
    let od = _.groupBy(
        odds.value.filter(odd => groups.value[activeGroup.value]?.m.includes(odd.fid)),
        'fid'
    );

    return Object.keys(od)
        .map((fid) => ({
            fid,
            order: getMarket.value(fid)?.lord ?? Infinity,
            name: getMarket.value(fid)?.nm || "Unknown",
            o: od[fid]
        }))
        .sort((a, b) => a.order - b.order);
});

const loadMarket = (market) => {
    console.log(market)
}

const widgetApiUrl = computed(() => import.meta.env.VITE_WIDGET_API_URL)
const widgetApiKey = computed(() => keys[window.location.hostname])
const widgetUrl = computed(() => import.meta.env.VITE_WIDGET_URL)

onMounted(async () => {
    await live.fetchEvent(eid.value).then(data => event.value = data)
    await live.fetchOdds(eid.value, {p: getUser.value.p ?? 0, m: getUser.value.mrg ?? 0 , sid: event.value.sid}).then(data => {
        odds.value = data
        activeGroup.value = Object.keys(groups.value)[0]
    })

    await http.post(widgetApiUrl.value, {
        mid: "merchant-1331",
        livestream: [
            {
                match_id: eid.value,
                sport_id: 50,
                league_name: getLeague.value(event.value.lid)?.nm,
                home_team: event.value.ht,
                away_team: event.value.at
            }
        ]
    }, {
        headers: {
            'x-api-key': widgetApiKey.value
        }
    }).then(res => {
        if (res.data.success && !res.data.errors) {
            for (let id in res.data.data) {
                if (id) {
                    widgetId.value = res.data.data[id].LiveTrackerID
                    sendMessageToLiveTracking({
                        lt_evID: widgetId.value,
                        lt_evSHOW: "Mobile",
                        lt_evLANG: "EN"
                    });
                }
            }
        }
    })

    socket.emit('live-event', {eid: eid.value})

    // STATSCOREWidgets.onLoad(err => {
    //     if (err) {
    //         switch (err.type) {
    //             case 'NetworkError':
    //                 // Handle network error here
    //                 break;

    //             case 'BrowserNotSupported':
    //                 // Handle unsupported browser here
    //                 break;
    //         }

    //         return;
    //     }

    //     const element = document.getElementById('myWidget')

    //     // Configuration that you should receive from STATSCORE
    //     const configurationId = '65030e768711d2db1d016f66';

    //     // Input data for widget type you want to embded
    //     const inputData = { eventId: 'm:'+eid.value, language: 'en' };

    //     // Optional object with options.
    //     // You can check available options further in the docs.
    //     const options = {};

    //     widget = new window.STATSCOREWidgets.WidgetGroup(element, configurationId, inputData, options);
    // });

    socket.on('odd:live', (data) => {
        const liveOdds = data.filter(odd => odd.eid == eid.value);
        const ticketOdds = data.filter(odd => odd.eid !== eid.value);

        liveOdds.forEach(odd => {
            const sportId = event.value.sid;
            const sport = getSport.value(sportId);
            if (sport?.m) {
                const newOdd = odd.p / ((sport.m || 1) / 100 + 1);
                odd.p = newOdd > 1 ? newOdd : 1;
            }
            const indexOfOdd = odds.value.findIndex(o => o.uid === odd.uid);
            if (indexOfOdd > -1) {
                odds.value.splice(indexOfOdd, 1, odd);
            } else {
                odds.value.push(odd);
            }
            betslips.updateOdd(eid.value, odd);
        });

        if (ticketOdds.length) {
            live.updateOdds(ticketOdds, getUser.value);
        }
    });

})

onBeforeUnmount(async () => {
    // widget.destroy()
    if (!isEventSelected.value(event.value.fid)) {
        socket.emit('leave-live-event', {eid: event.value.fid})
    }
})
</script>

<template>
    <div class="flex flex-col-reverse xl:flex-row overflow-hidden">
        <div class="overflow-y-auto flex-1">
            <div class="elv-EventHeader hidden xl:block pb-4 relative">
                <div class="px-8">
                    <div class="pt-4">
                        <RouterLink
                            :to="`/in-play?s=${event.sid}`"
                            class="text-white text-xs"
                        >
                            &lt; {{$t('app.10')}} {{ getSport(event.sid)?.nm }} - {{ getLeague(event.lid)?.nm }}
                        </RouterLink>
                    </div>
                    <div class="py-6">
                        <h2 class="text-white text-xl font-bold">{{ event.ht }} v {{ event.at }}</h2>
                    </div>

                    <div>
                        <ul class="list-none flex space-x-4">
                            <li v-for="group, fid in groups">
                                <div
                                    class="text-[13px] text-white cursor-pointer px-2 py-1"
                                    :class="{'bg-[var(--color-primary3)] rounded-2xl !text-black font-bold': fid == activeGroup}"
                                    @click="activeGroup = fid"
                                >
                                    {{ getGroup(fid)?.nm }}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div v-show="widgetId">
                <!-- <div id="myWidget"></div> -->
                <iframe
                    id="LiveTracking"
                    class="h-96 w-full focus:outline-none select-none"
                    name="LiveTracking"
                    :src="widgetUrl + encodeURIComponent(widgetApiKey)"
                    frameborder="0"
                    scrolling="no"
                ></iframe>
            </div>
            <div>
                <ul class="xl:hidden px-2 py-3 list-none flex space-x-4 border-t border-b border-[var(--color-primary6)] overflow-x-auto">
                    <li v-for="group, fid in groups">
                        <div
                            class="text-[13px] text-white cursor-pointer px-2 py-1"
                            :class="{'bg-[var(--color-primary3)] rounded-2xl !text-black font-bold': fid == activeGroup}"
                            @click="activeGroup = fid"
                        >
                            {{ getGroup(fid)?.nm }}
                        </div>
                    </li>
                </ul>
            </div>

            <div class="py-1.5 xl:py-4 px-2 xl:px-6 bg-[var(--color-grey3)] relative" v-if="activeGroup">
                <template v-for="market in oddsByGroup">
                    <MarketGroupCard
                        :fid="market.fid"
                        :odds="market.o"
                        :ht="event.ht"
                        :at="event.at"
                        v-lazy
                    />
                </template>
            </div>
        </div>

        <MatchStats
            :event="event"
        />
    </div>
</template>

<style scoped>
.elv-EventHeader::before {
    content: "";
    position: absolute;
    width: 100%;
    background-size: 100% 100%;
    background-image: linear-gradient(rgba(12, 22, 20, .1), transparent 20px), radial-gradient(122% 370px at center -220px, #009969 0, transparent 100%), linear-gradient(to right bottom, #0c1614, #084436);
    height: var(--elv-header-height);
    z-index: -1;
}
</style>
