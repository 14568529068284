const odds = {
    1: {
        id: 200,
        line: 0,
        order: ['1', 'X', '2'],
    },
    2: {
        id: 200,
        line: 0,
        order: ['Odd', 'Even']
    },
    3: {
        id: 200,
        line: 0,
        order: ['1X', 'X2', '12']
    },
    4: {
        id: 200,
        line: 0,
        order: ['Yes', 'No']
    },
    5: {
        id: 200,
        line: 0,
        order: ['1', '2']
    },
    6: {
        id: 200,
        line: 0,
        order: ['1', 'No Goal', '2']
    },
    7: {
        id: 200,
        line: 0,
        order: ['1/1', 'X/X', '2/2', 'X/1', '1/X', 'X/2', '2/1', '2/X', '1/2']
    },
    8: {
        id: 200,
        line: 0,
        order: ['1', 'No Card', '2']
    },
    9: {
        id: 200,
        line: 0,
        order: ['1st Half', '2nd Half', 'All Periods The Same']
    },
    10: {
        id: 200,
        line: 0,
        order: ['1st Half', 'All Periods The Same', '2nd Half']
    },
    11: {
        id: 200,
        line: 0,
        order: ['No Goal', '1', '2', '3', '4', '5', '6', '7', '2 Or More', '3 Or More', '4 Or More', '5 Or More', '6 Or More', '7 Or More']
    },
    12: {
        id: 200,
        line: 0,
        order: ['X', '2'],
    },
    13: {
        id: 200,
        line: 0,
        order: ['1', 'X'],
    },
    14: {
        id: 200,
        line: 0,
        order: ['12 & Yes', '12 & No', '1X & Yes', '1X & No', 'X2 & Yes', 'X2 & No']
    },
    15: {
        id: 200,
        line: 0,
        order: ['1st Half', 'Draw', '2nd Half']
    },
    50: {
        id: 300,
        line: 1,
        order: ['Over', 'Under']
    },
    51: {
        id: 300,
        line: 1,
        order: ['1', '2']
    },
    52: {
        id: 300,
        line: 1,
        order: ['1', 'X', '2']
    },
    53: {
        id: 300,
        line: 1,
        order: ['1', 'No Goal', '2']
    },
    54: {
        id: 300,
        line: 1,
        order: ['Over', 'Exactly', 'Under']
    },
    55: {
        id: 300,
        line: 1,
        order: ['1', 'Neither', '2']
    },
    56: {
        id: 300,
        line: 1,
        order: ['Yes', 'No']
    },
    57: {
        id: 301,
        line: 1,
        order: {
            over: ['1 And Over', 'X And Over', '2 And Over'],
            under: ['1 And Under', 'X And Under', '2 And Under']
        }
    },
    58: {
        id: 300,
        line: 1,
        order: ['Before', 'Not Before']
    },
    100: {
        id: 400,
        line: 0
    },
    1000: {
        id: 200,
        line: 0,
        order: ['1 To Nil', '1 And Both Teams To Score', '2 To Nil', '2 And Both Teams To Score', 'Both Teams Not To Score', 'Both Teams To Score And X']
    },
    1001: {
        id: 201,
        line: 0
    },
    1002: { // 1X2 And Under Over
        id: 205,
        line: 1
    },
    1003: { // Under/Over And Both teams to score
        id: 206,
        line: 1
    }
}

export default {
    1: odds[1],
    2: odds[50],
    3: odds[51],
    4: odds[7],
    5: odds[2],
    6: odds[100],
    7: odds[3],
    9: odds[100],
    11: odds[50],
    12: odds[50],
    13: odds[52],
    16: odds[5],
    17: odds[4],
    19: odds[8],
    21: odds[50],
    22: odds[4],
    23: odds[4],
    25: odds[3],
    28: odds[50],
    29: odds[50],
    30: odds[50],
    31: odds[50],
    34: odds[4],
    35: odds[4],
    41: odds[1],
    42: odds[1],
    43: odds[1],
    44: odds[1],
    45: odds[50],
    46: odds[50],
    47: odds[50],
    48: odds[50],
    49: odds[1],
    45: odds[50],
    46: odds[50],
    47: odds[50],
    48: odds[50],
    50: odds[1],
    51: odds[2],
    52: odds[5],
    53: odds[51],
    55: odds[5],
    56: odds[6],
    57: odds[6],
    59: odds[53],
    61: odds[52],
    62: odds[2],
    63: odds[5],
    64: odds[51],
    65: odds[51],
    66: odds[51],
    67: odds[51],
    68: odds[51],
    71: odds[15],
    72: odds[2],
    73: odds[2],
    74: odds[2],
    75: odds[2],
    76: odds[2],
    77: odds[50],
    79: odds[4],
    81: odds[5],
    84: odds[5],
    85: odds[5],
    86: odds[5],
    88: odds[5],
    92: odds[5],
    95: odds[51],
    96: odds[4],
    97: odds[4],
    98: odds[4],
    99: odds[4],
    100: odds[100],
    101: odds[50],
    102: odds[50],
    104: odds[4],
    105: odds[4],
    106: odds[4],
    107: odds[4],
    113: odds[4],
    128: odds[11],
    129: odds[50],
    132: odds[50],
    133: odds[50],
    134: odds[11],
    136: odds[50],
    137: odds[12],
    138: odds[13],
    143: odds[9],
    144: odds[9],
    145: odds[11],
    146: odds[11],
    147: odds[11],
    148: odds[11],
    149: odds[11],
    150: odds[11],
    151: odds[3],
    153: odds[50],
    154: odds[50],
    155: odds[50],
    156: odds[50],
    157: odds[50],
    158: odds[50],
    161: odds[1],
    162: odds[50],
    163: odds[11],
    165: odds[50],
    166: odds[50],
    168: odds[4],
    169: odds[5],
    170: odds[2],
    171: odds[5],
    180: odds[50],
    181: odds[50],
    182: odds[50],
    183: odds[50],
    184: odds[50],
    185: odds[50],
    186: odds[50],
    187: odds[50],
    192: odds[4],
    194: odds[4],
    195: odds[4],
    198: odds[2],
    199: odds[2],
    196: odds[4],
    197: odds[4],
    201: odds[51],
    202: odds[5],
    203: odds[5],
    204: odds[5],
    205: odds[5],
    206: odds[5],
    207: odds[1],
    208: odds[1],
    210: odds[50],
    211: odds[4],
    212: odds[4],
    214: odds[50],
    216: odds[4],
    217: odds[100],
    219: odds[4],
    220: odds[50],
    221: odds[50],
    222: odds[50],
    223: odds[50],
    224: odds[3],
    225: odds[3],
    226: odds[5],
    236: odds[50],
    242: odds[2],
    243: odds[2],
    250: odds[51],
    281: odds[51],
    282: odds[1],
    283: odds[51],
    284: odds[1],
    285: odds[2],
    286: odds[5],
    287: odds[50],
    288: odds[50],
    289: odds[2],
    290: odds[2],
    291: odds[2],
    292: odds[2],
    293: odds[2],
    294: odds[2],
    301: odds[56],
    302: odds[56],
    305: odds[54],
    308: odds[50],
    309: odds[50],
    317: odds[54],
    322: odds[54],
    329: odds[55],
    330: odds[55],
    331: odds[55],
    332: odds[55],
    333: odds[55],
    337: odds[50],
    338: odds[6],
    341: odds[55],
    342: odds[51],
    348: odds[1],
    349: odds[1],
    350: odds[2],
    351: odds[2],
    352: odds[50],
    353: odds[50],
    354: odds[50],
    355: odds[50],
    358: odds[50],
    366: odds[2],
    367: odds[2],
    387: odds[2],
    388: odds[2],
    390: odds[7],
    394: odds[100],
    395: odds[100],
    398: odds[2],
    400: odds[2],
    401: odds[50],
    402: odds[50],
    403: odds[50],
    404: odds[50],
    405: odds[50],
    406: odds[2],
    407: odds[51],
    408: odds[51],
    409: odds[1],
    410: odds[1],
    414: odds[50],
    416: odds[50],
    417: odds[50],
    415: odds[1000],
    419: odds[1],
    427: odds[1002],
    // 428: odds[1001],
    431: odds[54],
    433: odds[52],
    434: odds[1],
    435: odds[1],
    436: odds[50],
    437: odds[50],
    438: odds[2],
    443: odds[55],
    444: odds[55],
    445: odds[5],
    446: odds[5],
    447: odds[51],
    448: odds[51],
    449: odds[51],
    450: odds[51],
    451: odds[5],
    452: odds[5],
    456: odds[3],
    457: odds[3],
    464: odds[5],
    465: odds[5],
    466: odds[50],
    467: odds[51],
    468: odds[51],
    469: odds[50],
    470: odds[51],
    471: odds[51],
    473: odds[51],
    474: odds[51],
    475: odds[50],
    476: odds[50],
    477: odds[50],
    478: odds[50],
    479: odds[2],
    480: odds[2],
    523: odds[1003],
    525: odds[50],
    526: odds[51],
    528: odds[1],
    529: odds[2],
    553: odds[50],
    554: odds[5],
    555: odds[51],
    557: odds[50],
    558: odds[50],
    559: odds[50],
    566: odds[5],
    567: odds[5],
    568: odds[5],
    569: odds[5],
    570: odds[5],
    571: odds[5],
    573: odds[5],
    574: odds[5],
    575: odds[5],
    576: odds[5],
    577: odds[50],
    578: odds[50],
    601: odds[7],
    634: odds[2],
    635: odds[2],
    636: odds[2],
    691: odds[2],
    692: odds[2],
    693: odds[2],
    694: odds[2],
    751: odds[55],
    756: odds[50],
    766: odds[50],
    767: odds[50],
    820: odds[55],
    835: odds[50],
    836: odds[50],
    839: odds[1002],
    844: odds[5],
    846: odds[50],
    851: odds[15],
    863: odds[1],
    866: odds[51],
    879: odds[50],
    880: odds[54],
    914: odds[50],
    915: odds[50],
    916: odds[50],
    917: odds[50],
    919: odds[50],
    920: odds[50],
    922: odds[50],
    927: odds[50],
    928: odds[50],
    929: odds[50],
    930: odds[50],
    935: odds[52],
    936: odds[50],
    938: odds[50],
    939: odds[50],
    940: odds[58],
    947: odds[50],
    948: odds[50],
    950: odds[50],
    955: odds[1],
    958: odds[58],
    960: odds[50],
    976: odds[1],
    989: odds[50],
    990: odds[50],
    991: odds[50],
    998: odds[5],
    999: odds[5],
    1001: odds[50],
    1002: odds[50],
    1003: odds[50],
    1004: odds[50],
    1013: odds[1],
    1030: odds[58],
    1031: odds[50],
    1034: odds[5],
    1035: odds[5],
    1036: odds[5],
    1037: odds[5],
    1038: odds[5],
    1039: odds[5],
    1040: odds[5],
    1041: odds[5],
    1042: odds[5],
    1043: odds[5],
    1044: odds[5],
    1051: odds[50],
    1052: odds[50],
    1053: odds[50],
    1054: odds[50],
    1061: odds[50],
    1062: odds[15],
    1063: odds[15],
    1069: odds[50],
    1070: odds[50],
    1071: odds[50],
    1072: odds[50],
    1073: odds[50],
    1074: odds[50],
    1075: odds[50],
    1081: odds[1],
    1082: odds[3],
    1083: odds[51],
    1089: odds[50],
    1090: odds[50],
    1091: odds[50],
    1092: odds[50],
    1093: odds[50],
    1105: odds[50],
    1106: odds[50],
    1112: odds[50],
    1120: odds[50],
    1121: odds[50],
    1128: odds[50],
    1129: odds[50],
    1130: odds[50],
    1131: odds[50],
    1132: odds[50],
    1133: odds[50],
    1134: odds[50],
    1135: odds[50],
    1136: odds[50],
    1139: odds[50],
    1141: odds[50],
    1147: odds[50],
    1148: odds[50],
    1149: odds[51],
    1150: odds[51],
    1151: odds[51],
    1161: odds[50],
    1162: odds[50],
    1163: odds[50],
    1164: odds[50],
    1188: odds[50],
    1189: odds[50],
    1211: odds[1],
    1212: odds[3],
    1213: odds[50],
    1214: odds[50],
    1215: odds[51],
    1216: odds[50],
    1217: odds[50],
    1222: odds[50],
    1223: odds[50],
    1224: odds[1],
    1225: odds[3],
    1226: odds[50],
    1227: odds[51],
    1228: odds[51],
    1229: odds[50],
    1230: odds[50],
    1231: odds[51],
    1232: odds[51],
    1234: odds[1],
    1235: odds[3],
    1236: odds[50],
    1237: odds[50],
    1238: odds[3],
    1239: odds[1],
    1240: odds[51],
    1241: odds[51],
    1243: odds[3],
    1244: odds[3],
    1246: odds[3],
    1247: odds[1],
    1248: odds[51],
    1249: odds[3],
    1250: odds[14],
    1267: odds[2],
    1268: odds[51],
    1269: odds[3],
    1270: odds[51],
    1271: odds[50],
    1272: odds[50],
    1273: odds[1],
    1318: odds[51],
    1319: odds[51],
    1320: odds[51],
    1321: odds[51],
    1322: odds[51],
    1323: odds[51],
    1324: odds[50],
    1367: odds[51],
    1368: odds[51],
    1369: odds[51],
    1370: odds[51],
    1371: odds[51],
    1372: odds[51],
    1373: odds[51],
    1374: odds[51],
    1375: odds[51],
    1531: odds[2],
    1532: odds[2],
    1552: odds[50],
    1619: odds[5],
    1621: odds[5],
    1677: odds[6],
    1693: odds[52],
    1694: odds[52],
    1731: odds[3],
    1792: odds[50],
    1795: odds[1002],
    1796: odds[1],
    1797: odds[3],
    1825: odds[50],
    1826: odds[50],
    1827: odds[50],
    1828: odds[50],
    1829: odds[50],
    1830: odds[50],
    1831: odds[1],
    1832: odds[50],
    1833: odds[50],
    1834: odds[50],
    1835: odds[50],
    1846: odds[1],
    1853: odds[50],
    1870: odds[50],
    1871: odds[1],
    1876: odds[1],
    1877: odds[51],
    1878: odds[51],
    1901: odds[50],
    1904: odds[50],
    1905: odds[50],
    1918: odds[1],
    1920: odds[50],
    1921: odds[3],
    1927: odds[50],
    1928: odds[50],
    1929: odds[50],
    1930: odds[50],
    1931: odds[50],
    1964: odds[1],
    1965: odds[3],
    1966: odds[2],
    1967: odds[51],
    1973: odds[50],
    1988: odds[50],
    1989: odds[50],
    1990: odds[50],
    1991: odds[50],
    1992: odds[50],
    1993: odds[50],
    1994: odds[50],
    1995: odds[50],
    1996: odds[50],
    1997: odds[50],
    1998: odds[50],
    2578: odds[1000],
    2034: odds[3],
    2044: odds[3],
    2062: odds[1],
    2082: odds[1],
    2084: odds[1],
    2095: odds[51],
    2096: odds[51],
    2098: odds[50],
    2099: odds[50],
    2103: odds[2],
    2104: odds[2],
    2126: odds[51],
    2127: odds[2],
    2128: odds[3],
    2129: odds[3],
    2130: odds[1],
    2131: odds[1],
    2133: odds[3],
    2134: odds[3],
    2146: odds[1],
    2147: odds[3],
    2148: odds[50],
    2149: odds[50],
    2150: odds[1],
    2151: odds[3],
    2152: odds[50],
    2153: odds[50],
    2155: odds[2],
    2157: odds[50],
    2159: odds[1],
    2160: odds[1],
    2161: odds[3],
    2162: odds[1],
    2163: odds[3],
    2173: odds[1],
    2174: odds[1],
    2175: odds[3],
    2176: odds[3],
    2177: odds[50],
    2178: odds[50],
    2179: odds[50],
    2180: odds[50],
    2181: odds[1],
    2182: odds[1],
    2183: odds[3],
    2184: odds[3],
    2201: odds[2],
    2202: odds[2],
    2222: odds[50],
    2223: odds[50],
    2226: odds[1],
    2227: odds[1],
    2229: odds[3],
    2230: odds[3],
    2261: odds[3],
    2269: odds[3],
    2284: odds[50],
    2285: odds[5],
    2286: odds[51],
    2288: odds[2],
    2289: odds[50],
    2290: odds[50],
    2341: odds[1],
    2351: odds[50],
    2352: odds[50],
    2359: odds[4],
    2360: odds[4],
    2372: odds[1],
    2373: odds[3],
    2375: odds[50],
    2376: odds[50],
    2377: odds[50],
    2406: odds[1],
    2407: odds[1],
    2448: odds[3],
    2721: odds[1],
    2722: odds[1],
    2946: odds[5],
    2947: odds[5],
    2948: odds[5],
    2949: odds[5],
    2950: odds[5],
    2951: odds[5],
    2952: odds[5],
    2953: odds[5],
}