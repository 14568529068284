<script setup>
import { useAuthStore } from '@/stores/auth';
import UserPasswordForm from './Partials/UserPasswordForm.vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const auth = useAuthStore()
const success = ref('')
const error = ref('')
const { t } = useI18n()

const update = async (form) => {
    await auth.updatePassword(form).then(res => { 
        if (res.status == 204) {
            error.value = ''
            success.value = 'changePassword.updated'
        }
    }).catch(e => {
        success.value = ''
        error.value = 'changePassword.' + e.response.data.message
    })
}
</script>

<template>
    <div>
        <div class="py-2 px-4 bg-emerald-200/25 text-emerald-400 text-xs font-semibold" v-if="success">
            {{ t(success) }}
        </div>
        <div class="py-2 px-4 bg-red-200/25 text-red-400 text-xs font-semibold" v-if="error">
            {{ t(error) }}
        </div>
        <UserPasswordForm 
            @submitted="update"
            :auth="true"
        />
    </div>
</template>