<script setup>
import AppHeader from '@/components/App/Header.vue'
import AppFooter from '@/components/App/Footer.vue'
import Betslips from '@/components/Betslips.vue';
import Spinner from '@/components/Spinner.vue';
import { useAuthStore } from '@/stores/auth';
import { useLiveStore } from '@/stores/live';
import { usePrematchStore } from '@/stores/prematch';
import { useResourcesStore } from '@/stores/resources';
import { storeToRefs } from 'pinia';
import { onBeforeUnmount, onMounted, watch } from 'vue';

const resourcesStore = useResourcesStore()
const prematchStore = usePrematchStore()
const liveStore = useLiveStore()
const authStore = useAuthStore()
const { isAuth, getUser } = storeToRefs(authStore)
const { hasResources } = storeToRefs(resourcesStore)
const { eventsLoaded } = storeToRefs(prematchStore)
import { socket } from '@/user.socket';
import { socket as feedSocket } from '@/feed.socket';

onMounted(async () => {
    if (isAuth.value) {
        Promise.all([
            prematchStore.fetchEvents({p: getUser.value.p ?? 0, m: getUser.value.mrg ?? 0}),
            liveStore.fetchEvents({p: getUser.value.p ?? 0, m: getUser.value.mrg ?? 0}),
        ]).then(() => {
            socket.connect()
            feedSocket.connect()
            authStore.bindSocketEvents()
            prematchStore.bindSocketEvents(getUser.value)
            liveStore.bindSocketEvents(getUser.value)
        })
    }
})
</script>

<template>
    <div class="h-screen flex flex-col overflow-hidden">
        <AppHeader />

        <main class="pageView w-full flex-1 overflow-auto">
            <RouterView v-if="hasResources && eventsLoaded" />

            <AppFooter />
        </main>

        <Spinner :show="!hasResources || !eventsLoaded" />

        <Betslips v-if="hasResources && eventsLoaded" />
    </div>
</template>
