<script setup>
import { useResponseMessageStore } from '@/stores/response_message';
import { storeToRefs } from 'pinia';

const responseMessageStore = useResponseMessageStore()
const { getMessage, hasMessage, getCloseable } = storeToRefs(responseMessageStore)

const clearMessage = () => {
    if (getCloseable.value) {
        responseMessageStore.clearMessage()
    }
}
</script>

<template>
    <div v-if="hasMessage">
        <div class="fixed w-full h-full top-0" @click="clearMessage" />
        <div id="msgbox" @click="clearMessage">
            <div class="flex-1 text-[#ddd]">
                {{ getMessage }}
            </div>
        </div>
    </div>
</template>

<style scoped>
#msgbox {
    background-color: #000;
    width: 90%;
    height: 80px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    text-align: center;
    opacity: .9;
    z-index: 155;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: 15px;
    padding: .5em;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #555;
}
</style>