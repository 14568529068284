<script setup>
import { useBetsStore } from '@/stores/bets';
import { onMounted, ref, watch } from 'vue';

const props = defineProps({
    eid: [String, Number],
    sid: [String, Number]
})

const bets = useBetsStore()
const actions = ref([])
let lastResult = ref()
onMounted(async () => {
    await bets.fetchTimeline(props.eid).then(res => {
        if (res.data.ok) {
            actions.value = res.data.data.sort((a, b) => b.sec - a.sec)
            lastResult.value = actions.value.find(a => a.inctype == 'Score')?.results || '0-0'
        }
    })
})
</script>

<template>
    <div class="bg-neutral-600/50 rounded-xl mt-2">
        <ul class="list-none py-1" v-if="actions.length > 0">
            <p class="text-center text-emerald-400 font-bold pb-1">{{ lastResult }}</p>
            <li 
                v-for="action in actions" 
                :class="[
                    'flex text-xs text-white py-px',
                    action.position == 2 ? 'tml-ActionAway' : 'tml-ActionHome',
                ]"
            >
                <div class="tml-Action">
                    <span v-if="action.position == 2" :class="`tml-ActionIcon tml-${action.inctype} mr-1.5`"></span>
                    <p class="tml-ActionMessage">{{ action.inctype }}</p>
                    <span v-if="action.position == 1" :class="`tml-ActionIcon tml-${action.inctype} ml-1.5`"></span>
                </div>
                <div class="w-[50px] py-1 text-center">
                    <p class="font-bold">{{ Math.floor(action.sec / 60) }}'</p>
                </div>
                <div class="flex-1"></div>
            </li>
        </ul>
        <p v-else class="text-xs text-white p-2">{{$t('partials.38')}}</p>
    </div>
</template>

<style scoped>
.tml-Action {
    @apply flex-1 inline-flex items-center text-[13px];
}

.tml-ActionHome .tml-Action {
    @apply justify-end;
}

.tml-ActionHome .tml-Action::after {
    content: "";
    @apply border-r-2 border-emerald-400 ml-2 h-full;
}

.tml-ActionAway {
    @apply flex-row-reverse;
}

.tml-ActionAway .tml-Action::before {
    content: "";
    @apply border-l-2 border-emerald-400 mr-2 h-full;
}

.tml-ActionIcon {
    display: inline-block;
    height: auto;
    width: 11px;
    height: 11px;
    background-position: 50%;
    background-repeat: no-repeat;
}

.tml-ActionIcon.tml-YellowCard {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='9' height='13' viewBox='0 0 9 13' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle%3ERectangle 112%3C/title%3E%3Crect x='143' y='565' width='9' height='13' rx='1' transform='translate(-143 -565)' fill='%23E4D539' fill-rule='evenodd'/%3E%3C/svg%3E")
}

.tml-ActionIcon.tml-RedCard {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='10' viewBox='0 0 8 10'%3E%3Cg fill='none'%3E%3Cpath fill='%23E83838' d='M.5.785C.5.351.857 0 1.276 0h5.448c.429 0 .776.356.776.785v8.43c0 .43-.346.78-.776.785H1.276A.783.783 0 0 1 .5 9.215V.785z'/%3E%3Cpath fill='%23000' fill-opacity='.25' d='M6.724 0c.429 0 .776.356.776.785v8.43c0 .43-.346.78-.776.785H1.276A.783.783 0 0 1 .5 9.215V.785C.5.351.857 0 1.276 0h5.448zm0 .2H1.276a.586.586 0 0 0-.568.49L.7.785v8.43c0 .289.21.532.483.577l.093.008h5.448c.28 0 .523-.215.568-.49l.008-.095V.785a.586.586 0 0 0-.483-.577L6.724.2z'/%3E%3Cpath fill='%23000' fill-opacity='.2' d='M5.755 7.565c1.57-2.243 1.73-5.46 1.74-6.469L7.5.785v8.43c0 .43-.346.78-.776.785l-5.76-.001.319-.017s2.588.273 4.472-2.417z'/%3E%3C/g%3E%3C/svg%3E");
}

.tml-ActionIcon.tml-Score {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg height='11' viewBox='0 0 11 11' width='11' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 5.68a5.156 5.156 0 1 1 10.312 0A5.156 5.156 0 0 1 0 5.68z' fill='%23fff'/%3E%3Cg fill='%23545454'%3E%3Cpath d='M.86 8.84c.474-.045 2.393-.318 2.53-.428v-.327l-2.703.442.173.313zm2.871 1.948c.12-.462.294-1.049.237-1.216l-.306-.111-.225 1.257s.101.037.19.058c.042.01.08.016.104.012zM.102 6.793l1.309-3.334-.264-.186L0 6.421zM3.765 6.408L2.379 3.273l-.316.039 1.425 3.197zM1.328 2.666l-.463-.08-.177.238.492.095zM5.696 7.226l2.582-2.422-.245-.156L5.5 7.025zM7.26.675c-.152-.087-.225-.08-.385-.152l1.606 1.57h.43S7.71 1.159 7.259.675zM3.35.617s-.368.309-.6.705l.133.137.787-.936zM9.451 4.648l-.248.216-.265 3.725.285-.242zM8.87 9.464a5.59 5.59 0 0 0 .153-.168l-3.465-.523-.058.268s3.19.51 3.37.423zm-1.725-6.37s-.05-.163 0-.22L3.57 1.898l-.132.208z'/%3E%3C/g%3E%3Cpath d='M5.542 6.521s-.59-.093-1.243-.249c-.652-.156-.994-.249-.994-.249s-.404.467-.746.997c-.341.53-.496 1.246-.496 1.246l1.49 1.495 2.237-.996zm4.175 1.583l-.858-.018-1.328 1.187s-.056.924.054 1.012c.563-.198 1.754-1.173 2.132-2.181zM7.62 4.889l1.884.616s.364-.654.423-.917c.05-.226.17-.726.17-.726s-.241-.622-.749-1.252c-.094-.11-1.23-.712-1.23-.712l-1.243.997zm-5.372-.917l1.49-1.765-.496-.997-1.54.55-.417.377s-.373.507-.528.818c-.156.312 0 .997 0 .997h1.49zm-1.14 5.084L.723 7.593s-.16-.342-.378-.934c-.073-.2-.272-.412-.346-.636-.01.899.4 2.144 1.107 3.033z' fill='%23111'/%3E%3C/g%3E%3C/svg%3E");
}

.tml-ActionIcon.tml-Corners {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='10' height='13' viewBox='0 0 10 13'%3E%3Cdefs%3E%3Cpath id='a' d='M0 .1c2.2 1.298 5.2 2.3 9 3.006-3.884 2.37-6.884 3.372-9 3.007V.1z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(1 .5)'%3E%3Cpath fill='%23E83838' fill-rule='nonzero' d='M0 .1c2.2 1.298 5.2 2.3 9 3.006-3.884 2.37-6.884 3.372-9 3.007V.1z'/%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cpath fill='%23000' fill-rule='nonzero' d='M0 .1C.464.02 2.21.093 2.671.1c1.825 2.112-2.504 1.552-1.4 6.006-.337.95-.813.362-1.271.007V.1z' mask='url(%23b)' opacity='.2'/%3E%3C/g%3E%3Cpath fill='%23A7A7A7' fill-rule='nonzero' d='M0 .142a.953.953 0 0 1 1 0v12.734c-.267.152-.747.178-1 0V.142z'/%3E%3C/g%3E%3C/svg%3E");
}
</style>