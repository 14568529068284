<script setup>
import { useUsersStore } from '@/stores/users';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

const users = useUsersStore()
const { getUsers } = storeToRefs(users)
const route = useRoute()
const uid = computed(() => route.query.uid)
const { t } = useI18n()
const activeTab = ref(1)
const success = ref('')
const usersByStatus = computed(() => getUsers.value.filter(u => activeTab.value ? u.ac : !u.ac))

const fetchUsers = async () => {
    await users.fetchUsers(uid.value)
}

watch(() => route.query, async value => {
    await fetchUsers()
})

onMounted(async () => {
    await fetchUsers()
})
const blockAll = async() =>{
    await users.blockAllUsers().then(res => {
        if (res.status == 204) {
            success.value = 'blockUsers.users_disabled'
            fetchUsers()
        }
    })
}

const unblockAll = async() =>{
    await users.unblockAllUsers().then(res => {
        if (res.status == 204) {
            success.value = 'blockUsers.users_enabled'
            fetchUsers()
        }
    })
}
</script>

<template>
    <div>
        <div class="px-4 my-2">
            <RouterLink to="/users" class="text-sm font-semibold text-neutral-400" v-if="uid">&larr; {{$t('app.19')}}</RouterLink>
        </div>
        <div class="px-4 h-12 flex justify-between items-center">
            <ul class="list-none flex items-center space-x-2">
                <li class="tab-item" :class="{'active': activeTab == 1}" @click="activeTab = 1">
                    <span>{{$t('app.20')}}</span>
                </li>
                <li class="tab-item" :class="{'active': activeTab == 0}" @click="activeTab = 0">
                    <span>{{$t('app.21')}}</span>
                </li>
            </ul>
            <div>
                <button class="py-1.5 px-3 text-center text-red-600 font-bold text-[13px]" @click="blockAll" v-if="activeTab == 1">
                    {{ $t('blockUsers.disable_all')}}
                </button>
                <button class="py-1.5 px-3 text-center text-emerald-600 font-bold text-[13px]" @click="unblockAll" v-if="activeTab == 0">
                    {{ $t('blockUsers.enable_all')}}
                </button>
            </div>
        </div>
        <div class="py-2 px-4 bg-emerald-200/25 text-emerald-400 text-xs font-semibold" v-if="success">
            {{ t(success) }}
        </div>
        <div class="px-4 py-2">
            <ul class="list-none space-y-1">
                <li v-for="user in usersByStatus">
                    <div class="usm-Users">
                        <RouterLink :to="`/users/${user._id}`" class="flex items-center">
                            <div class="usm-UserStatus" :class="{'enabled': user.ac, 'disabled': !user.ac}" />
                            <p class="usm-UsersName">{{ user.un }}</p>
                        </RouterLink>

                        <div class="flex items-cetner space-x-4">
                            <RouterLink :to="`/users?uid=${user._id}`" class="usm-UserHierarchy" v-if="user.rl != 'clt'" />
                            <RouterLink :to="`/users/${user._id}#changePassword`" class="usm-UserLock" />
                            <RouterLink :to="`/users/${user._id}#credits`" class="usm-UserBalances" />
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<style scoped>
.usm-Users {
    background-image: var(--mgc-bg);
    @apply flex items-center justify-between px-3 py-2 rounded;
}

.usm-UserStatus {
    @apply w-2.5 h-2.5 rounded-full block;
}
.usm-UserStatus.enabled {
    @apply bg-emerald-400;
}
.usm-UserStatus.disabled {
    @apply bg-red-600;
}
.usm-UsersName {
    @apply text-neutral-300 text-base font-bold ml-1.5;
}

.usm-UserLock {
    @apply cursor-pointer;
    mask-image: url('/lock-closed.svg');
    mask-size: 18px 18px;
    height: 18px;
    width: 18px;
    background-color: var(--color-grey6);
}

.usm-UserBalances {
    @apply cursor-pointer;
    mask-image: url('/currency-dollar.svg');
    mask-size: 18px 18px;
    height: 18px;
    width: 18px;
    background-color: var(--color-grey6);
}

.usm-UserHierarchy {
    @apply cursor-pointer;
    mask-image: url('/users.svg');
    mask-size: 18px 18px;
    height: 18px;
    width: 18px;
    background-color: var(--color-grey6);
}
</style>
