import { defineStore } from "pinia";
import http from "@/composables/axios";

import { openDB } from 'idb';

export const useResourcesStore = defineStore('resources', {
    state: () => ({
        version: 0,
        locations: {},
        leagues: {},
        sports: {},
        markets: {},
        groups: {},
        teams: {},
        marketsMetadata: {},
        leaguesMetadata: [],
        groupsMetadata: {},
        languages:[]
    }),

    getters: {
        hasSports: state => { for (let key in state.sports) { return true }; return false },
        hasLeagues: state => { for (let key in state.leagues) { return true }; return false },
        hasLocations: state => { for (let key in state.locations) { return true }; return false },
        hasMarkets: state => { for (let key in state.markets) { return true }; return false },
        hasGroups: state => { for (let key in state.groups) { return true }; return false },
        hasResources() {
            return this.hasSports && this.hasLeagues && this.hasLocations && this.hasMarkets
        },
        getSport: state => fid => state.sports[fid],
        getLocation: state => fid => state.locations[fid],
        getLeague: state => fid => state.leagues[fid],
        getMarkets: state => state.markets,
        getMarket: state => fid => state.markets[fid],
        getGroup: state => id => state.groups[id],
        getTeam: state => id => id in state.teams ? state.teams[id] : null,
        mainMarkets: state => Object.fromEntries(
            Object.entries(state.marketsMetadata).filter(([key, value]) => value['mm'] === true)
        ),
        getMarketMetadata: state => fid => state.marketsMetadata[fid],
        trendingLeagues: state => Object.values(state.leaguesMetadata).filter(league => league.fav),
        mainLeagues: state => Object.values(state.leagues).filter(league => league.ml),
        getGroupsMetadata: state => state.groupsMetadata,
        getLanguages: state => state.languages,
    },

    actions: {
        setResourcesOnStore(data) {
            this.sports = data.s
            this.leagues = data.l
            this.locations = data.lo,
            this.markets = data.m
            this.groups = data.g
            this.teams = data.t
            this.languages = data.languages
        },

        async fetchAllConfigResources() {
            window.localStorage.removeItem('resources')
            window.localStorage.removeItem('metadata')
            window.localStorage.removeItem('metadataV')

            const db = await openDB('MyDatabase', 23, {
                upgrade(db) {
                    if (!db.objectStoreNames.contains('betting')) {
                        db.createObjectStore('betting');
                    }
                },
            });

            const metadataV = await db.get('betting', 'metadataV');
            
            let lang = window.localStorage.getItem('lang') ?? 'en'
            await http.get(`02/all?version=${metadataV}&lang=${lang}`).then(async res => {
                if (res.status != 204 && res.data.ok) {
                    await db.put('betting', res.data.data, 'metadata');
                    await db.put('betting', res.data.data.v, 'metadataV');
                }
                let metadata = await db.get('betting', 'metadata');
                this.setResourcesOnStore(metadata)

                this.marketsMetadata = metadata.m
                this.leaguesMetadata = metadata.l
                this.groupsMetadata = metadata.g

             
            })
        }
    }
})
