<script setup>
import { toFixedTwo } from '@/helpers';
import { useBetslipStore } from '@/stores/betslip';
import { storeToRefs } from 'pinia';
import { computed, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t, te } = useI18n()
const props = defineProps({
    odd: Object,
    oddClasses: String,
    withName: {
        type: Boolean,
        default: false
    },
    withLine: {
        type: Boolean,
        default: false
    }
})

const betslip = useBetslipStore()
const { isHighlited, getOddType } = storeToRefs(betslip)
const type = ref(localStorage.getItem('oddType'))
const hasChanged = ref(false)
let name = computed(() => {
    return te(props.odd.nm) ? t(props.odd.nm) : props.odd.nm;
})

watch(
    () => props.odd, 
    (val) => {
        if (val) { 
            hasChanged.value = true
            setTimeout(() => {
                hasChanged.value = false
            }, 1000)
        }
    },
    { deep: true } 
)

</script>

<template>
    <div 
        class="h-full flex flex-col items-center justify-center cursor-pointer odd"
        :class="[
            isHighlited(odd.uid) ? 'bg-[var(--color-grey9)]' : 'hover:bg-[var(--color-white15)]'
        ]"
        @click="betslip.setOdd(odd)"
        v-if="odd && odd.st != 3"
    >
        <p
            class="text-[13px]"
            :class="{
                'text-neutral-400 flex items-center justify-between': odd.st == 2,
                'text-[var(--color-yellow1)]': odd.st == 1,
                '!text-[var(--color-primary1)]': isHighlited(odd.uid),
            }"
        >
            <span 
                v-if="withName" 
                class="mr-2"
                :class="{
                    'text-neutral-400 whitespace-nowrap	': odd.st == 2,
                    'text-white': odd.st == 1
                }"
            >
                <span v-if="!withLine">{{ name }}</span>
                <span v-else>{{ $t(odd.nm + ' ' + odd.ln) }}</span>
            </span>
           <img src="/lock2.svg" class="h-4 w-full" alt="" v-if="odd.st == 2">
            <span :class="oddClasses , hasChanged ? 'font-bold' : ''" v-else class="Odd">
                {{ getOddType == 'p' ? toFixedTwo(odd[getOddType]) : odd[getOddType] }}
            </span> 
        </p>
    </div>
</template>
