<script setup>
import { useLiveStore } from '@/stores/live';
import { useResourcesStore } from '@/stores/resources';
import { storeToRefs } from 'pinia';

defineProps({
    sports: Array,
    active: [Number, String]
})

defineEmits(['selected'])

const resources = useResourcesStore()
const { getSport } = storeToRefs(resources)
</script>

<template>
    <div class="bg-[var(--color-grey3)] relative lv-SportsContainer" :class="`lv-SportsContainerActive-${active}`">
        <ul class="lv-SportsBar">
            <li 
                v-for="sport in sports" 
                class="lv-SportBarItem" 
                :class="[
                    `lv-SportBarItem lv-SportsContainerActive-${active}`,
                    {'lv-SportBarItemFilter': active == sport},
                    {'lv-SportBarItemInactive': active != sport},
                ]"
                @click="$emit('selected', sport)"
            >
                <div class="flex flex-col items-center py-3">
                    <img :src="`/sport/${sport}.svg`" class="w-6 h-6">
                    <p class="mt-1 text-[11px] text-white font-bold">{{ getSport(sport).nm }}</p>
                </div>
            </li>
        </ul>
    </div>
</template>

<style scoped>
.lv-SportsContainer::before {
    background-color: hsla(0,0%,100%,.17);
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    right: 0;
    transition: background-color .2s;
}

.lv-SportsContainerActive-6046::before {background-color: #408058;}
.lv-SportsContainerActive-54094::before {background-color: #5C8037;}
.lv-SportsContainerActive-48242::before {background-color: #855800;}
.lv-SportsContainerActive-452674::before {background-color: #718040;}
.lv-SportsContainerActive-154923::before {background-color: #783E3A;}
.lv-SportsContainerActive-35232::before {background-color: #4B5E69;}
.lv-SportsContainerActive-265917::before {background-color: #396188;}
.lv-SportsContainerActive-154830::before {background-color: #8F6B41;}

.lv-SportsBar {
    @apply list-none flex space-x-6 px-2 xl:px-4;
}

.lv-SportBarItem {
    @apply relative;
}

.lv-SportBarItemInactive {
    @apply opacity-90;
}

.lv-SportBarItem::before {
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    right: 0;
    transition: background-color .2s;
}

.lv-SportBarItem.lv-SportBarItemFilter::before {
    filter: brightness(2)
}
</style>