<script setup>
import { useGamesStore } from '@/stores/games';
import { storeToRefs } from 'pinia';
import { computed, onBeforeMount, onBeforeUnmount, onMounted, ref } from 'vue';

const games = useGamesStore()
const { getGame, isUrl } = storeToRefs(games)
const header = ref(true)
const height = ref(0)
const o = ref('')

const isMobileSafari = computed(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Check if the device is mobile
    const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(userAgent);

    const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
    const isSamsungBrowser = /SamsungBrowser/i.test(userAgent);

    return isMobile && (isSafari || isSamsungBrowser);
})

const setHeight = () => {
    const headerHeight = header ? document.getElementById("header")?.offsetHeight : 0
    height.value = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) - headerHeight
}

const hideHeader = () => {
    let header = document.getElementById('header')
    header.style.display = 'none'
    header.value = false

    let gameFrame = document.getElementById('lsframe')
    gameFrame.style.height = document.documentElement.clientHeight || window.innerHeight + 'px'
}

const showHeader = () => {
    let header = document.getElementById('header')
    header.style.display = 'block'
    header.value = true

    let gameFrame = document.getElementById('lsframe')
    gameFrame.style.height = '100%'
}

const resizeHandler = () => {
    const orientation = window.screen.orientation?.type;

    if (orientation === 'landscape-primary' || orientation === 'landscape-secondary') {
        hideHeader();
    } else if (orientation === 'portrait-primary' || orientation === 'portrait-secondary') {
        showHeader();
    }

    setHeight();
};

const registerResizeEvent = () => {
    // o.value = isMobileSafari.value
    if (isMobileSafari.value) {
        window.addEventListener("resize", resizeHandler);
    } else {
        screen.orientation.addEventListener("change", resizeHandler);
    }
}

const removeResizeEvent = () => {
    if (isMobileSafari.value) {
        window.removeEventListener("resize", resizeHandler);
    } else {
        screen.orientation.removeEventListener("change", resizeHandler);
    }
}

onMounted(() => {
    if (isUrl.value) {
        window.open(getGame.value, '_blank')
    }

    // setHeight()
    resizeHandler()
    registerResizeEvent()

})

onBeforeUnmount(() => {
    removeResizeEvent()
    showHeader()
})
</script>

<template>
    {{ o }}
    <div :style="{height: height + 'px'}" v-if="!isUrl" v-html="getGame"></div>
</template>
