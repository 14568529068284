<script setup>
import PrematchSportsNavigation from '@/components/PrematchSportsNavigation.vue';
import PrematchSportsSidebar from '@/components/PrematchSportsSidebar.vue';
import MainEvents from './Partials/MainEvents.vue';
import { usePrematchStore } from '@/stores/prematch';
import { storeToRefs } from 'pinia';
import { useLiveStore } from '@/stores/live';
import FavoriteLeagues from './Partials/FavoriteLeagues.vue';
import MainLeagues from './Partials/MainLeagues.vue';
import { onMounted } from 'vue';
import { useAuthStore } from '@/stores/auth';

const prematch = usePrematchStore()
const live = useLiveStore()
const auth = useAuthStore()
const { mainEvents, upcomingEvents } = storeToRefs(prematch)
const { tenEvents } = storeToRefs(live)
const { getUser } = storeToRefs(auth)

onMounted(() => {
    prematch.fetchMainLeagues({p: getUser.value.p ?? 0, m: getUser.value.mrg ?? 0})
})
</script>

<template>
    <div class="xl:flex flex-1 overflow-hidden">
        <!-- <PrematchSportsSidebar /> -->

        <div class="xl:flex-1">
            <RouterLink to="/search" class="block py-2.5 px-2">
                <div class="SearchBar">
                    <div class="SearchBar_Icon"></div>
                    <div class="SearchBar_Text">{{$t('app.8')}}</div>
                </div>
            </RouterLink>

            <PrematchSportsNavigation />

            <RouterLink to="/ebd" class="block px-4 bg-gradient-to-r from-[var(--color-primary6)]">
                <h3 class="text-base font-bold py-2.5 text-white/90">{{$t('app.9')}}</h3>
            </RouterLink>

            <MainEvents
                v-if="mainEvents(6046).length"
                :title="$t('partials.16')"
                icon="6046"
                :events="mainEvents(6046)"
            />

            <MainLeagues />

            <MainEvents
                :title="$t('partials.17')"
                :sport="6046"
                :events="tenEvents(6046)"
                :inline="false"
                class="mt-4"
            />

            <MainEvents
                :title="$t('partials.18')"
                :sport="6046"
                :events="upcomingEvents(6046)"
                :inline="false"
                class="mt-4"
            />

            <FavoriteLeagues />
        </div>
    </div>
</template>

<style scoped>
.SearchBar {
    background-color: hsla(0,0%,100%,.1);
    border-radius: 50px;
    padding: 10px 5px;
    display: flex;
    height: 40px
}

.SearchBar_Icon {
    mask-image: url('/search.svg');
    mask-size: 18px 18px;
    mask-repeat: no-repeat;
    mask-position: center;
    background-color: #fff;
    height: 100%;
    width: 45px;
}

.SearchBar_Text {
    font-size: 14px;
    color: #fff;
    font-weight: 700;
    line-height: 20px;
}
</style>
