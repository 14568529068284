<script setup>

</script>

<template>
 <div>
    <h1>{{$t('terms.term1.p1')}}</h1>

    <h2>1.{{$t('terms.term1.p2')}}</h2>
    <ul>
        <li>{{$t('terms.term1.p3')}}</li>
        <li>{{$t('terms.term1.p4')}}</li>
        <li>{{$t('terms.term1.p5')}}</li>
        <li>{{$t('terms.term1.p6')}}</li>
        <li>{{$t('terms.term1.p7')}}</li>
        <li>{{$t('terms.term1.p8')}}</li>
        <li>{{$t('terms.term1.p9')}}</li>
    </ul>
    
    <h2>2. {{$t('terms.term1.p10')}}</h2>
    <ul>
        <li><strong>{{$t('terms.term1.p11')}}:</strong> {{$t('terms.term1.p12')}}</li>
        <li><strong>{{$t('terms.term1.p13')}}:</strong> {{$t('terms.term1.p14')}}</li>
        <li><strong>{{$t('terms.term1.p15')}}:</strong> {{$t('terms.term1.p16')}}</li>
    </ul>
    
    <h2>3. {{$t('terms.term1.p17')}}</h2>
    <ul>
        <li><strong>{{$t('terms.term1.p18')}}:</strong> {{$t('terms.term1.p19')}}</li>
        <li><strong>{{$t('terms.term1.p20')}}:</strong> {{$t('terms.term1.p21')}}</li>
        <li><strong>{{$t('terms.term1.p22')}}:</strong> {{$t('terms.term1.p23')}}</li>
    </ul>
    
    <h2>4. {{$t('terms.term1.p24')}}</h2>
    <ul>
        <li>{{$t('terms.term1.p25')}}</li>
        <li>{{$t('terms.term1.p26')}}</li>
        <li>{{$t('terms.term1.p27')}}</li>
        <li>{{$t('terms.term1.p28')}}</li>
    </ul>
    
    <h2>5. {{$t('terms.term1.p29')}}</h2>
    <ul>
        <li>{{$t('terms.term1.p30')}}</li>
        <li>{{$t('terms.term1.p31')}}</li>
    </ul>
    
    <h2>6. {{$t('terms.term1.p32')}}</h2>
    <p>{{$t('terms.term1.p33')}}</p>
 </div>
</template>
<style scoped>
   ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        margin-bottom: 10px;
        font-size: 12px;
    }
    p{
        padding: 1px 0px;
        font-size: 12px;
    }
    strong {
        color: white;
    }
    ul{
        padding: 2px 0px
    }
    h2 , h3{
        color: white;
        padding: 2px 0px;
        font-size: 12px;
    }
    h1{
        color: white;
        padding: 4px 0px;
        font-size: 14px;
    }
</style>