<script setup>
import Spinner from '@/components/Spinner.vue';
import { toFixedTwo } from '@/helpers';
import { useBetsStore } from '@/stores/bets';
import { useResponseMessageStore } from '@/stores/response_message';
import { socket } from '@/user.socket';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n()
const props = defineProps({
    ticketId: String
})

const responseMessageStore = useResponseMessageStore()
const emit = defineEmits(['accepted'])

const value = ref(0)
const cashingOut = ref(false)

const getCashoutValue = (data) => {
    if (data.ok) {
        if (data.data.ticketId == props.ticketId) {
            value.value = data.data.value
        }
    }
}

const accept = () => {
    cashingOut.value = true
    socket.emit('ticket:accept-cashout', {ticketId: props.ticketId})
}

const onAccept = (data) => {
    cashingOut.value = false
    if (data.ok) {
        if (data.data.ticketId == props.ticketId) {
            emit('accepted')
        }
    } else {
        responseMessageStore.setMessage(t(data.message))
        socket.emit('ticket:calculate-cashout', {ticketId: props.ticketId})
    }
}

onMounted(() => {
    socket.emit('ticket:calculate-cashout', {ticketId: props.ticketId})

    socket.on('ticket:calculate-cashout', getCashoutValue)

    socket.on('ticket:accept-cashout', onAccept)
})

onBeforeUnmount(() => {
    socket.off('ticket:calculate-cashout', getCashoutValue)
    socket.off('ticket:accept-cashout', onAccept)
})
</script>

<template>
    <div class="py-2 px-4" v-if="value">
        <button class="py-2.5 text-center text-white text-base font-bold rounded-lg bg-neutral-600/75 w-full" @click="accept">
            <span>Cash Out</span>
            <span class="text-[var(--color-primary4)] ml-2">{{ toFixedTwo(value) }}</span>
        </button>

        <Spinner :show="cashingOut" />
    </div>
</template>
