<script setup>
import { usePrematchStore } from '@/stores/prematch';
import { storeToRefs } from 'pinia';
import MainEvent from './MainEvent.vue';
import MatchListItem from '@/components/Match/MatchListItem.vue';
import MatchGroupHeader from '@/components/Match/MatchGroupHeader.vue';
import { useResourcesStore } from '@/stores/resources';

defineProps({
    events: Array,
    sport: [String, Number],
    title: String,
    icon: [String, Number],
    inline: {
        type: Boolean,
        default: true
    }
})

const resources = useResourcesStore()
const { getSport } = storeToRefs(resources)
</script>

<template>
    <div class="py-1.5 px-3">
        <div class="mb-3 mt-1 flex items-center">
            <img
                v-if="icon"
                :src="`/sport/${icon}.svg`"
                class="w-6 h-6 mr-3"
            >
            <h3
                class="font-futura text-white font-extrabold text-2xl uppercase tracking-tight"
                v-if="title"
            >{{ title }}</h3>
        </div>

        <ul v-if="inline" class="list-none flex overflow-x-auto space-x-1">
            <li v-for="event in events">
                <MainEvent :event="event" />
            </li>
        </ul>

        <div v-else class="bg-[var(--color-white7)] rounded overflow-hidden divide-y divide-neutral-600/50">
            <MatchGroupHeader :title="getSport(sport).nm" />
            <template v-for="event in events" :key="event._id">
                <MatchListItem :event="event"/>
            </template>
        </div>
    </div>
</template>
