<script setup>
import TextField from '@/components/Form/TextField.vue';
import MatchListItem from '@/components/Match/MatchListItem.vue';
import { debounce } from '@/helpers';
import { useLiveStore } from '@/stores/live';
import { usePrematchStore } from '@/stores/prematch';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';

let search = ref('')

const prematch = usePrematchStore()
const live = useLiveStore()
const { search: searchPrematch } = storeToRefs(prematch)
const result = ref([])

const submit = debounce(() => {
    result.value = searchPrematch.value(search.value)
}, 500)

watch(search, (value) => {
    submit()
})
</script>

<template>
    <div class="fixed w-full h-full top-0 bg-[var(--color-grey28)]">
        <div class="bg-[var(--search-bg)] flex px-4">
            <div class="SearchIcon" />
            <TextField 
                class="flex-1 text-sm !bg-transparent placeholder:text-[var(--color-primary3)] text-white py-4" 
                placeholder="Search..."
                type="search"
                v-model.lazy="search"
            />
            <RouterLink to="/" class="flex items-center">
                <p class="text-[11px] text-[var(--color-primary4)]">{{$t('app.12')}}</p>
            </RouterLink>
        </div>

        <div class="flex-1 h-full overflow-y-auto">
            <template v-if="!search">
                <div class="SectionHeader">
                    <p class="SectionHeader_Title">{{$t('app.13')}}</p>
                </div>

                <div class="grid md:grid-cols-3 gap-2 py-2 px-3">
                    <div 
                        class="py-3 px-2 bg-[var(--color-grey3)] text-white text-sm font-bold italic rounded cursor-pointer"
                        @click="search = 'AC Milan'"
                    >AC Milan</div>
                    <div 
                        class="py-3 px-2 bg-[var(--color-grey3)] text-white text-sm font-bold italic rounded cursor-pointer"
                        @click="search = 'Barcelona'"
                    >Barcelona</div>
                    <div 
                        class="py-3 px-2 bg-[var(--color-grey3)] text-white text-sm font-bold italic rounded cursor-pointer"
                        @click="search = 'Liverpool'"
                    >Liverpool</div>
                </div>
            </template>

            <template v-else>
                <div>
                    <div class="SectionHeader">
                        <p class="SectionHeader_Title">{{$t('app.14')}}</p>
                    </div>

                    <ul>
                        <li v-for="event in result" :key="event._id">
                            <MatchListItem :event="event" />
                        </li>
                    </ul>
                </div>
            </template>
        </div>
    </div>
</template>

<style scoped>
.SearchIcon {
    mask-image: url("/search.svg");
    mask-size: 18px 18px;
    mask-repeat: no-repeat;
    mask-position: center;
    min-width: 18px;
    background-color: #fff;
    z-index: 20;
    display: flex;
    flex-direction: column
}

.SectionHeader {
    @apply border-b border-[var(--color-grey3)] py-2 px-3 mt-3
}
.SectionHeader_Title {
    @apply text-[11px] text-[var(--color-primary3)] font-bold uppercase;
}
</style>