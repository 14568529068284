<script setup>
import { useResourcesStore } from '@/stores/resources';
import { storeToRefs } from 'pinia';
import Odd from '../Odd.vue';
import { computed, ref } from 'vue';
import display from '@/markets-display'
import ColsNoLines from './ColsNoLines.vue';
import ColsWithLines from './ColsWithLines.vue';
import ColsWithScore from './ColsWithScore.vue';
import { useAuthStore } from '@/stores/auth';
import AutoCols from './AutoCols.vue';
import ColsRowsWithLines from './ColsRowsWithLines.vue';
import MultipleColsPerLine from './MultipleColsPerLine.vue';

const props = defineProps({
    fid: [String, Number],
    odds: Array,
    ht: String,
    at: String,
})

const auth = useAuthStore()
const resources = useResourcesStore()
const { isMarketBlocked } = storeToRefs(auth)
const { getMarket } = storeToRefs(resources)
const show = ref(getMarket.value(props.fid)?.crdop)
const displayOptions = computed(() => props.fid in display ? display[props.fid] : undefined)

const marketName = computed(() => {
    const regexHome = new RegExp('\\{1\\}', 'g');
    const regexAway = new RegExp('\\{2\\}', 'g');
    const regexScore = new RegExp('\\{score\\}', 'g');

    let market = getMarket.value(props.fid)?.nm

    if (market) {
        market = market.replace(regexHome, props.ht)
        market = market.replace(regexAway, props.at)
        
        if (props.fid == 59) {
            const ln = props.odds.find(odd => odd.st == 1)?.ln?.split('.')[0];
            if (ln) {
                market = market.replace(regexScore, ln)
            }
        }
    }

    return market
})
</script>

<template>
    <div class="MarketGroup bg-mgc rounded overflow-hidden mt-2" v-if="getMarket(fid)?.ac && !isMarketBlocked(fid)">
        <div 
            class="px-2.5 py-3 cursor-pointer group"
            :class="{'border-b border-neutral-600': show}"
            @click="show = !show"
        >
            <div class="flex justify-between">
                <p class="text-[15px] text-white font-bold group-hover:text-[var(--color-primary5)]">{{ marketName }}</p>

                <div class="mgc-Chevron hidden group-hover:block transition ease-in-out" />
            </div>
        </div>

        <div v-show="show">
            <template v-if="displayOptions">
                <template v-if="displayOptions.id == 200 && !displayOptions.line">
                    <ColsNoLines 
                        :odds="odds"
                        :order="displayOptions.order"
                    />
                </template>

                <template v-if="displayOptions.id == 300 && displayOptions.line">
                    <ColsWithLines
                        :odds="odds"
                        :order="displayOptions.order"
                    />
                </template>
                <!-- <template v-if="displayOptions.id == 301">
                    <ColsRowsWithLines
                        :odds="odds"
                        :order="displayOptions.order"
                    />
                </template> -->

                <template v-if="displayOptions.id == 400 && !displayOptions.line">
                    <ColsWithScore
                        :odds="odds"
                        :ht="ht"
                        :at="at"
                    />
                </template>

                <template v-if="displayOptions.id == 201 && !displayOptions.line">
                    <AutoCols
                        :odds="odds"
                    />
                </template>

                <template v-if="displayOptions.id == 205 || displayOptions.id == 206">
                    <MultipleColsPerLine :odds="odds" :id="displayOptions.id" />
                    <!-- <ul class="list-none w-full">
                        <li v-for="odd in odds" class="flex-1 OddsListItem w-1/3 float-left">
                            <Odd :odd="odd" :with-name="true" :with-line="odd.ln ? true : false" class="py-2.5" />
                        </li>
                    </ul> -->
                </template>
            </template>

            <template v-else>
                <ul class="list-none w-full">
                    <li v-for="odd in odds" class="flex-1 OddsListItem w-1/2 float-left">
                        <Odd :odd="odd" :with-name="true" class="py-2.5" />
                    </li>
                </ul>
            </template>
        </div>
    </div>
</template>

<style scoped>
.MarketGroup:not(:has(.odd)) {
    display:none;
}

.mgc-Chevron {
    mask-image: url('/chevron-down.svg');
    background-color: var(--color-grey6);
    mask-size: 20px 20px;
    width: 20px;
    height: 20px;
}
</style>