<script setup>
import { orderByGivenArray, orderByLines } from '@/helpers';
import Odd from '../Odd.vue';
import { computed, ref } from 'vue';

const props = defineProps({
    order: Array,
    odds: Array
})

const lines = computed(() => [...new Set(props.odds.map((odd) => odd.ln))].sort((a, b) => a - b))
const cols = ref({
    2: 'grid grid-cols-3',
    3: 'grid grid-cols-4',
    4: 'grid grid-cols-5',
}[props.order.length])
</script>

<template>
    <div>
        <div :class="[cols, 'bg-[var(--color-white7)] text-white text-xs font-bold']">
            <div></div>
            <div v-for="o in order" class="py-1.5 text-center">{{ o }}</div>
        </div>
        <div class="divide-y divide-[var(--color-white10)]">
            <div 
                v-for="data in orderByLines(odds, lines, order)"
                :class="cols"
            >
                <div
                    class="text-[13px] text-[var(--color-greyDD)] h-[45px] flex items-center justify-center"
                >{{ data.line }}</div>
                <div 
                    v-for="odd in data.row"
                    class="OddsListItem"
                >
                    <template v-if="odd">
                        <Odd :odd="odd" />
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>